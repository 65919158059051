<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'giftVoucher.template.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccess('TEMPLATE_GIFT_VOUCHER_CREATE')" class="btn_cta btn_little btn_add">{{ 'giftVoucher.template.action.add.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">

        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <ng-container>

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button mat-icon-button (click)="resetFilters()">
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom du bon cadeau -->

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.label')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.template.form.fields.name.value' | translate" [key]="'translations.label'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type du bon cadeau -->

                <ng-container [matColumnDef]="getFilterColumnDef('type')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.template.type.value' | translate" [key]="'type'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="types"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Locales -->
                <ng-container [matColumnDef]="getFilterColumnDef('locales')">
                    <th mat-header-cell *matHeaderCellDef>
                    <app-core-select-array-multiple-filter #filter #localesFilter [builder]="filterBuilder"
                                                       [label]="'offer.list.filter.locale.value' | translate"
                                                       [key]="'translations.locale'"
                                                       [operator]="'lkin'" [itemValueProperty]="'id'"
                                                       [itemLabelProperty]="'label'"
                                                       [items]="locales"
                                                       ></app-core-select-array-multiple-filter>
                    </th>
                    </ng-container>



                <!-- isDematerialized -->

                <ng-container [matColumnDef]="getFilterColumnDef('isDematerialized')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.template.form.fields.isDematerialized.value' | translate" [key]="'isDematerialized'" [truthyLabel]="'activation.enabled.value' | translate" [falsyLabel]="'activation.disabled.value' | translate"></app-core-select-boolean-filter>
                    </th>
                </ng-container>

                <!-- isPhysical -->

                <ng-container [matColumnDef]="getFilterColumnDef('isPhysical')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="'giftVoucher.template.form.fields.isPhysical.value' | translate" [key]="'isPhysical'" [truthyLabel]="'activation.enabled.value' | translate" [falsyLabel]="'activation.disabled.value' | translate"></app-core-select-boolean-filter>
                    </th>
                </ng-container>

                <!-- Date de création -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>


            </ng-container>

            <!-- Ligne des données -->


            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="update(element.id)" *ngIf="hasAccess('TEMPLATE_GIFT_VOUCHER_EDIT')">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Nom du bon cadeau -->

                <ng-container matColumnDef="translations.label">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.form.fields.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).translations).label }}</td>
                </ng-container>

                <!-- Type du bon cadeau -->

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ 'giftVoucher.template.form.fields.type.' +  getModel(element).type + '.value' | translate }}</td>
                </ng-container>

                <!-- Locales du bon cadeau -->

                <ng-container matColumnDef="locales">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.locales.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getParsedLocales(getModel(element))}}</td>
                </ng-container>

                <!-- isDematerialized -->

                <ng-container matColumnDef="isDematerialized">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.isDematerialized.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('promotion.status.' + (getModel(element).isDematerialized ? 'enabled' : 'disabled') + '.value') | translate }}</td>
                </ng-container>

                <!-- isPhysical -->

                <ng-container matColumnDef="isPhysical">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.isPhysical.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('promotion.status.' + (getModel(element).isPhysical ? 'enabled' : 'disabled') + '.value') | translate }}</td>
                </ng-container>

                <!-- Date de création -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>{{'giftVoucher.template.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).createdAt | date : 'dd/MM/yyyy' : null : localeId }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>
