import {Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OfferAccessRequestCreateConfiguration} from "@core/shared/models/offer/offer-access-request/offer-access-request-create";
import {OfferAccessRequestCreateComponent} from "@core/components/offer/offer-access-request/offer-access-request-create/offer-access-request-create.component";
import {Offer} from "@core/shared/models/offer";
import {Society} from "@core/shared/models/society";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";

@Component({
    selector: 'app-core-offer-access-request-create-dialog',
    templateUrl: './offer-access-request-create-dialog.component.html',
    styleUrls: ['./offer-access-request-create-dialog.component.scss']
})
export class OfferAccessRequestCreateDialogComponent implements OnInit {

    @Output() create: EventEmitter<{item: OfferCatalog}> = new EventEmitter<{item: OfferCatalog}>();

    @ViewChild(OfferAccessRequestCreateComponent) offerAccessRequestCreateComponent: OfferAccessRequestCreateComponent;

    public submitDisabled: boolean = false;

    constructor(
        private _dialogRef: MatDialogRef<OfferAccessRequestCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offer: Offer, society: Society }
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<any>[] = [
            this.create
        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }

    public close(): void {

        this._dialogRef.close();
    }

    public submit(): void {

        this.submitDisabled = true;

        this.offerAccessRequestCreateComponent.formService.submit();
    }

    get configuration(): OfferAccessRequestCreateConfiguration {

        return {
            showSubmitButton: false
        };
    }

    get offer(): Offer {

        return this.data.offer;
    }

    get society(): Society {

        return this.data.society;
    }
}
