<div>
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_FEDERATION'])">{{ 'offerCreator.list.management.value' | translate }} ({{ totalItems }})</h1>
                <h1 class="title" *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">{{ 'offerCreator.list.admin.management.value' | translate }} ({{ totalItems }})</h1>
            </div>
        </div>
    </div>
    <div class="bloc_offers">
        <div class="bloc_offers_filters">
            <div>
                <div class="filters">
                    <app-core-offer-creator-filter></app-core-offer-creator-filter>
                </div>
                <div class="filters_checked">

                    <ng-container *ngFor="let filter of currentFilters">

                        <ng-container *ngIf="filter.formattedValue">
                            <span class="filter" (mousedown)="removeFilter(filter.field)">
                                <span>{{ filter.formattedValue }}</span>
                                <span class="close"><mat-icon>close</mat-icon></span>
                            </span>
                        </ng-container>

                    </ng-container>

                </div>
            </div>
        </div>
        <div class="offers_list" style="padding-top: 32px">
            <div class="content_offers">
                <div class="row marges">
                    <div class="col_12 col_md_6 col_lg_4 content_card" *ngFor="let item of items">
                        <app-core-offer-creator-card [item]="item"></app-core-offer-creator-card>
                    </div>
                </div>
                <div class="row more_offer" *ngIf="hasMoreItems">
                    <div class="col x_center">
                        <button class="btn_cta btn_highlight" type="button" (click)="loadMoreItems()">
                            {{ 'offerCreator.more.value' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
