<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'distributor.list.approval.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="exportOffersApprovals()" class="btn_cta btn_little">{{ 'offerCatalog.list.export.value' | translate }}</button>
            </div>
        </div>

        <div class="subscription_information">
            <p [innerHTML]="'distributor.title.description.value' | translate | safeHtml"></p>
            <p><mat-icon class="tooltip_icon">info</mat-icon> {{ 'distributor.title.help.value' | translate }}</p>
            <p [innerHTML]="'distributor.title.commission.value' | translate"></p>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container [matColumnDef]="getFilterColumnDef('society.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'distributor.name.value' | translate" [key]="'society.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Rôle utilisateur -->

                <ng-container [matColumnDef]="getFilterColumnDef('society.admin.roles')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'distributor.type.value' | translate" [key]="'society.admin.roles'" [operator]="'andlkin'" [itemValueProperty]="'identifier'" [itemLabelProperty]="'label'" [items]="filterRoles"></app-core-select-array-multiple-filter>
                    </th>
                </ng-container>


                <!-- Nom de l'offre -->

                <ng-container [matColumnDef]="getFilterColumnDef('offer.translations.name')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.name.value' | translate" [key]="'offer.translations.name'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Commission -->

                <ng-container [matColumnDef]="getFilterColumnDef('society.commission')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container [matColumnDef]="getFilterColumnDef('society.incomingPayment')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Date de la demande -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <!-- Statut de la demande -->

                <ng-container [matColumnDef]="getFilterColumnDef('status')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.catalog.request.status.value' | translate" [key]="'status'" [operator]="'eq'" [items]="statusItems" [itemLabelProperty]="'label'" [itemValueProperty]="'value'"></app-core-select-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <ng-container *ngIf="hasAccessRead()" >
                        <button mat-button (click)="openViewItemDialog(getModel(element))">
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        </ng-container>
                        <ng-container *ngIf="getModel(element).status !== 'accepted' && hasAccessEdit()" >
                            <button mat-button (click)="approveItem(getModel(element))" class="check" matTooltip="{{ 'distributor.offer.acceptanceTooltip' | translate }}"  matTooltipPosition="above">
                                <mat-icon>check</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="getModel(element).status !== 'refused' && hasAccessEdit()">
                            <button mat-button (click)="openRevokeItemDialog(getModel(element))" class="close" matTooltip="{{ 'distributor.offer.revokationTooltip' | translate }}" matTooltipPosition="above">
                                <mat-icon>close</mat-icon>
                            </button>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- Nom du distributeur -->

                <ng-container matColumnDef="society.name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society.name}}</td>
                </ng-container>

                <!-- Rôle utilisateur -->

                <ng-container matColumnDef="society.admin.roles">
                    <th mat-header-cell *matHeaderCellDef>{{'distributor.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).society.admin ? getRoleLabel(getModel(element).society.admin.roles) : null}}</td>
                </ng-container>

                <!-- Nom de l'offre -->

                <ng-container matColumnDef="offer.translations.name">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'offer.name.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ translationService.getFallbackTranslation(getModel(element).offer.translations).name}}</td>
                </ng-container>

                <!-- Commission -->

                <ng-container matColumnDef="society.commission">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.commission.grid.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ itemHasOneOfThisRoles(['ROLE_FEDERATION', 'ROLE_INSTITUTIONAL', 'ROLE_PROVIDER'], getModel(element).society.admin.roles) ? (formatCommission(getModel(element).commission) + ' %') : ''}} </td>
                </ng-container>

                <!-- Commission déduite automatiquement -->

                <ng-container matColumnDef="society.incomingPayment">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'distributor.incomingPayment.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getIncomingPaymentLabel(getModel(element))}}</td>
                </ng-container>

                <!-- Date de la demande -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'offer.catalog.request.date.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | amDateFormat:'DD MMMM YYYY' }}</td>
                </ng-container>

                <!-- Statut de la demande -->

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.catalog.request.status.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ ('offer.catalog.request.status.' + getModel(element).status + '.value') | translate }}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

