import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {ActivatedRoute, Router} from "@angular/router";
import {Channel, ChannelIntegrationMode} from "@core/shared/models/channel";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {Observable, of} from "rxjs";
import {Currency} from "@core/shared/models/currency";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CurrencyService} from "@core/shared/services/currency.service";
import {ChannelService} from "@core/shared/services/channel.service";
import {FormService} from "@core/shared/services/form.service";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {REGEX_WEBSITE} from "@core/shared/models/regex";
import {Offer} from "@core/shared/models/offer";
import {ChannelTranslation} from "@core/shared/models/channel-translation";
import {Role} from "@core/shared/models/role";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Access} from "@core/shared/models/access";
import {FieldCollection} from "@lib/form/field";
import {environment} from "../../../../../environments/environment";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {ClipboardService, IClipboardResponse} from "ngx-clipboard";
import {TranslationService} from "@core/shared/services/translation.service";
import {tap} from "rxjs/operators";
import {SocietyWebsite} from "@core/shared/models/society/society-website";
import {WebsiteService} from "@core/shared/services/website.service";
import {SocietyIp} from "@core/shared/models/society/society-ip";

@Component({
    selector: 'app-core-page-channel-read',
    templateUrl: './page-channel-read.component.html',
    styleUrls: ['./page-channel-read.component.scss'],
    providers: [
        FormService,
        OfferSearchService
    ]
})
export class PageChannelReadComponent implements OnInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    public user: User;

    public society: Society;

    public channel: Channel;

    public translationBuilder: TranslationBuilder;

    public locales$: Observable<LocaleItem[]>;

    public currencies$: Observable<Currency[]>;

    public societyWebsites$: Observable<SocietyWebsite[]>;

    public owners: Observable<Society[]>;

    public fieldCollection = new FieldCollection();

    public fonts: object[] = [
        {
            id: 'font-lato',
            name: 'font Lato'
        },
        {
            id: 'font-roboto',
            name: 'font Roboto'
        },
        {
            id: 'font-source',
            name: 'font Source Sans Pro'
        },
        {
            id: 'font-nunito',
            name: 'font Nunito'
        },
        {
            id: 'font-ubuntu',
            name: 'font Ubuntu'
        },
        {
            id: 'font-raleway',
            name: 'font Raleway'
        },
        {
            id: 'font-caveat',
            name: 'font Caveat'
        },
        {
            id: 'font-amatic',
            name: 'font Amatic SC'
        },
        {
            id: 'font-poiretone',
            name: 'font Poiret One'
        },
        {
            id: 'font-opensans',
            name: 'font Open Sans'
        },
        {
            id: 'font-montserrat',
            name: 'font Montserrat'
        },
        {
            id: 'font-oswald',
            name: 'font Oswald'
        },
        {
            id: 'font-slabo',
            name: 'font Slabo'
        },
        {
            id: 'font-ptsans',
            name: 'font PT Sans'
        },
        {
            id: 'font-merriweather',
            name: 'font Merriweather'
        },
        {
            id: 'font-notosans',
            name: 'font Noto Sans'
        },
        {
            id: 'font-concertone',
            name: 'font Concert One'
        },
        {
            id: 'font-prompt',
            name: 'font Prompt'
        },
        {
            id: 'font-worksans',
            name: 'font Work Sans'
        },
        {
            id: 'font-ooohbaby',
            name: 'font Oooh Baby'
        },
        {
            id: 'font-badscript',
            name: 'font Bad Script'
        },
        {
            id: 'font-dancingscript',
            name: 'font Dancing Script'
        }
    ];

    public saveAndContinueConfiguration: { active: boolean, fragment: ('JSIntegration') } = {
        active: false,
        fragment: null
    };

    public colorTitle: string;
    public colorSubTitle: string;
    public colorBackgroundButton: string;
    public colorTextButton: string;
    public colorHighlighting: string;
    public colorDescription: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _clipboardService: ClipboardService,
        private _currencyService: CurrencyService,
        private _channelService: ChannelService,
        private _offerCatalogService: OfferCatalogService,
        private _websiteService: WebsiteService,
        public formService: FormService,
        public offerSearchService: OfferSearchService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: {channel: Channel, user: User}): void => {

            this.channel = data.channel;

            this.user = data.user;

            this.society = data.channel.society;

            this._configureSearchService();

            this._initForm();

            if(!this.hasOneOfThisRoles(['ROLE_ADMIN', 'ROLE_SUPER_ADMIN']) ){

                this._loadSelfOfferCatalogs();
            }

            this._initOwners();

            this._initLocales();

            this._initCurrencies();

            this._loadSocietyWebsites();

            this.form.patchValue(Object.assign(data.channel, {
                currency: data.channel.currency.id,
                integrationMode: this.integrationMode
            }));

            this.colorTitle = data.channel?.style?.colorTitle;
            this.colorSubTitle=  data.channel?.style?.colorSubTitle;
            this.colorBackgroundButton=  data.channel?.style?.colorBackgroundButton;
            this.colorTextButton=  data.channel?.style?.colorTextButton;
            this.colorHighlighting=  data.channel?.style?.colorHighlighting;
            this.colorDescription =  data.channel?.style?.colorDescription;

            data.channel.translations.forEach((translation: ChannelTranslation): void => {

                this.translationBuilder.addItemControl(this.translationBuilder.getLocaleItem(translation.locale), translation);
            });

            this.offerSearchService.selectedOffers.next(data.channel.offerCatalogs.map((item: OfferCatalog): Offer => {

                return item.offer;
            }));

            this.offerSearchService.displayReferencedOffer = this.form.get('referenced').value;

            this.offerSearchService.displayOnlyGiftVoucherOffer = false;

            this.offerSearchService.displayOnlyOnlineSaleOffer = false;

            this.form.disable();

            // Redirection automatique sur un onglet

            this._activatedRoute.fragment.subscribe((fragment: ('JSIntegration')): void => {

                switch (fragment) {

                    case "JSIntegration":

                        this.tabGroup.selectedIndex = 2;

                        break;
                }
            });

            this._handleClipboardResponse();
        });
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);

            this.offerSearchService.selectedOffers.subscribe((items: Offer[]): void => {

                this.form.get('offerCatalogs').patchValue(items.map((item: Offer): { id: number } => {

                    const offerCatalog: OfferCatalog = this._offerCatalogService.selfItems.value.find((i: OfferCatalog): boolean => {

                        return i.offer.id === item.id;
                    });

                    return {
                        id: offerCatalog.id
                    };
                }));
            });
        });
    }

    private _configureSearchService(): void {

        this.offerSearchService.selectOfferAllowed = true;
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            ownerName: ['', Validators.required],
            marketplace: ['', [Validators.required, Validators.pattern(REGEX_WEBSITE)]],
            locale: [null, [Validators.required]],
            currency: [null, [Validators.required]],
            searchEngine: [false, [Validators.required]],
            referenced: [false, [Validators.required]],
            enable: [false, [Validators.required]],
            integrationMode: ['subDomain'],
            canonicalUrl: ['', [(control: UntypedFormControl) => {

                if(!this.form)
                    return null;

                if(!this.channel.enableJS) {

                    return null;

                }

                if(control.value.length < 1) {

                    return {
                        'isRequired': {
                            valid: false
                        }
                    }
                }

                if(/^\//g.test(control.value))
                    return null;

                return {
                    'isPatternInvalid': {
                        valid: false
                    }
                }
            }]],
            offerCatalogs: [[]],
            isDistributor: [!this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']), Validators.required],
            comment: [null],
            translations: new UntypedFormArray([]),
            style: this._formBuilder.group({
                id: [''],
                font: ['', Validators.required],
                colorTitle: [null],
                colorDescription: [null],
                colorSubTitle: [null],
                colorBackgroundButton: [null],
                colorTextButton: [null],
                colorHighlighting: [null]
            }),
            allowedIps: [[], [Validators.required]]
        });

        this.fieldCollection.addField({
            type: 'select-search',
            config: {
                id: 'font',
                attrs: {
                    label: this._translateService.instant('channel.fields.font.value'),
                    required: true,
                    choices: this.fonts,
                    multiple: false,
                }
            }
        });

        this._initTranslationsForm();

        this.formService.submitCallback = (): void => {

            const data: object = Object.assign(this.form.value, {
                currency: {id: parseInt(this.form.get('currency').value)}
            });

            /** @ts-ignore **/
            data.translations[0].locale = data.locale;

            this._channelService.updateItemAPI(this._activatedRoute.snapshot.params['id'], data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('channel.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                if (!this.saveAndContinueConfiguration.active) {
                    this.redirectToList();
                }
            });
        };
    }

    private _initTranslationsForm(): void {

        this.translationBuilder = new TranslationBuilder(this._formBuilder);

        this.translationBuilder.form = this.form;

        this.translationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                name: ['', [Validators.required]],
                title: ['', [Validators.required]],
                description: [null]
            });
        };
    }

    private _initOwners(): void {

        this.owners = of([this.society]);
    }

    private _initLocales(): void {

        this.locales$ = of(LOCALE_ITEMS);
    }

    private _initCurrencies(): void {

        this.currencies$ = this._currencyService.getItemsAPI();
    }

    private _loadSocietyWebsites(): void {

        this.societyWebsites$ = this._websiteService.getSocietyItemsAPI(this.society.id).pipe(
            tap((societyWebsites: SocietyWebsite[]): void => {

                const societyWebsite: SocietyWebsite = societyWebsites.find((item: SocietyWebsite): boolean => {

                    return item.website === this.society.mainWebsite;
                });

                this.form.get('marketplace').patchValue(this.hasAccessAdditionalWebsite ? this.channel.marketplace : societyWebsite);
            })
        );
    }

    private _handleClipboardResponse(): void {

        this._clipboardService.copyResponse$.subscribe((response: IClipboardResponse) => {

            if (response.isSuccess) {

                this._snackBar.open(this._translateService.instant('content.copied.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            }
        });
    }

    public redirectToList(): void {

        this._router.navigate(['account/channel/list']);
    }

    public getTranslation(index: number): UntypedFormGroup {

        return this.translationsControl.controls[index] as UntypedFormGroup;
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public submitEnter($event){

        if($event.key == 'Enter'){

            $event.preventDefault();

            return;
        }
    }

    public changeFont(event): void {

        this.form.controls['style'].get('font').patchValue(event.value);
    }

    public updateColorField(value,field){

        this.form.controls['style'].get(field).patchValue(value);
    }

    public changeLocale(): void {

        this.translationBuilder.changeLocale([this.form.get('locale').value]);
    }

    public copyJSIntegrationWCContent(): void {

        this._clipboardService.copy(`<tywin-channel token="${this.channel.tokenJs}" base-url="${this.canonicalUrl}"></tywin-channel>`);
    }

    public copyJSIntegrationSourceContent(): void {

        this._clipboardService.copy(this.JSIntegrationSourceTag);
    }

    public submit(saveAndContinue: boolean, fragment?: ('JSIntegration')): void {

        this.saveAndContinueConfiguration = {
            active: saveAndContinue,
            fragment: fragment
        };

        this.formService.submit();
    }

    public compareSocietyWebsite(a: SocietyWebsite, b: SocietyWebsite): boolean {

        if(!a || !b){

            return false;
        }

        return a.id === b.id;
    }

    get JSIntegrationSourceTag(): string {

        return `<script src="${environment.channelBaseUrl}/build/channel/channel.js" defer></script>`;
    }

    get subDomainIntegrationLink(): string {

        return `https://${this.channel.subDomain}${environment.channelSubDomainSuffix}`;
    }

    get sitemapUrl(): string {

        return `${environment.channelBaseUrl}/${this.channel.id}/sitemap.xml`;
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get translationsControl(): UntypedFormArray {

        return this.form.get('translations') as UntypedFormArray;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get hasAccessAdditionalWebsite(): boolean {

        if(!this.society){

            return false;
        }

        return this.society.admin.accesses.some((access: Access): boolean => {

            return access.tag === 'ADDITIONAL_WEBSITE';
        });
    }

    get mode(): ModeType {

        return 'channel';
    }

    get marketplace(): string {

        const societyService: SocietyWebsite = (this.form.get('marketplace').value as SocietyWebsite);

        return societyService ? societyService.website : null;
    }

    get canonicalUrl(): string {

        const canonicalUrl: string = (this.form.get('canonicalUrl').value as string);

        return `${this.marketplace}${canonicalUrl}`;
    }

    get integrationMode(): ChannelIntegrationMode {

        if(!this.channel){

            return null;
        }

        const items: { [p in ChannelIntegrationMode]: boolean } = {
            subDomain: this.channel.enableSubDomain,
            JS: this.channel.enableJS,
            API: this.channel.enableApi
        };

        return Object.keys(items).find((key: ChannelIntegrationMode): boolean => {

            return Boolean(items[key]);

        }) as ChannelIntegrationMode;
    }

    get allowedIps(): string[] {

        return (this.form.get('allowedIps').value as SocietyIp[]).map((item: SocietyIp): string => {

            return item.address;
        });
    }
}
