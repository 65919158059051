import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OfferListComponent} from "@core/components/offer/offer-list/offer-list.component";
import {OfferMapComponent} from "@core/components/offer/offer-map/offer-map.component";
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {User} from "@core/shared/models/user";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {Role} from "@core/shared/models/role";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {OfferAttributeTypeService} from "@core/shared/services/offer-attribute-type.service";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {OfferDurationService} from "@core/shared/services/offer/offer-duration.service";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {Society} from "@core/shared/models/society";
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferFilterComponent} from "@core/components/offer/offer-filter/offer-filter.component";
import {OfferFilterPanelComponent} from "@core/components/offer/offer-filter-panel/offer-filter-panel.component";
import {OfferNetworkProviderService} from "@core/shared/services/offer/offer-network-provider.service";
import {OfferGroupNetworkProvider} from "@core/shared/models/offer/offer-group-network-provider";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {AbstractControl, FormControl} from "@angular/forms";
import {SocietyService} from "@core/shared/services/society.service";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {SocietyProviderService} from "@core/shared/services/society/society-provider.service";
import {map} from "rxjs/operators";
import {SocietyProvider} from "@core/shared/models/society/society-provider";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import * as moment from "moment";
import {
    OfferSearchSessionFilter
} from "@core/shared/models/offer/offer-search";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {TranslationService} from "@core/shared/services/translation.service";
import {Moment} from "moment";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";

type ViewType = 'list' | 'map';

@Component({
    selector: 'app-core-offer-search',
    templateUrl: './offer-search.component.html',
    styleUrls: ['./offer-search.component.scss']
})
export class OfferSearchComponent implements OnInit {

    @Input() user: User;

    @Input() society: Society;

    @Input() mode: ModeType;

    @Input() locale: AbstractControl;

    @Input() referenced: AbstractControl;

    @Input() allowBooking: AbstractControl;

    @Input() allowGiftVoucher: AbstractControl;

    @Input() allowRequest: AbstractControl;

    @Input() allowOnlineSale: AbstractControl;

    @ViewChild(OfferMapComponent, {static: false}) offerMapComponent: OfferMapComponent;

    @ViewChild(OfferListComponent, {static: false}) offerListComponent: OfferListComponent;

    @ViewChild(OfferFilterComponent, {static: true}) offerFilterComponent: OfferFilterComponent;

    @ViewChild(OfferFilterPanelComponent, {static: true}) offerFilterPanelComponent: OfferFilterPanelComponent;

    public filterBuilder: FilterBuilder;

    public params: string[] = [];

    public openFilterPanel: boolean = false;

    public offerCreator: Society[] = [];

    public provider: Society[] = [];

    public restrictedSocietyProviders: Society[] = [];

    public hasNight: boolean = false;

    public publicPrice: number;

    public allAttributes: OfferAttribute[] = [];

    public offerAttributeTouristsDestinations: { id: number, label: string }[] = [];

    public offerAttributeThemes: { id: number, label: string }[] = [];

    public offerAttributeTypes: { id: number, label: string }[] = [];

    public offerAttributeActivities: { id: number, label: string }[] = [];

    public offerAttributeAccomodations: { id: number, label: string }[] = [];

    public offerAttributeRestoration: { id: number, label: string }[] = [];

    public offerAttributeRegions: { id: number, label: string }[] = [];

    public offerDuration: OfferDuration[] = [];

    public offerCustomerTypology: CustomerTypology[] = [];

    public providerNetwork: OfferGroupNetworkProvider[] = [];

    public offerCreatorNetwork: NetworkOfferCreator[] = [];

    public offerAttributeType: string[] = ['touristsDestinations', 'themes', 'types', 'activities', 'accommodations', 'restoration', 'region'];

    public view: ViewType = 'list';

    public roles: Role[] = [];

    public identificationNumber: string;

    public locales: LocaleItem[] = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _translateService: TranslateService,
        private _subscriptionService: SubscriptionService,
        private _offerAttributeTypeService: OfferAttributeTypeService,
        private _offerAttributeService: OfferAttributeService,
        private _societyService: SocietyService,
        private _societyProviderService: SocietyProviderService,
        private _offerDurationService: OfferDurationService,
        private _customerTypologyService: CustomerTypologyService,
        private _networkProviderService: OfferNetworkProviderService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        private _offerSearchService: OfferSearchService,
        private _offerCatalogService: OfferCatalogService,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        if(this._activatedRoute.snapshot.queryParams['layoutView']){

            this.view = this._activatedRoute.snapshot.queryParams['layoutView'] as ViewType;
        }

        this._initLocales();

        this.publicPrice = +this.hasOneOfThisMode(['catalog', 'offer-permanent-option-catalog']);

        this.roles = this.user.roles;

        this.identificationNumber = this.society?.identificationNumber;

        this._initFilterBuilder();

        this._initNightFilter();

        switch (true) {

            case (this.hasRole('ROLE_SUPER_ADMIN')):

                this._initProviderNetwork();

                this._initAdminProvider();

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreatorNetwork();

                this._initAdminOfferCreator();

                break;

            case (this.hasRole('ROLE_ADMIN')):

                this._initProviderNetwork();

                this._initAdminProvider();

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreatorNetwork();

                this._initAdminOfferCreator();

                break;

            case (this.hasAllOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_OFFER_CREATOR'])):

                this._initProviderNetwork();

                if(this.society?.hasRestrictedNetworkProvider) {

                    this._initRestrictedSocietyProviders();
                }

                this._initProvider();

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreatorNetwork();

                this._initOfferCreator();

                break;

            case (this.hasRole('ROLE_OFFER_DISTRIBUTOR')):

                this._initProviderNetwork();

                if(this.society?.hasRestrictedNetworkProvider) {

                    this._initRestrictedSocietyProviders();
                }

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreatorNetwork();

                this._initOfferCreator();

                break;

            case (this.hasRole('ROLE_OFFER_CREATOR')):

                this._initProviderNetwork();

                if(this.society?.hasRestrictedNetworkProvider) {

                    this._initRestrictedSocietyProviders();
                }

                this._initProvider();

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                break;

            case (this.hasRole('ROLE_PROVIDER')):

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreator();

                break;

            case (this.hasRole('ROLE_INSTITUTIONAL') || this.hasRole('ROLE_FEDERATION')):

                this._initProviderNetwork();

                if(this.society?.hasRestrictedNetworkProvider) {

                    this._initRestrictedSocietyProviders();
                }

                this._initOfferAttributes();

                this._initOfferDuration();

                this._initCustomerTypology();

                this._initOfferCreatorNetwork();

                this._initOfferCreator();

                break;
        }
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    public getSessionsFilters(mode: ModeType): OfferSearchSessionFilter[] {

        const excludedKeys: string[] = [
            'duration.value'
        ];

        if(!this._offerSearchService.getSessionFilters(mode)){

            return [];
        }

        return this._offerSearchService.getSessionFilters(mode).filter((item: OfferSearchSessionFilter): boolean => {

            return !excludedKeys.includes(item.key);
        });
    }

    public identifySessionFilter(index: number, item: OfferSearchSessionFilter): string {

        return `${item.key}`;
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();
    }

    private _initNightFilter(): void {

        this.hasNight = this._offerSearchService.getSessionFilter(this.mode, `duration.value`) ? (this._offerSearchService.getSessionFilter(this.mode, `duration.value`).extraData.operator === 'gt') : true;

        const nightFilterField: TextFilterField = new TextFilterField(`${this.prefixFilter}duration.value`, this.hasNight ? 'gt' : 'eq', 1);

        this.filterBuilder.addField(nightFilterField);
    }

    private _initProviderNetwork(): void {

        this._networkProviderService.getItemsAPI().subscribe((data: OfferGroupNetworkProvider[]): void => {
            this.providerNetwork = data;
        });
    }

    private _initOfferCreatorNetwork(): void {

        this._networkOfferCreatorService.getItemsAPI().subscribe((data: NetworkOfferCreator[]): void => {

            this.offerCreatorNetwork = data;
        });
    }

    private _initOfferCreator(): void {

        const params: string[] = [
            'sort[name]=ASC'
        ];

        this._societyService.getOfferCreatorsItemsAPI(params).subscribe((data: Society[]): void => {

            this.offerCreator = data;
        });
    }

    private _initAdminOfferCreator(): void {

        const roleFilter: TextFilterField = new TextFilterField('admin.roles', 'lk', 'ROLE_OFFER_CREATOR');

        const params: string[] = [
            roleFilter.serialize,
            'sort[name]=ASC'
        ];

        this._societyService.getItemsAPI(params).subscribe((data: Society[]): void => {

            this.offerCreator = data;
        });
    }

    private _initProvider(): void {

        const societyProviderParams: string[]= [
            `enable[eq]=1`
        ]

        this._societyProviderService.getSocietyItemsAPI(this.society.id, societyProviderParams).pipe(map((items: SocietyProvider[]): Society[] => {

            return items.map((item: SocietyProvider): Society => {

                return item.provider;
            });

        })).subscribe((data: Society[]): void => {
            this.provider = data;
        });
    }

    private _initRestrictedSocietyProviders(): void {

        this._societyService.getProvidersItemsAPI().subscribe((data: Society[]): void => {

            this.restrictedSocietyProviders = data;
        });
    }

    private _initAdminProvider(): void {

        const roleFilter: TextFilterField = new TextFilterField('admin.roles', 'lk', 'ROLE_PROVIDER');

        const params: string[] = [
            roleFilter.serialize,
        ];

        this._societyService.getItemsAPI(params).subscribe((data: Society[]): void => {

            this.provider = data;
        });
    }

    private _initOfferAttributeType(index: number, offerAttributes: OfferAttribute[]): void {

        if (index === 0) {

            this.offerAttributeTouristsDestinations = this.transformOfferAttributes(offerAttributes);

        } else if (index === 1) {

            this.offerAttributeThemes = this.transformOfferAttributes(offerAttributes);

        } else if (index === 2) {

            this.offerAttributeTypes = this.transformOfferAttributes(offerAttributes);

        } else if (index === 3) {

            this.offerAttributeActivities = this.transformOfferAttributes(offerAttributes);

        } else if (index === 4) {

            this.offerAttributeAccomodations = this.transformOfferAttributes(offerAttributes);

        } else if (index === 5){

            this.offerAttributeRestoration = this.transformOfferAttributes(offerAttributes);
        }
        else {

            this.offerAttributeRegions = this.transformOfferAttributes(offerAttributes);
        }
    }

    private _initOfferAttributes(): void {

        for (let i = 0; i < this.offerAttributeType.length; i++) {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', this.offerAttributeType[i]);

            const params: string[] = [
                typeFilter.serialize
            ];

            this._offerAttributeService.getItemsAPI(params).subscribe((offerAttributes: OfferAttribute[]): void => {

                this._initOfferAttributeType(i, offerAttributes);

                this.allAttributes = [ ...this.allAttributes, ...offerAttributes];
            });
        }
    }

    private _initOfferDuration(): void {

        const params: string[] = [
            `sort[position]=ASC`
        ];

        this._offerDurationService.getItemsAPI(params).subscribe((duration: OfferDuration[]): void => {

            this.offerDuration = duration;
        });
    }

    private _initCustomerTypology(): void {

        this._customerTypologyService.getItemsAPI().subscribe((customerTypologies: CustomerTypology[]): void => {

            this.offerCustomerTypology = customerTypologies;
        });
    }

    public closePanel(event: boolean): void {

        this.openFilterPanel = event;
    }

    public resetFilters(): void {

        const filterComponents: FilterComponent[] = [];

        filterComponents.push(...this.offerFilterComponent.filterComponents);

        filterComponents.push(...this.offerFilterPanelComponent.filterComponents);

        if (this.offerListComponent) {

            this.offerListComponent.resetFilters(filterComponents);
        }

        if (this.offerMapComponent) {

            this.offerMapComponent.resetFilters(filterComponents);
        }
    }

    public addItem(): void {

        this._router.navigate(['account/offer/create']);
    }

    public setView(value: ViewType): void {

        this.view = value;

        this._offerCatalogService.selfItems.next([]);
    }

    public isView(value: ViewType): boolean {

        return this.view === value;
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public isMode(mode: ModeType): boolean {

        return this.mode === mode;
    }

    public hasOneOfThisMode(modes: ModeType[]): boolean{

        return modes.some((mode: ModeType) => {

            return this.isMode(mode);
        });

    }

    get prefixFilter(): string {

        return this.hasOneOfThisMode(['channel', 'catalog', 'offer-permanent-option-catalog']) ? 'offer.' : '';
    }

    public isListFilter(value) {
        return value instanceof Array;
    }

    public getListFilter(filter: OfferSearchSessionFilter): string[] {

        switch (filter.key){

            case 'society.networkOfferCreators.id':

                const existingNetworkOfferCreatorsIds: number[] = this.offerCreatorNetwork.map((item: NetworkOfferCreator): number => {

                    return item.id;
                });

                const ids: number[] = (filter.value as number[]).filter((id: number): boolean => {

                    return existingNetworkOfferCreatorsIds.includes(id);

                });

                return ids.map((id: number): string => {

                    return id.toString();
                });

            default:

                return filter.value.toString().split(',');
        }
    }

    public get duration(){
        return this.offerDuration;
    }

    public formatFilterValue(key: string, value): any {

        switch (key) {

            case 'promotions':
            case 'isOnAlert':
            case 'createdAt':
            case 'offerAdded':
            case 'onlineSale.enable':
            case 'giftVoucher.enable':
            case 'notInCatalog':
            case 'isRestricted':
            case 'permanentOption':
            case 'permanentOptions.id':
            case 'permanentOptions.offerPermanentOption.locales':

                return '';

            case 'locales':

                const locale = this.locales.find((item: LocaleItem): boolean => {

                    return item.id === value;
                });

                return ' : ' + locale.label;

            case 'duration.id':

                const duration = this.offerDuration.find((item): boolean => {

                    return item.id == value;
                });

                if(!duration){

                    return '';
                }

                const durationTranslate = this.translationService.getFallbackTranslation(duration.translations);

                return (durationTranslate) ?  ' : ' + durationTranslate.label :  ' : ' + duration[0].label;

            case 'customerTypology.id':

                const offerCustomerTypology = this.offerCustomerTypology.find((item): boolean => {

                    return item.id == value;
                });

                if(!offerCustomerTypology){

                    return '';
                }

                const offerCustomerTypologyTranslate = this.translationService.getFallbackTranslation(offerCustomerTypology.translations);

                return ' : ' + (offerCustomerTypologyTranslate) ? offerCustomerTypologyTranslate.name : offerCustomerTypology[0].label;

            case 'attributes.attribute.destinations':
            case 'attributes.attribute.accommodations':
            case 'attributes.attribute.restoration':
            case 'attributes.attribute.themes':
            case 'attributes.attribute.activities':
            case 'attributes.attribute.region':
            case 'attributes.attribute.types':

                const attribute = this.allAttributes.find((item: OfferAttribute): boolean => {

                    return item.id === parseInt(value);
                });

                return attribute ? this.translationService.getFallbackTranslation(attribute.translations).label : '';

            case 'society.id':

                const society = this.offerCreator.find((item) => item.id == value);

                return society ? society.name : '';

            case 'providerSocieties.id':

                const provider = this.provider.find((item) => item.id == value);

                return provider ? provider.name : '';

            case 'restrictedProviderSocieties.id':

                const restrictedProvider = this.restrictedSocietyProviders.find((item) => item.id === parseInt(value));

                return restrictedProvider ? restrictedProvider.name : '';

            case 'date':
            case 'updatedAt':

                let start = (value.start) ? moment(value.start) : '';

                let end = (value.end) ? moment(value.end) : '';

                let dates = (value.start) ? (start as Moment).format('DD/MM/YYYY') : '';

                dates += (value.end) ? ' - ' + (end as Moment).format('DD/MM/YYYY') : '';

               return  ' : ' + dates;

            case 'published':

                return ' : ' + this._translateService.instant('offer.filters.status.' + value);

            case 'providerSocieties.networkProviders.groupNetworkProvider.id':

                const groupNetworkProvider = this.providerNetwork.find((item: OfferGroupNetworkProvider): boolean => {

                    return item.id === parseInt(value);
                });

                return groupNetworkProvider ? groupNetworkProvider.label : '';

            case 'society.networkOfferCreators.id':

                const networkOfferCreator = this.offerCreatorNetwork.find((item: NetworkOfferCreator): boolean => {

                    return item.id === parseInt(value);
                });

                return networkOfferCreator ? networkOfferCreator.name : '';

            default:

                return ' : ' + value;
        }
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public removeFilter(keyFilter: string, value, val): void {

        const session = this.getSessionsFilters(this.mode);

        const index: number = this.getSessionsFilters(this.mode).findIndex((item: OfferSearchSessionFilter): boolean => {

            return item.key === keyFilter;
        });

        if(!(value instanceof Array)){

            session[index].value = null;

            this.filterBuilder.resetFieldValueByKey(this.prefixFilter + keyFilter);
        }
        else{

            session[index].value = session[index].value.filter((item): boolean => {

                return item != val;
            });

            (this.filterBuilder.getFieldsByKey(this.prefixFilter + keyFilter) as FilterCollectionField[]).forEach((collectionField: FilterCollectionField): void => {

                const fieldIndex: number = collectionField.fields.findIndex((field: ArrayFilterField): boolean => {

                    return field.value == val;
                });

                if(fieldIndex >= 0){

                    collectionField.fields.splice(fieldIndex, 1);
                }
            });
        }

        if(keyFilter === 'date'){

            this.offerFilterPanelComponent.dateFilterComponent.start = null;

            this.offerFilterPanelComponent.dateFilterComponent.end = null;
        }

        if (keyFilter === 'updatedAt'){

            this.offerFilterPanelComponent.updatedAtFilterComponent.start = null;

            this.offerFilterPanelComponent.updatedAtFilterComponent.end = null;
        }

        this._offerSearchService.setSessionFilters(this.mode, session);

        this.offerFilterPanelComponent.initFilters();

        this.offerFilterComponent.initFilters();

        this.filterBuilder.filter();
    }

    public getNightFilterFieldClasses(value: boolean) {

        return {
            'tile_checked': (value === this.hasNight)
        };
    }

    public handleNightFilter(hasNight: boolean): void {

        if(!this.filterBuilder){

            return;
        }

        this.hasNight = hasNight;

        this.filterBuilder.getFieldByKey(`${this.prefixFilter}duration.value`).operator = this.hasNight ? 'gt' : 'eq';

        this.filterBuilder.filter();
    }

    public transformOfferAttributes(items: OfferAttribute[]): { id: number, label: string }[] {

        return items.map((item: OfferAttribute): { id: number, label: string } => {

            return {
                id: item.id,
                label: this.translationService.getFallbackTranslation(item.translations).label
            };

        }).sort((a: { label: string }, b: { label: string }): number => {

            return a.label.localeCompare(b.label);
        });
    }
}
