import {Injectable} from '@angular/core';
import {FilterBuilder} from "@core/shared/models/filter";
import {Subject} from "rxjs";
import {OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, OfferCreatorSearchSessionFilter} from "@core/shared/models/offer/offer-creator-search";
import {JwtToken} from "@core/shared/models/jwt";
import {AuthenticationService} from "@core/shared/services/authentication.service";

@Injectable()
export class OfferCreatorSearchService {

    public filterBuilder: FilterBuilder = new FilterBuilder();

    public resetFilter$: Subject<void> = new Subject();

    constructor(private _authenticationService: AuthenticationService) {

        this._handleSession();
    }

    private _handleSession(): void {

        this._authenticationService.jwtToken.subscribe((value: JwtToken): void => {

            if(!value.isAuthenticated){

                sessionStorage.removeItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
            }
        });
    }

    public getSessionFilter(key: string): OfferCreatorSearchSessionFilter {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return null;
        }

        return filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public hasSessionFilter(key: string): boolean {

        const filters: OfferCreatorSearchSessionFilter[] = this.sessionFilters;

        if (!filters || !Boolean(filters.length)){

            return false;
        }

        return !!filters.find((sessionFilter: OfferCreatorSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    get hasSessionFilters(): boolean {

        return !!sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER);
    }

    get sessionFilters(): OfferCreatorSearchSessionFilter[] {

        return JSON.parse(sessionStorage.getItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER));
    }

    set sessionFilters(value: OfferCreatorSearchSessionFilter[]) {

        sessionStorage.setItem(OFFER_CREATOR_SEARCH_SESSION_STORAGE_IDENTIFIER, JSON.stringify(value));
    }
}
