<form [formGroup]="form">

    <!-- Actions -->

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.form.action.update.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="openLeaveWithoutSubmitDialog()">
                    {{ 'offer.attribute.title.back.mono.value' | translate }}
                </button>

                <!-- Suppression -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="openDeleteDialog()" *ngIf="hasAccessDelete()">
                    {{ 'offer.form.action.delete.value' | translate }}
                </button>

                <ng-container *ngIf="!offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Passer à l'étape suivante -->

                    <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isCurrentItemValid" (click)="offerFormTabValidationService.goToNextItem()">
                        {{ 'form.tab.next.value' | translate }}
                    </button>

                </ng-container>

                <ng-container *ngIf="offerFormTabValidationService.isValid || offerFormTabValidationService.currentItemIsLastItem">

                    <!-- Enregistrer -->

                    <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isValid || !submitEnabled" (click)="submit(false)">
                        {{ 'form.submit.action.value'|translate }}
                    </button>

                    <ng-container *ngIf="!form.get('onRequest').value">

                        <!-- Enregistrer et renseigner les tarifs & disponibilités -->

                        <button class="btn_cta btn_little" type="button" [disabled]="!offerFormTabValidationService.isValid || !submitEnabled" (click)="submit(true, 'availabilities')">
                            {{ 'offer.submitAndSetAvailabilities.value' | translate }}
                        </button>

                    </ng-container>

                </ng-container>
            </div>

        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>

        <ng-container *ngFor="let tabItem of tabItems">

            <mat-tab>
                <ng-template mat-tab-label>
                    <span>{{ tabItem.label | translate }}</span>
                    <span [ngClass]="offerFormTabValidationService.getTabLabelClasses(tabItem.tag)"></span>
                </ng-template>
                <ng-container *ngTemplateOutlet="tabItem.template"></ng-container>
            </mat-tab>

        </ng-container>

    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>

    <ng-container [formGroup]="form">

       <div class="offer_dates">
           <div class="col">
               <div class="row marges">
                   <div class="col_content y_items_center">
                       <span>{{'offer.list.item.createdAt.value' | translate}} : {{ offer.createdAt | date : 'shortDate' : null: localeId }}</span>
                   </div>
               </div>
               <div class="row marges">
                   <div class="col_content y_items_center">
                       <span>{{'offer.list.item.updatedAt.value' | translate}} : {{ offer.updatedAt | date : 'shortDate' : null: localeId }}</span>
                   </div>
               </div>
           </div>
       </div>

        <div class="form_part">
            <div class="ligne_form row marges">
                <div class="col_6">
                    <!-- Référence -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="reference" id="reference">
                        <label for="reference">{{ 'offer.form.fields.reference.value' | translate }}</label>
                    </div>
                </div>
                <div class="col_6">
                    <!-- Createur de l'offre -->
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="offerCreator" id="offer-creator" required="required">
                        <label for="offer-creator">{{ 'offer.form.fields.offerCreator.value' | translate }}</label>
                    </div>
                </div>
            </div>

            <!-- Type de clientèle -->

            <div class="ligne_form row marges">
                <div class="col_12">
                    <ng-container formGroupName="customerTypology">

                        <div class="form-group">
                            <select class="form-control" id="customerTypology" formControlName="id" required="required" (change)="getCustomerTypologyType($event)">
                                <option value=""></option>
                                <option *ngFor="let customerTypology of customerTypologies;"
                                        [value]="customerTypology.id">{{ translationService.getFallbackTranslation(customerTypology.translations).name }}</option>
                            </select>
                            <label class="required" for="customerTypology">{{ 'offer.form.fields.customerType.value' | translate }}</label>
                            <div class="message">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'offer.form.fields.customerType.description.value' | translate }}
                                </span>
                            </div>
                            <mat-error *ngIf="customerTypologyControl.get('id').invalid">
                                <div class="invalid-feedback">
                                    <div *ngIf="customerTypologyControl.get('id').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="form_part">

            <p class="title_part">{{'offer.public.selection.value' | translate}}</p>

            <div class="row marges ligne_form">
                <div class="col_12">

                    <!-- Sélection du public de l'offre -->

                    <div class="form-group">
                        <div class="checkbox inline" *ngFor="let offerPricePublic of offerPricePublics">
                            <input type="checkbox" id="offer-price-public-{{offerPricePublic.type}}" [value]="offerPricePublic.type" [checked]="isSelectedOfferPricePublic(offerPricePublic.type)" (change)="handleOfferPricePublic($event)">
                            <label for="offer-price-public-{{offerPricePublic.type}}">{{ offerPricePublic.label | translate }}</label>
                        </div>
                        <mat-error *ngIf="form.get('publics').invalid && (form.get('publics').dirty || form.get('publics').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('publics').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </div>

        <ng-container *ngIf="isSelectedOfferPricePublic('child')">

            <div class="form_part">

                <p class="title_part">{{'offer.childrenAge.title.value' | translate}}</p>

                <div class="row marges ligne_form">

                    <!-- Âge minimum enfant -->

                    <div class="col_md_6">
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="minChildrenAge" id="minChildrenAge">
                            <label for="minChildrenAge">{{'offer.minChildrenAge.value' | translate}}</label>
                            <mat-error *ngIf="form.get('minChildrenAge').errors && (form.get('minChildrenAge').dirty || form.get('minChildrenAge').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('minChildrenAge').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('minChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                    <!-- Âge maximum enfant -->

                    <div class="col_md_6">
                        <div class="form-group">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="maxChildrenAge" id="maxChildrenAge">
                            <label for="maxChildrenAge">{{'offer.maxChildrenAge.value' | translate}}</label>
                            <mat-error *ngIf="form.get('maxChildrenAge').errors && (form.get('maxChildrenAge').dirty || form.get('maxChildrenAge').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('maxChildrenAge').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    <div *ngIf="form.get('maxChildrenAge').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

            </div>

        </ng-container>

        <ng-container *ngIf="form.get('customerTypology').get('type').valid && form.get('publics').valid">

            <div class="form_part">

                <p class="title_part">{{'offer.presential.data.value' | translate}}</p>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="ligne_form">
                            <div class="form-group">
                                <label *ngIf="this.customerTypologyType === 'I'">{{ 'offer.presential.individual.value' | translate }}</label>
                                <label *ngIf="this.customerTypologyType === 'G'">{{ 'offer.presential.group.value' | translate }}</label>
                            </div>
                        </div>
                        <div class="ligne_form">
                            <app-core-offer-presential-form [form]="form" [control]="presentialControl" [DOMIdentifier]="'offer-presential'" [enableMaxField]="this.customerTypologyType === 'I'" ></app-core-offer-presential-form>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <div class="form_part">

            <p class="title_part">{{'offer.publicationManagement.value' | translate}}</p>

            <div class="ligne_form row_marges">

                <div class="col_12">
                    <div class="form-group">
                        <select class="form-control" formControlName="published" id="published" required="required">
                            <option value=""></option>
                            <option *ngFor="let pub of publishes$ | async"
                                    [value]="pub.name">{{ pub.label | translate }}
                            </option>
                        </select>
                        <label class="required" for="published">{{ 'offer.form.fields.publication.value' | translate }}</label>
                        <mat-error *ngIf="form.get('published').invalid && (form.get('published').dirty || form.get('published').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('published').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>

            </div>

            <!-- Offre ciblée -->

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <div class="checkbox">
                            <input type="checkbox" id="isRestricted" formControlName="isRestricted">
                            <label for="isRestricted">{{ 'offer.targeted.value' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="form.get('isRestricted').value">

                <div class="row ligne_form">
                    <app-core-offer-restricted-society-collection-select [parentForm]="form" [formName]="'restrictedSocieties'" [society]="society"></app-core-offer-restricted-society-collection-select>
                </div>

            </ng-container>

        </div>

        <ng-container formGroupName="duration">

            <div class="form_part">

                <p class="title_part">{{'offer.form.fields.duration.value' | translate}}</p>

                <!-- Durée -->

                <div class="ligne_form row marges">
                    <div class="col_6">
                        <div class="form-group">
                            <app-select-search [config]="fieldCollection.getFieldConfig('duration')" [form]="form" [value]="groupDurationControl.value?.id" (valueChange)="changeDuration($event)"></app-select-search>
                            <mat-error *ngIf="groupDurationControl.invalid">
                                <div class="invalid-feedback">
                                    <div *ngIf="this.groupDurationControl.controls.id.errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>

        <ng-container *ngIf="form.get('customerTypology').get('type').valid">

            <div class="form_part">

                <p class="title_part">{{'offer.form.fields.locales.value' | translate}}</p>

                <ng-container *ngIf="!hasAccessAutomaticTranslation">
                    <div class="ligne_form row marges">
                        <div class="col_12">
                            <div class="deletable_information">
                                <p [innerHTML]="'offer.form.fields.locales.noTranslationService.help.value' | translate"></p>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Langue -->

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox inline" *ngFor="let locale of locales$ | async">
                                <input type="checkbox" id="locale-{{locale.item.id}}" [value]="locale.item.id" [checked]="isSelectedLocale(locale.item)" [disabled]="!locale.enabled" (change)="handleLocaleSelection($event)">
                                <label for="locale-{{locale.item.id}}">{{ locale.item.label | translate }}</label>
                            </div>
                            <mat-error *ngIf="form.get('locales').invalid && (form.get('locales').dirty || form.get('locales').touched)" style="padding: 12px 0;">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('locales').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="message">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon> {{ 'offer.form.fields.locale.description.value' | translate }}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Visuel commun ou propre à chaque langue -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{'offer.translationPictureType.title.value' | translate}}</label>
                            <div class="form-group">
                                <div class="radio">
                                    <input type="radio" [value]="'common'" formControlName="translationPictureType" class="form-control" id="translationPictureTypeCommon">
                                    <label for="translationPictureTypeCommon">{{'no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="'dissociated'" formControlName="translationPictureType" class="form-control" id="translationPictureTypeDissociated">
                                    <label for="translationPictureTypeDissociated">{{'yes.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="form.get('translationPictureType').errors && (form.get('translationPictureType').dirty || form.get('translationPictureType').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('translationPictureType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="hasAccessAutomaticTranslation && form.get('locales').valid">

                    <div #enableAutomaticTranslationReference class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'offer.translation.automatic.enable.question.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="false" [(ngModel)]="enableAutomaticTranslation" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleEnableAutomaticTranslation()" class="form-control" id="enableAutomaticTranslationNo">
                                    <label for="enableAutomaticTranslationNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="true" [(ngModel)]="enableAutomaticTranslation" [ngModelOptions]="{standalone: true}" (ngModelChange)="handleEnableAutomaticTranslation()" class="form-control" id="enableAutomaticTranslationYes">
                                    <label for="enableAutomaticTranslationYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br>

                    <ng-container *ngIf="enableAutomaticTranslation">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{'offer.translation.automatic.locale.source.title.value' | translate}} : </label>
                                </div>
                            </div>
                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <div class="radio" *ngFor="let sourceLocale of sourceLocales$ | async">
                                        <input type="radio" [value]="sourceLocale.item.id" [checked]="isSelectedSourceLocale(sourceLocale.item)" [disabled]="!sourceLocale.enabled" (change)="handleSourceLocaleSelection($event)" class="form-control" id="source-locale-{{ sourceLocale.item.id }}">
                                        <label for="source-locale-{{ sourceLocale.item.id }}">{{ sourceLocale.item.label | translate }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div #sourceLocaleDescriptionReference class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <div class="message">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon> {{ 'offer.translation.automatic.locale.source.description.value' | translate }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="!!automaticTranslationSourceLocale">

                            <div class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <label>{{'offer.translation.automatic.locale.destination.title.value' | translate}} : </label>
                                    </div>
                                </div>
                            </div>

                            <div class="ligne_form row marges">
                                <div class="col_12">
                                    <div class="form-group">
                                        <div class="checkbox inline" *ngFor="let destinationLocale of destinationLocales$ | async">
                                            <input type="checkbox" id="destination-locale-{{destinationLocale.item.id}}" [value]="destinationLocale.item.id" [disabled]="!destinationLocale.enabled" [checked]="isSelectedDestinationLocale(destinationLocale.item)" (change)="handleDestinationLocaleSelection($event)">
                                            <label for="destination-locale-{{destinationLocale.item.id}}">{{ destinationLocale.item.label | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div #destinationLocalesDescriptionReference class="row marges ligne_form">
                                <div class="col_12">
                                    <div class="form-group">
                                        <div class="message">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon> {{ 'offer.translation.automatic.locale.destination.description.value' | translate }}
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <ng-container *ngIf="automaticTranslationSourceLocale && automaticTranslationDestinationLocales.length">

                            <div #translationRequestSummaryReference class="row ligne_form">

                                <ng-container *ngIf="card; else noCardToTranslate">

                                    <ng-container *ngIf="translationRequestSummary$.getValue().totalPriceTTC">

                                        <div class="ligne_form create_card_information col_12">
                                            <p [innerHTML]="'offer.translation.automatic.price.description.value' | translate:{ count: automaticTranslationDestinationLocales.length, price: (translationRequestSummary$.getValue().totalPriceTTC / 100) | parsePrice, card: (card.cardNumber + ' | ' + (card.expirationDate | slice:0:2) + '/' + (card.expirationDate | slice:2:4) ) }"></p>
                                        </div>

                                        <!-- Je souhaite traduire automatiquement mon offre et valide le montant facturé. -->

                                        <div class="row marges ligne_form">
                                            <div class="col_12">
                                                <div class="form-group">
                                                    <div class="checkbox">
                                                        <input type="checkbox" id="optinAutomaticTranslation" formControlName="optinAutomaticTranslation">
                                                        <label for="optinAutomaticTranslation">{{ 'offer.optinAutomaticTranslation.description.value' | translate }}</label>
                                                        <mat-error *ngIf="form.get('optinAutomaticTranslation').errors && (form.get('optinAutomaticTranslation').dirty || form.get('optinAutomaticTranslation').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="form.get('optinAutomaticTranslation').errors.isRequired">{{ 'offer.optinAutomaticTranslation.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </ng-container>

                                </ng-container>

                                <ng-template #noCardToTranslate>

                                    <div class="ligne_form create_card_information failed col_12">
                                        <p [innerHTML]="'offer.translation.automatic.price.noCard.value' | translate"></p>
                                    </div>

                                </ng-template>

                            </div>

                        </ng-container>

                    </ng-container>

                </ng-container>
            </div>

        </ng-container>

    </ng-container>

</ng-template>

<!-- Gestion de contenu -->

<ng-template #contentManagement>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <!-- Nom de l’offre-->

            <ng-container *ngIf="translationBuilder.items.length">

                <div class="form_part">

                    <p class="title_part">{{ 'offer.form.fields.offerName.value' | translate }}</p>

                    <div class="ligne_form row marges">
                        <div class="col_12">
                            <div class="mat-tab-translations">

                                <ng-container formArrayName="translations">

                                    <mat-tab-group [@.disabled]="true">
                                        <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                            <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                                <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                                    <div class="row marges ligne_form">
                                                        <div class="col_12">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="name"
                                                                       id="name" required="required" appInputCapitalize>
                                                                <label for="name">{{ 'offer.form.fields.offerName.value' | translate }}</label>
                                                                <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('name').errors && (translationBuilder.getItemControlByLocale(locale).get('name').dirty || translationBuilder.getItemControlByLocale(locale).get('name').touched)">
                                                                    <div class="invalid-feedback">
                                                                        <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('name').errors.pattern">{{ 'offer.form.control.name.error.pattern.value' | translate }}</div>
                                                                    </div>
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </mat-tab>
                                        </div>
                                    </mat-tab-group>

                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="translationBuilder.items.length">

                <div class="form_part">

                    <p class="title_part">{{ 'offer.form.fields.teaser.value' | translate }}</p>

                    <!-- Accroche -->

                    <div class="ligne_form row marges">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="mat-tab-translations">

                                    <ng-container formArrayName="translations">

                                        <mat-tab-group [@.disabled]="true">
                                            <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                                <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                                    <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                                        <div class="row marges ligne_form">
                                                            <div class="col_12">
                                                                <div class="form-group">
                                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="teaser" id="teaser-{{translationBuilder.getItemIndexByLocale(locale)}}" appInputTextStripTags
                                                                           appInputCountLimit [placeholderElement]="formControlTeaser" [limit]="150" [formControlReference]="translationBuilder.getItemControlByLocale(locale).get('teaser')">
                                                                    <label for="teaser-{{translationBuilder.getItemIndexByLocale(locale)}}">{{ 'offer.form.fields.teaser.value' | translate }}</label>
                                                                    <div class="message">
                                                                        <span class="message-help">
                                                                            <mat-icon class="tooltip_icon">info</mat-icon>
                                                                            {{ 'form.validation.error.maxLength' | translate: { value: 150 } }}
                                                                        </span>
                                                                            <span>
                                                                            <span #formControlTeaser class="form-control-input" ></span>
                                                                                {{ 'form.input.type' | translate }}
                                                                        </span>
                                                                    </div>
                                                                    <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('teaser').errors && (translationBuilder.getItemControlByLocale(locale).get('teaser').dirty || translationBuilder.getItemControlByLocale(locale).get('teaser').touched)">
                                                                        <div class="invalid-feedback">
                                                                            <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('teaser').errors.pattern">{{ 'form.control.error.maxLength.value' | translate: { maxLength: 150 } }}</div>
                                                                        </div>
                                                                    </mat-error>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </mat-tab>
                                            </div>
                                        </mat-tab-group>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </ng-container>

            <div class="form_part" [style.display]="translationBuilder.items.length < 1 ? 'none': 'block'">

                <p class="title_part">{{ 'offer.form.fields.interests.title.value' | translate }} ({{ 'offer.form.fields.interests.max.value' | translate }})</p>

                <!-- Intérêts -->

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <app-offer-interest-create [parentInterests]="form" [offer]="offer" (controlUpdated)="handleContentManagementValidators()"></app-offer-interest-create>
                    </div>
                </div>

            </div>

            <ng-container *ngIf="translationBuilder.items.length">
                <div class="form_part">

                    <p class="title_part">{{ 'offer.location.description.value' | translate }}</p>

                    <!-- Description -->

                    <div class="ligne_form row marges">
                        <div class="col_12">
                            <div class="ligne_form row marges">
                                <div class="col_12">
                                    <div class="form-group">
                                        <div class="mat-tab-translations">

                                            <ng-container formArrayName="translations">

                                                <!-- Description -->
                                                <mat-tab-group [@.disabled]="true">
                                                    <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                                        <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                                            <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                                                <div class="row marges ligne_form">
                                                                    <div class="col_12">
                                                                        <div class="form-group">
                                                                            <label>{{ 'offer.location.description.value' | translate }}</label>
                                                                            <app-ckeditor [form]="translationBuilder.getItemControlByLocale(locale)" [config]="descriptionEditorConfig"></app-ckeditor>
                                                                            <div class="form-group col_content y_items_center">
                                                                                <div class="message">
                                                                            <span class="message-help">
                                                                                <mat-icon class="tooltip_icon">info</mat-icon>
                                                                                {{ 'offer.location.description.info.value' | translate }} - {{ 'form.validation.error.maxLength' | translate: { value: 7000 } }}
                                                                            </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </ng-container>
                                                        </mat-tab>
                                                    </div>
                                                </mat-tab-group>
                                            </ng-container>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>

            <div class="form_part" [style.display]="translationBuilder.items.length < 1 ? 'none': 'block'">

                <p class="title_part">{{ 'offer.form.fields.included.value' | translate }}</p>

                <!-- Compris dans l'offre -->

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <app-offer-included-create [parentIncluds]="form" [offer]="offer" (controlUpdated)="handleContentManagementValidators()"></app-offer-included-create>
                    </div>
                </div>

            </div>

            <div class="form_part" [style.display]="translationBuilder.items.length < 1 ? 'none': 'block'">

                <p class="title_part">{{ 'offer.form.fields.included.no.value' | translate }}</p>

                <!-- Nom compris dans l'offre -->

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <app-offer-no-included-create [parentIncluds]="form" [offer]="offer" (controlUpdated)="handleContentManagementValidators()"></app-offer-no-included-create>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="translationBuilder.items.length">
                <div class="form_part">

                    <p class="title_part">{{ 'offer.form.fields.pictures.value' | translate }} <span>{{ 'offer.form.fields.pictures.infos.value' | translate}}</span></p>

                    <ng-container *ngIf="isTranslationPictureType('dissociated')">

                        <div class="ligne_form row marges">
                            <div class="col_12">
                                <div class="mat-tab-translations">

                                    <ng-container formArrayName="translations">

                                        <!-- Visuels -->

                                        <mat-tab-group [@.disabled]="true">
                                            <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                                <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                                    <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">

                                                        <div formArrayName="pictures" class="add_picture collection">
                                                            <div class="row marges">
                                                                <div *ngFor="let picture of getSortedPictureControls(translationBuilder.getItemControlByLocale(locale)); let j=index" class="col_4">
                                                                    <div class="removable_item">
                                                                        <ng-container [formGroupName]="j">
                                                                            <div class="ligne_form row marges">
                                                                                <div class="col_content">
                                                                                    <div class="form-group">
                                                                                        <form-field-image [config]="picturesConfig" [form]="picture.get('image')" (imageAdded)="translationPictureCopyright.readOnly = false" (imageDeleted)="translationPictureCopyright.readOnly = true"></form-field-image>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="ligne_form row marges">
                                                                                <div class="col_12">
                                                                                    <div class="form-group">
                                                                                        <input #translationPictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_{{j}}" required="required" [readOnly]="true">
                                                                                        <label for="copyright_{{j}}">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                                                        <mat-error *ngIf="translationBuilder.getItemControlByLocale(locale).get('pictures')['controls'][j].get('copyright').errors && (translationBuilder.getItemControlByLocale(locale).get('pictures')['controls'][j].get('copyright').dirty || translationBuilder.getItemControlByLocale(locale).get('pictures')['controls'][j].get('copyright').touched)">
                                                                                            <div class="invalid-feedback">
                                                                                                <div *ngIf="translationBuilder.getItemControlByLocale(locale).get('pictures')['controls'][j].get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                                            </div>
                                                                                        </mat-error>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <ng-container *ngIf="translationBuilder.getItemControlByLocale(locale).get('pictures').controls.length > 1">
                                                                                <button type="button" class="btn_cta btn_lines btn_small move_item up" [disabled]="!isDecreaseTranslationPicturePositionAllowed(translationBuilder.getItemControlByLocale(locale).get('pictures'), j)" (click)="decreaseTranslationPicturePosition(translationBuilder.getItemControlByLocale(locale).get('pictures'), j)">
                                                                                    <mat-icon>arrow_back</mat-icon>
                                                                                </button>
                                                                                <button type="button" class="btn_cta btn_lines btn_small move_item down" [disabled]="!isIncreaseTranslationPicturePositionAllowed(translationBuilder.getItemControlByLocale(locale).get('pictures'), j)" (click)="increaseTranslationPicturePosition(translationBuilder.getItemControlByLocale(locale).get('pictures'), j)">
                                                                                    <mat-icon>arrow_forward</mat-icon>
                                                                                </button>
                                                                            </ng-container>
                                                                            <button type="button" class="btn_cta btn_red btn_small remove_item" (click)="removeTranslationPicture(translationBuilder.getItemControlByLocale(locale).get('pictures'), j)">
                                                                                <mat-icon>delete</mat-icon>
                                                                            </button>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ligne_form">
                                                                <button class="btn_cta btn_little" type="button" (click)="addTranslationPicture(translationBuilder.getItemControlByLocale(locale).get('pictures'))">{{ 'offer.form.fields.picture.value' | translate }}</button>
                                                            </div>
                                                        </div>

                                                    </ng-container>
                                                </mat-tab>
                                            </div>
                                        </mat-tab-group>
                                    </ng-container>

                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="isTranslationPictureType('common')">

                        <div class="ligne_form row marges">
                            <div class="col_12">
                                <ng-container formArrayName="translations">

                                    <ng-container [formGroupName]="0">

                                        <div formArrayName="pictures" class="add_picture collection">
                                            <div class="row marges">
                                                <div *ngFor="let picture of getSortedPictureControls(translationBuilder.itemsControl.at(0)); let j=index" class="col_4">
                                                    <div class="removable_item">
                                                        <ng-container [formGroupName]="j">
                                                            <div class="ligne_form row marges">
                                                                <div class="col_content">
                                                                    <div class="form-group">
                                                                        <form-field-image [config]="picturesConfig" [form]="picture.get('image')" (imageAdded)="translationPictureCopyright.readOnly = false" (imageDeleted)="translationPictureCopyright.readOnly = true"></form-field-image>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="ligne_form row marges">
                                                                <div class="col_12">
                                                                    <div class="form-group">
                                                                        <input #translationPictureCopyright type="text" value="" placeholder=" " class="form-control" formControlName="copyright" id="copyright_{{j}}" required="required" [readOnly]="true">
                                                                        <label for="copyright_{{j}}">{{ 'offer.form.fields.copyright.value' | translate }}</label>
                                                                        <mat-error *ngIf="translationBuilder.itemsControl.at(0).get('pictures')['controls'][j].get('copyright').errors && (translationBuilder.itemsControl.at(0).get('pictures')['controls'][j].get('copyright').dirty || translationBuilder.itemsControl.at(0).get('pictures')['controls'][j].get('copyright').touched)">
                                                                            <div class="invalid-feedback">
                                                                                <div *ngIf="translationBuilder.itemsControl.at(0).get('pictures')['controls'][j].get('copyright').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                            </div>
                                                                        </mat-error>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ng-container *ngIf="translationBuilder.itemsControl.at(0).get('pictures').controls.length > 1">
                                                                <button type="button" class="btn_cta btn_lines btn_small move_item up" [disabled]="!isDecreasePicturePositionAllowed(j)" (click)="decreasePicturePosition(j)">
                                                                    <mat-icon>arrow_back</mat-icon>
                                                                </button>
                                                                <button type="button" class="btn_cta btn_lines btn_small move_item down" [disabled]="!isIncreasePicturePositionAllowed(j)" (click)="increasePicturePosition(j)">
                                                                    <mat-icon>arrow_forward</mat-icon>
                                                                </button>
                                                            </ng-container>
                                                            <button type="button" class="btn_cta btn_red btn_small remove_item" (click)="removePicture(j)">
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </ng-container>
                            </div>
                        </div>

                        <div class="row ligne_form">
                            <button class="btn_cta btn_little" type="button" (click)="addPicture()">{{ 'offer.form.fields.picture.value' | translate }}</button>
                        </div>

                    </ng-container>

                </div>
            </ng-container>

        </div>

    </ng-container>

</ng-template>

<!-- Données programme -->

<ng-template #programData>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">
            <div class="col_12">

                <!-- Ajout des photos au programmes -->

                <div class="form-group">
                    <div class="checkbox inline">
                        <input type="checkbox" formControlName="hasProgramsPictures" id="offer-program-pictures"  [checked]="form.get('hasProgramsPictures').value">
                        <label for="offer-program-pictures">{{ 'offer.program.pictures.value' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="groupDurationControl.valid">
            <div class="ligne_form">
                <app-core-offer-programs-form [parentForm]="form" [formArray]="programsControl" [limitProgram]="limitProgram" [offer]="offer" (controlUpdated)="handleContentManagementValidators()"></app-core-offer-programs-form>
            </div>
        </ng-container>

    </ng-container>

</ng-template>

<!-- Données prestataires -->

<ng-template #providerData>

    <ng-container [formGroup]="form">

        <div class="ligne_form">
            <app-offer-provider-create [parentProviderSocieties]="form" [formName]="'providerSocieties'" [offer]="offer" [society]="society"></app-offer-provider-create>
        </div>

    </ng-container>

</ng-template>

<!-- Données caractéristiques -->

<ng-template #characteristicData>

    <ng-container [formGroup]="form">

        <div class="form-group">
            <app-core-offer-attributes-form [attributesFormReference]="attributesFormReference" [parentFormSubmitSubscription]="formSubmitSubscription" [formArray]="attributesControl" [defaultOfferAttributesOffer]="offer.attributes"></app-core-offer-attributes-form>
        </div>

    </ng-container>

</ng-template>

<!-- Données localisation -->

<ng-template #locationData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="collection" cdkDropList (cdkDropListDropped)="dragDropService.dragAndDrop(locationsControl, $event)">

                <div class="form_part">

                    <!-- Type de localisation  -->

                    <div class="col_12">
                        <div class="form-group">
                            <label>{{'offer.location.type.value' | translate}}</label>

                            <div class="radio">
                                <input type="radio" [value]="false"  formControlName="enableMultipleMainLocation" class="form-control" id="multipleLocationsNo">
                                <label for="multipleLocationsNo">{{'offer.location.default.value' | translate}}</label>
                            </div>

                            <div class="radio" *ngIf="geolocationIsAllowed">
                                <input type="radio" [value]="true" formControlName="enableMultipleMainLocation" class="form-control" id="multipleLocationsYes">
                                <label for="multipleLocationsYes">{{'offer.location.multiple.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="form.get('enableMultipleMainLocation').errors && (form.get('enableMultipleMainLocation').dirty || form.get('enableMultipleMainLocation').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('enableMultipleMainLocation').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="!form.get('enableMultipleMainLocation').value && (locationsControl.controls.length > 1)">

                    <div class="form_part">

                        <!-- Circuit itinérant ou en étoile -->

                        <div class="ligne_form">

                            <div class="form-group">
                                <label>{{'offer.locations.type.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="'star'" formControlName="circuitType" class="form-control" id="circuitTypeStar">
                                    <label for="circuitTypeStar">{{'offer.locations.type.star.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="'itinerant'" formControlName="circuitType" class="form-control" id="circuitTypeItinerant">
                                    <label for="circuitTypeItinerant">{{'offer.locations.type.itinerant.value' | translate}}</label>
                                </div>
                                <mat-error *ngIf="form.get('circuitType').errors && (form.get('circuitType').dirty || form.get('circuitType').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('circuitType').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                </ng-container>

                <!-- Plusieurs adresses principales -->

                <ng-container *ngIf="form.get('enableMultipleMainLocation').value">

                    <div class="form_part">

                        <p class="title_part">{{ 'offer.location.main.value' | translate }}
                            <span>
                         {{ 'offer.location.multiple.description.value' | translate }}
                        </span>
                        </p>

                        <!-- Associations des prestaires à chaque localisation -->

                        <div class="row marges mb_24" *ngIf="locationsControl.controls.length > 0">

                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{'offer.location.providers.value' | translate}}</label>
                                    <app-offer-location-provider [form]="form" [hideSelection]="true" [providersForm]="form" [formName]="'providerLocations'" [emitProviderLocationsUpdate]="true"></app-offer-location-provider>
                                </div>
                            </div>

                        </div>

                        <mat-accordion >

                            <ng-container *ngFor="let _ of locationsControl.controls; index as i">

                                <div class="location ligne_form" [ngClass]="( locationsControl.controls.length > 1 ? 'removable_item' : '')">

                                    <app-core-offer-location-form cdkDrag [form]="form" [formArrayName]="'locations'" [index]="i" [translationBuilder]="getLocationTranslationBuilder(i)"  [accessMultipleGeolocation]="hasAccessMultipleGeolocation"></app-core-offer-location-form>

                                    <!-- Suppression de la localisation -->

                                    <ng-container *ngIf="locationsControl.controls.length > 1">

                                        <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="removeLocation(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>

                                    </ng-container>

                                </div>

                            </ng-container>

                        </mat-accordion>

                    </div>

                </ng-container>

                <!-- 1 adresse principale et plusieurs adresses secondaires -->

                <ng-container *ngIf="!form.get('enableMultipleMainLocation').value">

                    <!-- Localisation principale -->

                    <div class="form_part" >

                        <p class="title_part">{{ 'offer.location.main.value' | translate }}
                            <span>
                            {{ 'offer.location.default.description.value' | translate }}
                        </span>
                        </p>

                        <mat-accordion   id="mainLocation" >
                            <div class="location ligne_form">
                                <app-core-offer-location-form cdkDrag [form]="form" [formArrayName]="'locations'" [index]="0" [translationBuilder]="getLocationTranslationBuilder(0)" [accessMultipleGeolocation]="hasAccessMultipleGeolocation"></app-core-offer-location-form>
                            </div>
                        </mat-accordion>

                    </div>

                    <!-- Localisations secondaires -->

                    <div class="form_part" *ngIf="locationsControl.controls.length > 1">

                        <p class="title_part">{{ 'offer.location.secondary.plural.value' | translate }}</p>

                        <mat-accordion >

                            <ng-container *ngFor="let _ of locationsControl.controls; index as i">

                                <div *ngIf="i > 0" class="location ligne_form removable_item">

                                    <app-core-offer-location-form cdkDrag [form]="form" [formArrayName]="'locations'" [index]="i" [translationBuilder]="getLocationTranslationBuilder(i)" [accessMultipleGeolocation]="hasAccessMultipleGeolocation"></app-core-offer-location-form>

                                    <!-- Suppression de la localisation -->

                                    <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="removeLocation(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>

                                </div>

                            </ng-container>

                        </mat-accordion>

                    </div>

                </ng-container>

                <div class="form_part">

                    <!-- Ajout d'une localisation -->

                    <div class="ligne_form">
                        <button class="btn_cta btn_little" type="button" [disabled]="form.get('locations').value.length >= limitLocation && !form.get('enableMultipleMainLocation').value" (click)="addLocation()">{{ 'offer.location.add.action.value' | translate }}</button>
                    </div>

                    <!-- Affichage des erreurs -->


                    <div class="ligne_form" *ngIf="locationsControl.errors">
                        <mat-error>
                            <div class="invalid-feedback">
                                <div *ngIf="locationsControl.errors.maxlength">{{ (form.get('limitLocation').value > 0 ? 'offer.location.secondary.control.error.maxLength.plural.value' : 'offer.location.secondary.control.error.maxLength.value') | translate:{max: limitLocation - 1} }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>

            </div>

        </div>
    </ng-container>

</ng-template>

<!-- Gestion du SEO -->

<ng-template #seoManagement>

    <ng-container [formGroup]="form">

        <ng-container formGroupName="seo">

            <div class="content_tab">

                <!-- Index / Follow -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{'offer.seo.follow.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="follow" class="form-control" id="isSeoFollowYes">
                                <label for="isSeoFollowYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="follow" class="form-control" id="isSeoFollowNo">
                                <label for="isSeoFollowNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.seo.follow.help.value' | translate }}
                            </span>
                            <mat-error *ngIf="seoControl.get('follow').errors && (seoControl.get('follow').dirty || seoControl.get('follow').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="seoControl.get('follow').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <div class="mat-tab-translations">

                            <ng-container formArrayName="translations">

                                <mat-tab-group [@.disabled]="true">

                                    <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                        <mat-tab label="{{seoTranslationBuilder.getLocaleLabel(locale) | translate }}">
                                            <ng-container [formGroupName]="seoTranslationBuilder.getItemIndexByLocale(locale)">

                                                <!-- Slug -->

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="slug" id="seo-slug-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}" required="required">
                                                            <label for="seo-slug-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}">{{ 'offer.seo.slug.value' | translate }}</label>
                                                            <span class="message-help">
                                                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.seo.slug.help.value' | translate }}
                                                            </span>
                                                            <mat-error *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('slug').errors && (seoTranslationBuilder.getItemControlByLocale(locale).get('slug').dirty || seoTranslationBuilder.getItemControlByLocale(locale).get('slug').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('slug').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('slug').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row marges ligne_form">
                                                    <div class="col_12">

                                                        <!-- Meta Title -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="metaTitle" id="seo-meta-title-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}" required="required">
                                                            <label for="seo-meta-title-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}">{{ 'offer.seo.meta.title.value' | translate }}</label>
                                                            <span class="message-help">
                                                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.seo.meta.title.help.value' | translate }}
                                                            </span>
                                                            <mat-error *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('metaTitle').errors && (seoTranslationBuilder.getItemControlByLocale(locale).get('metaTitle').dirty || seoTranslationBuilder.getItemControlByLocale(locale).get('metaTitle').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('metaTitle').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row marges ligne_form">
                                                    <div class="col_12">

                                                        <!-- Meta Description -->

                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="metaDescription" id="seo-meta-description-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}" required="required">
                                                            <label for="seo-meta-description-{{ seoTranslationBuilder.getItemIndexByLocale(locale) }}">{{ 'offer.seo.meta.description.value' | translate }}</label>
                                                            <span class="message-help">
                                                                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.seo.meta.description.help.value' | translate }}
                                                            </span>
                                                            <mat-error *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('metaDescription').errors && (seoTranslationBuilder.getItemControlByLocale(locale).get('metaDescription').dirty || seoTranslationBuilder.getItemControlByLocale(locale).get('metaDescription').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="seoTranslationBuilder.getItemControlByLocale(locale).get('metaDescription').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-tab>
                                    </div>
                                </mat-tab-group>

                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>

        </ng-container>

    </ng-container>

</ng-template>

<!-- Commentaires internes BtoB -->

<ng-template #internalComments>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <ng-container *ngIf="translationBuilder.items.length">

                <div class="form_part">

                    <p class="title_part">{{ 'offer.form.title.salesPitch.value' | translate }}</p>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group col_content y_items_center">
                                <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.salesPitch.info.value' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row ligne_form">
                        <div class="mat-tab-translations">

                            <ng-container formArrayName="translations">

                                <mat-tab-group [@.disabled]="true">
                                    <div *ngFor="let locale of offerService.displayedLocales$.getValue()">
                                        <mat-tab label="{{translationBuilder.getLocaleLabel(locale) | translate }}">
                                            <ng-container [formGroupName]="translationBuilder.getItemIndexByLocale(locale)">
                                                <div class="row marges ligne_form">
                                                    <div class="col_12">
                                                        <div class="form-group">
                                                            <app-ckeditor [form]="translationBuilder.getItemControlByLocale(locale)" [config]="salesPitchEditorConfig"></app-ckeditor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-tab>
                                    </div>
                                </mat-tab-group>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Offre en vigilance -->

            <div class="form_part">

                <p class="title_part">{{ 'offer.form.title.vigilance.value' | translate }}</p>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group col_content y_items_center">
                            <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.vigilance.info.value' | translate }}</span>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{'offer.vigilance.put.action.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="isOnAlert" class="form-control" id="isOnAlertYes">
                                <label for="isOnAlertYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="isOnAlert" class="form-control" id="isOnAlertNo">
                                <label for="isOnAlertNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="form.get('isOnAlert').errors && (form.get('isOnAlert').dirty || form.get('isOnAlert').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('isOnAlert').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <!-- Commentaire -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <textarea placeholder=" " class="form-control" formControlName="commentAlert" id="commentAlert"></textarea>
                            <label for="commentAlert">{{'offer.vigilance.comment.value' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Options -->

<ng-template #options>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">

            <div class="col_12 y_items_center">

                <div class="form-group">
                    <mat-select formControlName="options" multiple>
                        <mat-option *ngFor="let option of options$ | async;" [value]="option.id">
                            {{ translationService.getFallbackTranslation(option.translations).name }}
                        </mat-option>
                    </mat-select>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>

<!-- Paramètres tarifs -->

<ng-template #pricingData>

    <ng-container [formGroup]="form">

        <div class="content_tab">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="price_config_description added_bottom_margin">
                        <p [innerHTML]="'offer.price.configuration.description.value'|translate"></p>
                    </div>
                    <div class="col_12">
                    <!-- Offre sur devis -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'offer.quotation.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="onRequest" class="form-control" id="onRequestNo">
                                    <label for="onRequestNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="true" formControlName="onRequest" class="form-control" id="onRequestYes">
                                    <label for="onRequestYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!form.get('onRequest').value">


                        <!-- Sélection de la devise -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <select class="form-control" id="currency" formControlName="currency" required="required">
                                        <option value=""></option>
                                        <option *ngFor="let currency of currencies" [ngValue]="currency.id">
                                            {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                                        </option>
                                    </select>
                                    <label class="required" for="currency">{{ 'currency.value' | translate }}</label>
                                    <mat-error *ngIf="form.get('currency').errors && (form.get('currency').dirty || form.get('currency').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                    <div class="message">
                                        <span class="message-help">
                                            <mat-icon class="tooltip_icon">info</mat-icon>
                                            {{ 'offer.currency.description.value' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Préférence d'affichage des tarifs -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{'offer.displayPrice.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="'HT'" formControlName="displayPrice" class="form-control" id="isDisplayPriceHT">
                                        <label for="isDisplayPriceHT">{{'offer.displayPrice.ht.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'TTC'" formControlName="displayPrice" class="form-control" id="isDisplayPriceTTC">
                                        <label for="isDisplayPriceTTC">{{'offer.displayPrice.ttc.value' | translate}}</label>
                                    </div>
                                    <mat-error *ngIf="form.get('displayPrice').errors && (form.get('displayPrice').dirty || form.get('displayPrice').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('displayPrice').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                        </div>
                                    </mat-error>
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.displayPrice.description.value' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <!-- Sélection du montant de la TVA -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label for="vatPercent">{{'tva.percent.value' | translate}}</label>
                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="vatPercent" id="vatPercent">
                                        <label for="vatPercent">{{'tva.percent.value' | translate}}</label>
                                        <mat-error *ngIf="form.get('vatPercent').errors && (form.get('vatPercent').dirty || form.get('vatPercent').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('vatPercent').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Mark-up -->

                        <div class="ligne_form">
                            <div class="form-group">
                                <label>{{'markup.define.value' | translate}}</label>
                                <div class="form-group">
                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="markup" id="markup" appInputTextParseMarkup [control]="form.get('markup')">
                                    <label for="markup">{{'markup.form.value' | translate}}</label>
                                    <mat-error *ngIf="form.get('markup').errors && (form.get('markup').dirty || form.get('markup').touched)">
                                        <div class="invalid-feedback">
                                            <div *ngIf="form.get('markup').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            <div *ngIf="form.get('markup').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            <div *ngIf="form.get('markup').errors.min">{{ 'markup.validator.min.value' | translate:{min: minMarkupPercent} }}</div>
                                        </div>
                                    </mat-error>
                                    <span class="message-help" [ngClass]="form.get('markup').value < defaultMarkupPercent ? 'error' : ''">
                                        <mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.markup.help.recommendations.value' | translate }}
                                    </span>
                                    <span class="message-help" [innerHTML]="'offer.markup.help.value' | translate"></span>
                                </div>
                            </div>

                        </div>

                    </ng-container>


                    <!-- Fuseau horaire -->

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <app-core-timezone-select [form]="form"
                                                              [required]="true"></app-core-timezone-select>
                                </div>
                            </div>

                        </div>
                        <br/>

                    </div>

                    <ng-container *ngIf="!form.get('onRequest').value">
                        <div class="row marges ligne_form">
                        <div class="col_12">


                                <div class="form-group">
                                    <label>{{'offer.quotation.form.calendar.incomplete.value' | translate}}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="false" formControlName="allowBookingRequest" class="form-control" id="allowBookingRequestNo">
                                        <label for="allowBookingRequestNo">{{'form.user.fields.no.value' | translate}}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="true" formControlName="allowBookingRequest" class="form-control" id="allowBookingRequestYes">
                                        <label for="allowBookingRequestYes">{{'form.user.fields.yes.value' | translate}}</label>
                                    </div>
                                </div>
                            </div>

                            <!-- Tarif palier -->

                            <div class="ligne_form collection" *ngIf="form.get('currency').value">

                                <!-- Ajout d'un palier -->

                                <ng-container formArrayName="priceLevels">
                                    <br/>
                                    <div *ngFor="let _ of priceLevelsControl.controls; index as i" class="price-level form-group removable_item">
                                        <ng-container [formGroupName]="indexAsString(i)">
                                            <div class="row marges ligne_form">
                                                <div class="col_6">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="min" id="offer-price-level-min-{{ i }}">
                                                        <label for="offer-price-level-min-{{ i }}">{{'offer.price.level.min.value' | translate}}</label>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('min').errors && (getPriceLevelControl(i).get('min').dirty || getPriceLevelControl(i).get('min').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('min').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="getPriceLevelControl(i).get('min').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col_6">
                                                    <div class="form-group">
                                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="max" id="offer-price-level-max-{{ i }}">
                                                        <label for="offer-price-level-max-{{ i }}">{{'offer.price.level.max.value' | translate}}</label>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('max').errors && (getPriceLevelControl(i).get('max').dirty || getPriceLevelControl(i).get('max').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('max').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                <div *ngIf="getPriceLevelControl(i).get('max').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <label>{{'offer.price.level.type.value' | translate}}</label>
                                                        <div class="radio" *ngFor="let type of offerPriceLevelTypes">
                                                            <div class="radio">
                                                                <input type="radio" [value]="type.type" formControlName="type" class="form-control" id="priceLevelType-{{ type.type }}-{{ i }}" (ngModelChange)="handlePriceLevelValues(getPriceLevelControl(i))">
                                                                <label for="priceLevelType-{{ type.type }}-{{ i }}" *ngIf="getCurrencyById(form.get('currency').value)">{{ type.label | translate: {currency: getCurrencyById(form.get('currency').value).symbol} }}</label>
                                                            </div>
                                                        </div>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('type').errors && (getPriceLevelControl(i).get('type').dirty || getPriceLevelControl(i).get('type').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('type').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="getPriceLevelControl(i).get('type').valid">
                                                <div class="row marges ligne_form">
                                                    <div class="col_6">
                                                        <div class="form-group">
                                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="value" id="offer-price-level-value-{{ i }}" appInputTextParsePrice [control]="getPriceLevelControl(i).get('value')">
                                                            <label for="offer-price-level-value-{{ i }}" *ngIf="getCurrencyById(form.get('currency').value)">{{ (getPriceLevelControl(i).get('type').value === 'percent' ? 'offer.price.level.type.percent.value' : 'offer.price.level.type.price.ttc.value') | translate:{currency: getCurrencyById(form.get('currency').value).symbol} }}</label>
                                                            <mat-error *ngIf="getPriceLevelControl(i).get('value').errors && (getPriceLevelControl(i).get('value').dirty || getPriceLevelControl(i).get('value').touched)">
                                                                <div class="invalid-feedback">
                                                                    <div *ngIf="getPriceLevelControl(i).get('value').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                                    <div *ngIf="getPriceLevelControl(i).get('value').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                </div>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="getPriceLevelControl(i).get('type').value === 'price'">
                                                    <div class="row marges ligne_form">
                                                        <div class="col_6">
                                                            <div class="form-group">
                                                                <input type="text" value="" placeholder=" " class="form-control" formControlName="additionalValue" id="offer-price-level-additionalValue-{{ i }}" appInputTextParsePrice [control]="getPriceLevelControl(i).get('additionalValue')">
                                                                <label for="offer-price-level-additionalValue-{{ i }}" *ngIf="getCurrencyById(form.get('currency').value)">{{ 'offer.price.level.type.price.ht.value' | translate:{currency: getCurrencyById(form.get('currency').value).symbol} }}</label>
                                                                <mat-error *ngIf="getPriceLevelControl(i).get('additionalValue').errors && (getPriceLevelControl(i).get('additionalValue').dirty || getPriceLevelControl(i).get('additionalValue').touched)">
                                                                    <div class="invalid-feedback">
                                                                        <div *ngIf="getPriceLevelControl(i).get('additionalValue').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                                                        <div *ngIf="getPriceLevelControl(i).get('additionalValue').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                                                    </div>
                                                                </mat-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <div class="row marges ligne_form">
                                                <div class="col_12">
                                                    <div class="form-group">
                                                        <label>{{'offer.price.level.increaseArea.value' | translate}}</label>
                                                        <div class="radio" *ngFor="let increaseArea of offerPriceLevelIncreaseAreas">
                                                            <div class="radio">
                                                                <input type="radio" [value]="increaseArea.type" formControlName="priceIncreaseArea" class="form-control" id="priceIncreaseArea-{{ increaseArea.type }}-{{ i }}">
                                                                <label for="priceIncreaseArea-{{ increaseArea.type }}-{{ i }}">{{ increaseArea.label | translate }}</label>
                                                            </div>
                                                        </div>
                                                        <mat-error *ngIf="getPriceLevelControl(i).get('priceIncreaseArea').errors && (getPriceLevelControl(i).get('priceIncreaseArea').dirty || getPriceLevelControl(i).get('priceIncreaseArea').touched)">
                                                            <div class="invalid-feedback">
                                                                <div *ngIf="getPriceLevelControl(i).get('priceIncreaseArea').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                            </div>
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <!-- Suppression du palier -->

                                        <button type="button" class="btn_cta btn_red btn_little remove_item" (click)="removePriceLevel(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                                <button class="btn_cta btn_little" type="button" (click)="addPriceLevel()">{{ 'offer.price.level.add.action.value' | translate }}</button>
                            </div>
                        </div>

                    </ng-container>

            </div>
        </div>
        </div>
    </ng-container>

</ng-template>

<!-- Bon cadeaux -->

<ng-template #giftVoucherData >

    <ng-container [formGroup]="form">

        <div class="form_part">

            <div class="ligne_form row marges">

                <div class="col_12 col_md_6">

                    <!-- Offre proposé en Bon cadeau -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'offer.form.fields.giftVoucher.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="enableGiftVoucher" class="form-control" id="enableGiftVoucherNo" (ngModelChange)="handleEnableGiftVoucher()">
                                    <label for="enableGiftVoucherNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="true" [attr.disabled]="form.get('onRequest').value ? true : null"  formControlName="enableGiftVoucher" class="form-control" id="enableGiftVoucherYes" (ngModelChange)="handleEnableGiftVoucher()">
                                    <label for="enableGiftVoucherYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="message" *ngIf="form.get('onRequest').value">
                                    <span class="message-help" >
                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'offer.form.fields.giftVoucher.onRequest.value' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="form.get('enableGiftVoucher').value && !society.isValidMangoPayAccount">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'offer.giftVoucher.mangopay.validation.required.value' | translate }}
                                </span>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <!-- Tarifs adultes -->

                    <ng-container *ngIf="form.get('enableGiftVoucher').value" >

                        <div class="row marges ligne_form" formGroupName="giftVoucher">

                            <ng-container *ngIf="isSelectedOfferPricePublic('adult')">

                                <div class="col_6">
                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="adultPriceTTC" id="adultPriceTTC" required appInputTextParsePrice [control]="giftVoucherControl.get('adultPriceTTC')">
                                        <label for="adultPriceTTC">{{ 'offer.giftVoucher.price.adult.ttc.value' | translate: {currency: '€'} }}</label>
                                        <mat-error>
                                            <div *ngIf="giftVoucherControl.get('adultPriceTTC').invalid && (giftVoucherControl.get('adultPriceTTC').dirty || giftVoucherControl.get('adultPriceTTC').touched)">
                                                <div *ngIf="giftVoucherControl.get('adultPriceTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="giftVoucherControl.get('adultPriceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="isSelectedOfferPricePublic('child')">

                                <div class="col_6">
                                    <div class="form-group">
                                        <input type="text" value="" placeholder=" " class="form-control" formControlName="childPriceTTC" id="childPriceTTC" required appInputTextParsePrice [control]="giftVoucherControl.get('childPriceTTC')">
                                        <label for="childPriceTTC">{{'offer.giftVoucher.price.child.ttc.value' | translate: {currency: '€'} }}</label>
                                        <mat-error>
                                            <div *ngIf="giftVoucherControl.get('childPriceTTC').invalid && (giftVoucherControl.get('childPriceTTC').dirty || giftVoucherControl.get('childPriceTTC').touched)">
                                                <div *ngIf="giftVoucherControl.get('childPriceTTC').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                <div *ngIf="giftVoucherControl.get('childPriceTTC').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>

                            </ng-container>

                        </div>

                        <div class="row marges ligne_form">
                            <div class="col_6">
                                <div class="form-group">
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'offer.prices.help.value' | translate }}
                                </span>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>

            </div>

        </div>

        <div class="form_part">

            <ng-container *ngIf="form.get('enableGiftVoucher').value" >

                <ng-container formGroupName="giftVoucher">

                    <!--   Distribution du bon cadeaux  -->
                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'giftVoucher.template.type.value' | translate}}</label>

                                <div class="checkbox inline" *ngFor="let giftVoucherType of offerGiftVoucherTypes">
                                    <input type="checkbox" id="{{giftVoucherType.type}}" [value]="giftVoucherType.type" [checked]="isSelectedGiftVoucherType(giftVoucherType.type)" (change)="handleGiftVoucherType($event)">
                                    <label for="{{giftVoucherType.type}}">{{ giftVoucherType.label | translate }}</label>
                                </div>
                                <mat-error *ngIf="giftVoucherControl.get('types').invalid && (giftVoucherControl.get('types').dirty || giftVoucherControl.get('types').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="giftVoucherControl.get('types').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </ng-container>

            </ng-container>

        </div>

        <!-- Offre proposé en vente en ligne -->

        <div class="form_part">

            <div class="ligne_form row marges">

                <div class="col_12 col_md_6">

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{'offer.form.fields.onlineSale.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="enableOnlineSale" class="form-control" id="enableOnlineSaleNo">
                                    <label for="enableOnlineSaleNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="true" [attr.disabled]="form.get('onRequest').value ? true : null" formControlName="enableOnlineSale" class="form-control" id="enableOnlineSaleYes">
                                    <label for="enableOnlineSaleYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="message" *ngIf="form.get('onRequest').value">
                                    <span class="message-help" >
                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'offer.form.fields.onlineSale.onRequest.value' | translate }}
                                    </span>
                                </div>
                                <div class="message">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'offer.form.fields.onlineSale.info.value' | translate }}
                                    </span>
                                </div>
                                <div class="message">
                                    <span class="message-help">
                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'offer.form.fields.onlineSale.permanentOption.info.value' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <ng-container *ngIf="form.get('enableOnlineSale').value">

            <div class="form_part">

                <div class="ligne_form row marges">

                    <div class="col_12 col_md_6">

                        <div class="row marges ligne_form">
                            <div class="col_12">
                                <div class="form-group">
                                    <label>{{ 'offer.stock.management.type.selection.value' | translate }}</label>
                                    <div class="radio">
                                        <input type="radio" [value]="'dissociated'" formControlName="stockManagementType" class="form-control" id="stockManagementTypeDissociated">
                                        <label for="stockManagementTypeDissociated">{{ 'offer.stock.management.type.dissociated.value' | translate }}</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" [value]="'common'" formControlName="stockManagementType" class="form-control" id="stockManagementTypeCommon">
                                        <label for="stockManagementTypeCommon">{{ 'offer.stock.management.type.common.value' | translate }}</label>
                                    </div>
                                    <div class="message">
                                        <span class="message-help"><mat-icon class="tooltip_icon">info</mat-icon>{{ 'offer.stock.management.type.info.value' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </ng-container>

    </ng-container>

</ng-template>
