import {Component, OnInit, ViewChild} from '@angular/core';
import {map} from "rxjs/operators";
import {Society} from "@core/shared/models/society";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";
import {Pagination} from "@core/shared/models/pagination";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {FilterBuilder, FilterField} from "@core/shared/models/filter";
import {OfferCreatorFilterComponent, OfferCreatorFilterKey} from "@core/components/offer-creator/offer-creator-filter/offer-creator-filter.component";
import {FilterCollectionField} from "@core/shared/models/filter/filter-collection";
import {OfferCreatorSearchSessionFilter} from "@core/shared/models/offer/offer-creator-search";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {TranslateService} from "@ngx-translate/core";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";

@Component({
    selector: 'app-core-page-offer-creator-list',
    templateUrl: './page-offer-creator-list.component.html',
    styleUrls: ['./page-offer-creator-list.component.scss'],
    providers: [
        OfferCreatorSearchService
    ]
})
export class PageOfferCreatorListComponent implements OnInit {

    @ViewChild(OfferCreatorFilterComponent, { static: true }) offerCreatorFilterComponent: OfferCreatorFilterComponent;

    public currentUser: User = null;

    public itemOffset: number = 0;

    public itemPerPage: number = 21;

    public totalItems: number = 0;

    public items: Society[] = [];

    constructor(
        public userService: UserService,
        private _translateService: TranslateService,
        private _offerCreatorService: OfferCreatorService,
        private _offerCreatorSearchService: OfferCreatorSearchService
    ) {
    }

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._initFilterBuilder();

        setTimeout((): void => {

            if (this._offerCreatorSearchService.hasSessionFilters) {

                this._initFiltersValues();
            }
            else{

                this._initDefaultFiltersValues();
            }

            this._loadItems(true);
        });
    }

    private _initFilterBuilder(): void {

        this.filterBuilder.filterCallback = (): void => {

            this._loadItems(true);
        };

        this._offerCreatorSearchService.resetFilter$.subscribe((): void => {

            this.filterBuilder.resetFields();

            this._loadItems(true);
        });
    }

    private _initDefaultFiltersValues(): void {

        this.currentUser.marketplacePreferences.forEach((item: MarketplacePreference): void => {

            switch (item.filter){

                case 'society.networkOfferCreators.id':

                    const ids: number[] = (item.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

                    ids.forEach((id: number): void => {

                        (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.NetworkOfferCreator, 'in', id));
                    });

                    break;
            }
        });
    }

    private _initFiltersValues(): void {

        this.filterBuilder.resetFields();

        // Destinations

        if (this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Destination)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Destination).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Destination) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.Destination, 'in', value));
            });
        }

        // Cibles

        if (this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Target)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Target).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Target) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.Target, 'in', value));
            });
        }

        // Réseaux de créateurs d'offres

        if (this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.NetworkOfferCreator, 'in', value));
            });
        }

        // Type d'offres

        if (this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.OfferType)){

            (this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.OfferType).value as string[]).forEach((value: string): void => {

                (this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.OfferType) as FilterCollectionField).fields.push(new ArrayFilterField(OfferCreatorFilterKey.OfferType, 'in', value));
            });
        }

        // Nom

        if (this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Name)){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Name).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Name).value;
        }

        // Présent dans mon catalogue

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            if (this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.InCatalog))){

                this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.InCatalog).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.InCatalog).value;
            }
        }

        // Labellisé RSE

        if (this._offerCreatorSearchService.hasSessionFilter((OfferCreatorFilterKey.HasRseLabel))){

            this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasRseLabel).value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.HasRseLabel).value;
        }
    }

    private _storeSessionFilters(): void {

        const sessionFilters: OfferCreatorSearchSessionFilter[] = [];

        // Destinations

        sessionFilters.push({
            key: OfferCreatorFilterKey.Destination,
            value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.Destination) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                return collectionField.fields.map((field: FilterField): string => {

                    return field.value;
                });
            }))
        });

        // Cibles

        sessionFilters.push({
            key: OfferCreatorFilterKey.Target,
            value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.Target) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                return collectionField.fields.map((field: FilterField): string => {

                    return field.value;
                });
            }))
        });

        // Réseaux de créateurs d'offres

        sessionFilters.push({
            key: OfferCreatorFilterKey.NetworkOfferCreator,
            value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.NetworkOfferCreator) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                return collectionField.fields.map((field: FilterField): string => {

                    return field.value;
                });
            }))
        });

        // Type d'offres

        sessionFilters.push({
            key: OfferCreatorFilterKey.OfferType,
            value: [].concat.apply([], (this.filterBuilder.getFieldsByKey(OfferCreatorFilterKey.OfferType) as FilterCollectionField[]).map((collectionField: FilterCollectionField): string[] => {

                return collectionField.fields.map((field: FilterField): string => {

                    return field.value;
                });
            }))
        });

        // Nom

        sessionFilters.push({
            key: OfferCreatorFilterKey.Name,
            value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.Name).value || null
        });

        // Présent dans mon catalogue

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            sessionFilters.push({
                key: OfferCreatorFilterKey.InCatalog,
                value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.InCatalog).value || null
            });
        }

        // Labellisé RSE

        sessionFilters.push({
            key: OfferCreatorFilterKey.HasRseLabel,
            value: this.filterBuilder.getFieldByKey(OfferCreatorFilterKey.HasRseLabel).value || null
        });

        this._offerCreatorSearchService.sessionFilters = sessionFilters;
    }

    private _loadItems(resetOffset: boolean): void {

        this._storeSessionFilters();

        if (resetOffset) {

            this.itemOffset = 0;
        }

        this._offerCreatorService.getPaginationItemsAPI(this.itemsApiParams)
            .pipe(
                map(this.mapItemPaginationApiResult)
            )
            .subscribe((items: Society[]): void => {

                this.hydrateItems(items, resetOffset);
            })
        ;
    }

    public hydrateItems(items: Society[], reset: boolean): void {

        if (reset) {

            this.items = [];
        }

        this.items.push(...items);
    }

    public removeFilter(field: FilterField): void {

        const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

        switch (key){

            case OfferCreatorFilterKey.Destination:
            case OfferCreatorFilterKey.Target:
            case OfferCreatorFilterKey.NetworkOfferCreator:
            case OfferCreatorFilterKey.OfferType:

                const fields: FilterCollectionField[] = this.filterBuilder.getFieldsByKey(key) as FilterCollectionField[];

                fields.forEach((collectionField: FilterCollectionField): void => {

                    const fieldIndex: number = collectionField.fields.findIndex((item: ArrayFilterField): boolean => {

                        return item.value === field.value;
                    });

                    if(fieldIndex >= 0){

                        collectionField.fields.splice(fieldIndex, 1);
                    }
                });

                break;

            default:

                this.filterBuilder.resetFieldValueByKey(key);
        }

        this._storeSessionFilters();

        this.offerCreatorFilterComponent.initFilters();

        this.filterBuilder.filter();
    }

    public loadMoreItems(): void {

        this.itemOffset++;

        this._loadItems(false);
    }

    get itemsApiParams(): string[] {

        const params: string[] = [];

        params.push(`sort[name]=asc`);

        params.push(`page=${this.itemOffset + 1}`);

        params.push(`limit=${this.itemPerPage}`);

        params.push(...this.mapFilterParams);

        return params;
    }

    get mapFilterParams(): string[] {

        const params: string[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

            switch (key){

                case OfferCreatorFilterKey.OfferType:

                    const values: OfferDurationType[] = (field as FilterCollectionField).fields.map((offerTypeField: FilterField): OfferDurationType => {

                        return offerTypeField.value;
                    });

                    values.forEach((value: string): void => {

                        params.push(`offers.duration.type[lkin][]=${ value }`);
                    });

                    break;

                case OfferCreatorFilterKey.InCatalog:

                    if(Boolean(field.value)){

                        params.push(`${key}[${field.operator}]=${this.currentUser.society.id}`);
                    }

                    break;

                case OfferCreatorFilterKey.HasRseLabel:

                    if(Boolean(field.value)){

                        params.push(field.serialize);
                    }

                    break;

                default:

                    params.push(field.serialize);
            }
        });

        return params;
    }

    get currentFilters(): { field: FilterField, formattedValue: string }[] {

        const items: { field: FilterField, formattedValue: string }[] = [];

        const fields: FilterField[] = this.filterBuilder.fields.filter((field: FilterField): boolean => {

            return Boolean(field.serialize.length);
        });

        fields.forEach((field: FilterField): void => {

            const key: OfferCreatorFilterKey = field.key as OfferCreatorFilterKey;

            switch (key){

                case OfferCreatorFilterKey.Destination:
                case OfferCreatorFilterKey.Target:
                case OfferCreatorFilterKey.NetworkOfferCreator:
                case OfferCreatorFilterKey.OfferType:

                    const fields: FilterField[] = (field as FilterCollectionField).fields;

                    const choices: { id: number, label: string }[] = (field as FilterCollectionField).choices;

                    fields.forEach((field: FilterField): void => {

                        const choice: { id: number, label: string } = choices.find((choice: { id: number, label: string }): boolean => {

                            return choice.id === field.value;
                        });

                        items.push({
                            field: field,
                            formattedValue: choice ? choice.label : null
                        });
                    });

                    break;

                case OfferCreatorFilterKey.InCatalog:

                    if(Boolean(field.value)){

                        items.push({
                            field: field,
                            formattedValue: this._translateService.instant('offerCreator.inCatalog.mine.value')
                        });
                    }

                    break;

                case OfferCreatorFilterKey.HasRseLabel:

                    if(Boolean(field.value)){

                        items.push({
                            field: field,
                            formattedValue: this._translateService.instant('rse.labelled.value')
                        });
                    }

                    break;

                default:

                    items.push({
                        field: field,
                        formattedValue: `${ this._translateService.instant(`offerCreator.filter.${key}.value`) } : ${ field.isCollection ? (field as FilterCollectionField).fields.map((field: FilterField): string => {

                            return field.value.toString();

                        }).join(', ') : field.value }`
                    });
            }
        });

        return items;
    }

    get mapItemPaginationApiResult(): (data: Pagination<Society>) => Society[] {

        return (data: Pagination<Society>) => {

            this.totalItems = data.totalItems;

            return data.items;
        };
    }

    get hasMoreItems(): boolean {

        return this.items.length < this.totalItems;
    }

    get filterBuilder(): FilterBuilder {

        return this._offerCreatorSearchService.filterBuilder;
    }
}
