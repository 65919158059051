import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {FilterBuilder, FilterComponent} from '@core/shared/models/filter';
import {ActivatedRoute, Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, Pagination} from '@core/shared/models/pagination';
import {User} from '@core/shared/models/user';
import {merge} from 'rxjs';
import {map, startWith, switchMap} from 'rxjs/operators';
import {Society} from '@core/shared/models/society';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ChannelService} from '@core/shared/services/channel.service';
import {Channel} from '@core/shared/models/channel';
import {ChannelTranslation} from '@core/shared/models/channel-translation';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import {ArrayFilterField} from '@core/shared/models/filter/array-filter-field';
import {SelectionModel} from '@angular/cdk/collections';
import {TextFilterField} from "@core/shared/models/filter/text-filter-field";
import {TranslationService} from "@core/shared/services/translation.service";

@Component({
    selector: 'app-page-channel-restricted-list',
    templateUrl: './page-channel-restricted-list.component.html',
    styleUrls: ['./page-channel-restricted-list.component.scss']
})
export class PageChannelRestrictedListComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;

    @ViewChildren("filter") filterComponents: QueryList<FilterComponent>;

    public totalItems: number = 0;

    public displayedColumns: string[] = [
        'actions',
        'ownerName',
        'createdAt',
        'marketplace',
        'enable',
        'individualQuota',
        'groupQuota',
        'translations.name',
        'translations.title',
        'integrationType'
    ];

    public types: object[] = [
        {
            id: 'js',
            label: this._translateService.instant('channel.restricted.typeIntegration.js.value')
        },
        {
            id: 'sub_domain',
            label: this._translateService.instant('channel.restricted.typeIntegration.subDomain.value')
        }

    ];

    public isEnableItems: object[] = [
        {
            id: 1,
            label: this._translateService.instant('channel.restricted.isEnable.yes.value')
        },
        {
            id: 0,
            label: this._translateService.instant('channel.restricted.isEnable.no.value')
        }
    ];

    public filterBuilder: FilterBuilder;

    public user : Pagination<User>;

    public items: Channel[] = [];

    public society : Society;

    public collaborator: User[] = [];

    public userId: number;

    public locale: string;

    public societyId : number;

    public societies : Society[];

    public selection = new SelectionModel<Channel>(true, []);

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _translateService: TranslateService,
        private _channelService: ChannelService,
        private _snackBar: MatSnackBar,
        public translationService: TranslationService
    ) {
    }

    ngOnInit() {

        this._initFilterBuilder();

    }

    ngAfterViewInit(): void {

        this.sort.sortChange.subscribe(() => {

            this._resetPagination();
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {

                    return this._channelService.getPaginationItemsAPI(this.itemsApiParams);

                }),
                map(this.mapApiResult),
            ).subscribe((data: Channel[]): void => {

                this.items = data;

                this.selection.clear();
            }
        );
    }

    private _initFilterBuilder(): void {

        this.filterBuilder = new FilterBuilder();

        this.filterBuilder.filterCallback = (): void => {

            this._resetPagination();

            this._channelService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

                this.items = data;
            });
        };
    }

    private _resetPagination(): void {

        this.paginator.pageIndex = 0;
    }

    public resetFilters(): void {

        this.filterBuilder.resetFields();

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._resetPagination();

        this._channelService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

            this.items = data;
        });
    }

    public getModel(item: Channel): Channel {

        return item;
    }

    get displayedFilterColumns(): string[] {

        return this.displayedColumns.map((column: string): string => {

            return this.getFilterColumnDef(column);
        });
    }

    public getFilterColumnDef(value: string): string {

        return `${value}-filter`;
    }

    public openDeleteItemDialog(id: number): void {

        const channel: Channel = this.items.find((item: Channel): boolean => {

            return item.id === id;
        });

        const translation: ChannelTranslation = this.translationService.getFallbackTranslation(channel.translations);

        const title : string = this._translateService.instant('channel.restricted.actions.delete.value');

        const content : string = this._translateService.instant('channel.restricted.actions.delete.content.value', {
            channel: translation.name
        });

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._channelService.deleteItemAPI(channel.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('channel.restricted.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._resetPagination();

                this._channelService.getPaginationItemsAPI(this.itemsApiParams).pipe(map(this.mapApiResult)).subscribe((data: Channel[]): void => {

                    this.items = data;
                });
            });
        });
    }

    public addItem(): void {

        this._router.navigate(['account/channel/restricted/create']);
    }

    public editItem(id: number): void{

        this._router.navigate(['account/channel/restricted/update', id]);
    }

    public get itemsApiParams(): string[] {

        const customFilter : TextFilterField = new TextFilterField('society.channelRestricted', 'eq', '1');

        const roleFilters: ArrayFilterField[] = [
            new ArrayFilterField('admin.roles', 'lkin', 'ROLE_OFFER_DISTRIBUTOR'),
            new ArrayFilterField('admin.roles', 'lkin', 'ROLE_INSTITUTIONAL'),
            new ArrayFilterField('admin.roles', 'lkin', 'ROLE_FEDERATION')
        ];

        const params: string[] = [
            `page=${this.paginator.pageIndex + 1}`,
            `limit=${this.paginator.pageSize}`,
            customFilter.serialize
        ];

        roleFilters.forEach((roleFilter: ArrayFilterField): void => {

            params.push(roleFilter.serialize);
        });

        if(this.sort.active && this.sort.direction !== ''){

            params.push(`sort[${this.sort.active}]=${this.sort.direction.toUpperCase()}`);
        }

        if(this.filterBuilder.hasFilters){

            params.push(this.filterBuilder.serializedRequest);
        }

        return params;
    }

    get mapApiResult(): (data: Pagination<Channel>) => Channel[] {

        return (data: Pagination<Channel>) => {

            this.totalItems = data.totalItems;

            return data.items;
        }
    }

    get pageSize(): number {

        return DEFAULT_PAGE_SIZE;
    }

    get pageSizeOptions(): number[] {

        return DEFAULT_PAGE_SIZE_OPTIONS;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    public getIntegrationType(channel){
        let integrationType = "";

        if(channel.enableJS){
            integrationType = this._translateService.instant('channel.restricted.typeIntegration.js.value');
        }

        if(channel.enableSubDomain){
            integrationType = this._translateService.instant('channel.restricted.typeIntegration.subDomain.value');
        }

        return integrationType;
    }

    public getIsEnable(channel: Channel): string {

        return this._translateService.instant(`channel.restricted.isEnable.${channel.enable ? 'yes' : 'no'}.value`);
    }
}
