<div class="row marges">
    <div class="col_4 content_card" *ngFor="let item of items">
        <app-core-offer-card
            [item]="item"
            [roles]="roles"
            [layoutView]="layoutView"
            [mode]="mode"
            [identificationNumber]="identificationNumber"
            [publicPrice]="publicPrice"
        ></app-core-offer-card>
    </div>
</div>
<div class="row more_offer" *ngIf="hasMoreOffers">
    <div class="col x_center">
        <button class="btn_cta btn_highlight" type="button" (click)="loadMoreOffers()">
            {{ 'offer.more.value' | translate }}
        </button>
    </div>
</div>
