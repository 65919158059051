import {AfterContentInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {Country, CountryTranslation} from "@core/shared/models/country";
import {ActivatedRoute, Router} from "@angular/router";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {ApiService} from "@core/shared/services/api.service";
import {CountryService} from "@core/shared/services/country.service";
import {FormService} from "@core/shared/services/form.service";
import {User} from "@core/shared/models/user";
import {REGEX_EMAIL} from "@core/shared/models/regex";
import {LOCALE_ITEMS, LocaleItem} from "@core/shared/models/translation";
import {Access, AccessType} from "@core/shared/models/access";
import {Address} from "@core/shared/models/address";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {TranslationService} from "@core/shared/services/translation.service";
import {map} from "rxjs/operators";
import {restrictLocales} from "@core/shared/utils/locale";

@Component({
    selector: 'app-core-page-collaborator-create',
    templateUrl: './page-collaborator-create.component.html',
    styleUrls: ['./page-collaborator-create.component.scss'],
    providers: [
        FormService
    ]
})
export class PageCollaboratorCreateComponent implements OnInit, AfterContentInit {

    public user: User;

    public countries$: Observable<Country[]>;

    public isAddressLikePrincipalAccount: boolean = false;

    public locales$: Observable<LocaleItem[]>;

    public accessesList : Access[] = [];

    public accesses : Access[] = [];

    public initialAccesses : Access[] = [];

    public excludedAccessTypes: AccessType[] = [
        'USER_CREATE',
        'USER_EDIT',
        'USER_DELETE',
        'USER_DELETE_IS_MINE',
        'INVOICE_LIST_IS_MINE',
        'INVOICE_DOWNLOAD_IS_MINE',
        'SUBSCRIPTION_LIST',
        'SOCIETY_SUBSCRIPTION_LIST',
        'SOCIETY_SUBSCRIPTION_READ_SI_MINE',
        'SOCIETY_SUBSCRIPTION_CREATE_IS_MINE',
        'SOCIETY_SUBSCRIPTION_EDIT_IS_MINE',
        'SOCIETY_SUBSCRIPTION_DELETE_IS_MINE'
    ];

    public defaultRevokedAccessTypes: AccessType[] = [
        'BOOKING_RESTRICTED_IS_MINE',
        'GIFT_VOUCHER_RESTRICTED_IS_MINE',
        'GIFT_VOUCHER_NO_SERVICE_RESTRICTED_IS_MINE'
    ];

    public autoAssignedAccessTypes: { [p: string]: AccessType[] } = {
        'BOOKING_RESTRICTED_IS_MINE': ['BOOKING_LIST_IS_MINE'],
        'GIFT_VOUCHER_RESTRICTED_IS_MINE': ['GIFT_VOUCHER_LIST_IS_MINE'],
        'GIFT_VOUCHER_NO_SERVICE_RESTRICTED_IS_MINE': ['GIFT_VOUCHER_NO_SERVICE_LIST_IS_MINE']
    }

    public autoRevokedAccessTypes: { [p: string]: AccessType[] } = {
        'BOOKING_RESTRICTED_IS_MINE': ['BOOKING_EDIT_RESPONSIBLE_USER_IS_MINE'],
        'GIFT_VOUCHER_RESTRICTED_IS_MINE': ['GIFT_VOUCHER_EDIT_RESPONSIBLE_USER_IS_MINE']
    }

    public currentTitle:string = '';

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _userService: UserService,
        private _apiService: ApiService,
        private _countryService: CountryService,
        private _changeDetectorRef: ChangeDetectorRef,
        public formService: FormService,
        public translationService: TranslationService,
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { user: User, accesses : Access[] }): void => {

            this._loadCountries();

            this._loadLocales();

            this._initAccesses(data.accesses);

            this._initAccessesList(data.accesses);

            this._initForm();

            this.initialAccesses = data.accesses;

            this.user = data.user;
        });
    }

    ngAfterContentInit(): void {

        this._changeDetectorRef.detectChanges();
    }

    private _initAccesses(accesses: Access[]): void {

        const managedAccesses: Access[] = accesses.filter((access: Access): boolean => {

            return access.isManaged && !this.defaultRevokedAccessTypes.includes(access.tag);
        });

        managedAccesses.forEach((access : Access): void => {

            this.accesses.push(access);
        });

        const unmanagedAccesses: Access[] = accesses.filter((access: Access): boolean => {

            return !access.isManaged && !this.excludedAccessTypes.includes(access.tag);
        });

        unmanagedAccesses.forEach((access : Access): void => {

            this.accesses.push(access);
        });
    }

    private _initAccessesList(accesses: Access[]): void {

        const managedAccesses: Access[] = accesses.filter((access: Access): boolean => {

            return access.isManaged;
        });

        managedAccesses.forEach((access : Access): void => {

            this.accessesList.push(access);
        });

        this.accessesList.sort((a: Access, b: Access): number => {

            const aTitle: string = this._translateService.instant(this._generateTitle(a, true));
            const bTitle: string = this._translateService.instant(this._generateTitle(b, true));

            return aTitle.localeCompare(bTitle);
        });
    }

    private _clearAccesses(): void {

        this.accesses = [];
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS).pipe(
            map((localeItems: LocaleItem[] ):LocaleItem[] => restrictLocales(localeItems) )
        );
    }

    private _loadCountries(): void {

        const params: string[] = [
            `paymentSupported[eq]=1`
        ];

        this.countries$ = this._countryService.getItemsAPI(params);
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            civility: [1, [Validators.required]],
            lastName: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            email: ['', [Validators.pattern(REGEX_EMAIL)]],
            switchboardPhone: ['', [Validators.required]],
            directPhone: [''],
            cellphone: [''],
            service: [''],
            locale: [this.localeId, [Validators.required]],
            timezone: [this._userService.currentUser.value.timezone, [Validators.required]],
            address: this._formBuilder.group({
                address: ['', [Validators.required]],
                additionalAddress: [''],
                zipcode: ['', [Validators.required]],
                city: ['', [Validators.required]],
                region: ['', [Validators.required]],
                country: ['', [Validators.required]]
            }),
            comment: [''],
            accesses: [this.accesses]
        });

        this.formService.submitCallback = (): void => {
            const data: object = Object.assign(this.form.value, {
                society: {
                    id: this.user.societyAdmin.id
                },
                username: this.form.get('email').value,
                roles : this.user.roles,
                switchboardPhone : this.form.get('switchboardPhone').value.e164Number,
                directPhone : this.form.get('directPhone').value?.e164Number,
                cellphone : this.form.get('cellphone').value?.e164Number
            });

            this._userService.createItemAPI(data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.create.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.redirectToList();
            });
        };
    }

    private _generateTitle(access: Access, applySuffix: boolean = false): string {

        return access.label.split('.')[0] + '.' + access.label.split('.')[1] + (applySuffix ? '.value' : '');
    }

    public allCheck(): void {

        this._clearAccesses();

        this._initAccesses(this.initialAccesses);

        const existingAccessTypes: AccessType[] = this.accessesList.map((item: Access): AccessType => {

            return item.tag;
        });

        const existingDefaultRevokedAccessTypes: AccessType[] = this.defaultRevokedAccessTypes.filter((accessType: AccessType): boolean => {

            return existingAccessTypes.includes(accessType);
        });

        existingDefaultRevokedAccessTypes.forEach((defaultRevokedAccessType: AccessType): void => {

            this.accesses.push(this.accessesList.find((access: Access): boolean => {

                return access.tag === defaultRevokedAccessType;
            }));
        });

        this.accessesList.forEach((access: Access): void => {

            if(this.isAutoRevokedAccess(access)){

                const index: number = this.accesses.findIndex((item: Access): boolean => {

                    return access.tag === item.tag;
                });

                this.accesses.splice(index, 1);
            }
        });

        this.form.get('accesses').patchValue(this.accesses);
    }

    public noneCheck(): void {

        this._clearAccesses();

        const unmanagedAccesses: Access[] = this.initialAccesses.filter((access: Access): boolean => {

            return !access.isManaged && !this.excludedAccessTypes.includes(access.tag);
        });

        unmanagedAccesses.forEach((access : Access): void => {

            this.accesses.push(access);
        });

        this.form.get('accesses').patchValue(this.accesses);
    }

    public handleAddress(): void {

        if (this.isAddressLikePrincipalAccount){

            const address: Address = this.user.society.addresses.find((item: Address): boolean => {

                return item.type === 'mailing';
            });

            this.addressForm.patchValue({
                address: address.address,
                additionalAddress : address.additionalAddress,
                zipcode: address.zipcode,
                city: address.city,
                region: address.region,
                country: address.country
            });
        }
        else{

            this.addressForm.patchValue({
                address: '',
                additionalAddress: '',
                zipcode: '',
                city: '',
                region: '',
                country: ''
            });
        }
    }

    public redirectToList(): void {

        this._router.navigate(['account/collaborator/list']);
    }

    public patchAccessesValue(access: Access): void {

        const result = this.accesses.findIndex((acc: Access): boolean => {

            return acc.id === access.id;
        });

        if(result > -1){

            this.accesses.splice(result, 1);
        }
        else{

            this.accesses.push(access);
        }

        // Affection automatique des accès

        if(access.tag in this.autoAssignedAccessTypes){

            const existingAccessTypes: AccessType[] = this.accessesList.map((item: Access): AccessType => {

                return item.tag;
            });

            const existingAutoAssignedAccessTypes: AccessType[] = this.autoAssignedAccessTypes[access.tag].filter((accessType: AccessType): boolean => {

                return existingAccessTypes.includes(accessType);
            });

            existingAutoAssignedAccessTypes.forEach((accessType: AccessType): void => {

                const autoAssignedAccess: Access = this.accessesList.find((access: Access): boolean => {

                    return access.tag === accessType;
                });

                const isFromCurrentAccesses: boolean = !!this.accesses.find((access: Access): boolean => {

                    return access.tag === autoAssignedAccess.tag;
                });

                if(!isFromCurrentAccesses){

                    this.accesses.push(autoAssignedAccess);
                }
            });
        }

        // Révocation automatique des accès

        if(access.tag in this.autoRevokedAccessTypes){

            const existingAccessTypes: AccessType[] = this.accessesList.map((item: Access): AccessType => {

                return item.tag;
            });

            const existingAutoRevokedAccessTypes: AccessType[] = this.autoRevokedAccessTypes[access.tag].filter((accessType: AccessType): boolean => {

                return existingAccessTypes.includes(accessType);
            });

            existingAutoRevokedAccessTypes.forEach((accessType: AccessType): void => {

                const autoRevokedAccess: Access = this.accessesList.find((access: Access): boolean => {

                    return access.tag === accessType;
                });

                const isFromCurrentAccesses: boolean = !!this.accesses.find((access: Access): boolean => {

                    return access.tag === autoRevokedAccess.tag;
                });

                if(isFromCurrentAccesses){

                    const index: number = this.accesses.findIndex((access: Access): boolean => {

                        return access.tag === autoRevokedAccess.tag;
                    });

                    this.accesses.splice(index, 1);
                }
            });
        }

        this.form.get('accesses').setValue(this.accesses);
    }

    public hasAccess(access: Access): boolean {

        const result = this.accesses.find((acc: Access): boolean => {

            return acc.id === access.id;
        });

        return !!(result);
    }

    public isAccessToHide(access: Access): boolean {

        const hideItems: AccessType[] = [
            'OFFER_OPTION_LIST_IS_MINE',
            'OFFER_OPTION_CREATE_IS_MINE',
            'OFFER_OPTION_EDIT_IS_MINE',
            'OFFER_OPTION_DELETE_IS_MINE',
        ];

        return hideItems.some((item: AccessType): boolean => {

            return item === access.tag;
        });
    }

    public isAutoAssignedAccess(access: Access): boolean {

        const matches: AccessType[] = Object.keys(this.autoAssignedAccessTypes).filter((accessType: AccessType): boolean => {

            return this.autoAssignedAccessTypes[accessType].includes(access.tag);

        }) as AccessType[];

        if(!matches.length){

            return false;
        }

        return !!this.accesses.find((access: Access): boolean => {

            return matches.includes(access.tag);
        });
    }

    public isAutoRevokedAccess(access: Access): boolean {

        const matches: AccessType[] = Object.keys(this.autoRevokedAccessTypes).filter((accessType: AccessType): boolean => {

            return this.autoRevokedAccessTypes[accessType].includes(access.tag);

        }) as AccessType[];

        if(!matches.length){

            return false;
        }

        return !!this.accesses.find((access: Access): boolean => {

            return matches.includes(access.tag);
        });
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ]
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get addressForm(): UntypedFormGroup {

        return this.form.get('address') as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    getTitleRole(access: Access, isTest) {

        const title: string = this._generateTitle(access, false);

        if (this.currentTitle != title) {

            if (!isTest) {

                this.currentTitle = title;
            }

            return title;

        } else {

            return '';
        }
    }
}
