import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FilterBuilder, FilterComponent} from "@core/shared/models/filter";
import {OfferCreatorSearchService} from "@core/shared/services/offer-creator/offer-creator-search.service";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {forkJoin, Observable} from "rxjs";
import {OfferAttributeTypeTagType} from "@core/shared/models/offer-attribute-type";
import {map} from "rxjs/operators";
import {TranslationService} from "@core/shared/services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {TextFilterComponent} from "@core/components/filter/text-filter/text-filter.component";
import {SelectArrayMultipleFilterComponent} from "@core/components/filter/select-array-multiple-filter/select-array-multiple-filter.component";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";

export enum OfferCreatorFilterKey {
    Destination = 'offers.attributes.attribute.region',
    Target = 'offers.customerTypology.id',
    NetworkOfferCreator = 'networkOfferCreators.id',
    OfferType = 'offers.types',
    Name = 'name',
    InCatalog = 'offers.inCatalog',
    HasRseLabel = 'information.hasRseLabel'
}

@Component({
    selector: 'app-core-offer-creator-filter',
    templateUrl: './offer-creator-filter.component.html',
    styleUrls: ['./offer-creator-filter.component.scss']
})
export class OfferCreatorFilterComponent implements OnInit, AfterViewInit {

    @ViewChildren(`
        destinationFilter,
        targetFilter,
        networkOfferCreatorFilter,
        offerTypeFilter,
        stayTypeFilter,
        nameFilter,
        inCatalogFilter,
        hasRseLabelFilter
    `) filterComponents: QueryList<FilterComponent>;

    @ViewChild('destinationFilter', {static: true}) destinationFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('targetFilter', {static: true}) targetFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('networkOfferCreatorFilter', {static: true}) networkOfferCreatorFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('offerTypeFilter', {static: true}) offerTypeFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('nameFilter', { static: true }) nameFilterComponent: TextFilterComponent;

    @ViewChild('inCatalogFilter', { static: false }) inCatalogFilterComponent: TextFilterComponent;

    @ViewChild('hasRseLabelFilter', { static: true }) hasRseLabelFilterComponent: TextFilterComponent;

    public readonly OfferCreatorFilterKey = OfferCreatorFilterKey;

    public currentUser: User = null;

    public offerAttributeRegions: { id: number, label: string }[] = [];

    public offerTypes: { id: ('day'|'stay'), label: string }[] = [];

    public offerCustomerTypologies: { id: number, label: string }[] = [];

    public offerNetworkOfferCreators: { id: number, label: string }[] = [];

    constructor(
        private _translateService: TranslateService,
        private _translationService: TranslationService,
        private _offerCreatorSearchService: OfferCreatorSearchService,
        private _offerAttributeService: OfferAttributeService,
        private _customerTypologyService: CustomerTypologyService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        public userService: UserService
    ) {}

    ngOnInit() {

        this.currentUser = this.userService.currentUser.value;

        this._initOfferAttributes();

        this._initOfferTypes();

        this._initOfferCustomerTypologies();

        this._initOfferNetworkOfferCreators();
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this.initFilters();
        });
    }

    private _initOfferAttributes(): void {

        const observables: Observable<{ tag: OfferAttributeTypeTagType, attributes: OfferAttribute[] }>[] = [];

        const assignations: { tag: OfferAttributeTypeTagType, callback: (items: OfferAttribute[]) => void }[] = [
            {
                tag: 'region',
                callback: (items: OfferAttribute[]): void => {

                    this.offerAttributeRegions = this._mapOfferAttributes(items);
                }
            }
        ];

        assignations.forEach((assignation: { tag: OfferAttributeTypeTagType }): void => {

            const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', assignation.tag);

            const params: string[] = [
                typeFilter.serialize
            ];

            observables.push(this._offerAttributeService.getItemsAPI(params).pipe(
                map((attributes: OfferAttribute[]): { tag: OfferAttributeTypeTagType, attributes: OfferAttribute[] } => {

                    return {
                        tag: assignation.tag,
                        attributes: attributes
                    };
                }))
            );
        });

        forkJoin(observables).subscribe((items: { tag: OfferAttributeTypeTagType, attributes: OfferAttribute[] }[]): void => {

            items.forEach((item: { tag: OfferAttributeTypeTagType, attributes: OfferAttribute[] }): void => {

                const assignation: { tag: OfferAttributeTypeTagType, callback: (items: OfferAttribute[]) => void } = assignations.find((assignation: { tag: OfferAttributeTypeTagType}): boolean => {

                    return assignation.tag === item.tag;
                });

                assignation.callback(item.attributes);
            });
        });
    }

    private _initOfferTypes(): void {

        this.offerTypes = [
            {
                id: 'day',
                label: this._translateService.instant(`offer.type.day.value`)
            },
            {
                id: 'stay',
                label: this._translateService.instant(`offer.type.stay.value`)
            }
        ]
    }

    private _initOfferCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((customerTypologies: CustomerTypology[]): void => {

            this.offerCustomerTypologies = customerTypologies.map((item: CustomerTypology): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: this._translationService.getFallbackTranslation(item.translations).name
                };
            });
        });
    }

    private _initOfferNetworkOfferCreators(): void {

        this._networkOfferCreatorService.getItemsAPI().subscribe((networkOfferCreators: NetworkOfferCreator[]): void => {

            this.offerNetworkOfferCreators = networkOfferCreators.map((item: NetworkOfferCreator): { id: number, label: string } => {

                return {
                    id: item.id,
                    label: item.name
                };
            });
        });
    }

    private _mapOfferAttributes(items: OfferAttribute[]): { id: number, label: string }[] {

        return items.map((item: OfferAttribute): { id: number, label: string } => {

            return {
                id: item.id,
                label: this._translationService.getFallbackTranslation(item.translations).label
            };

        }).sort((a: { label: string }, b: { label: string }): number => {

            return a.label.localeCompare(b.label);
        });
    }

    public initFilters(): void {

        if (!this._offerCreatorSearchService.hasSessionFilters) {

            return;
        }

        // Destinations

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Destination)){

            this.destinationFilterComponent.values = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Destination).value;
        }

        // Cibles

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Target)){

            this.targetFilterComponent.values = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Target).value;
        }

        // Réseaux de créateurs d'offres

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator)){

            this.networkOfferCreatorFilterComponent.values = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.NetworkOfferCreator).value;
        }

        // Type d'offres

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.OfferType)){

            this.offerTypeFilterComponent.values = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.OfferType).value;
        }

        // Nom

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.Name)){

            this.nameFilterComponent.value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.Name).value;
        }

        // Présent dans mon catalogue

        if(this.userService.hasOneOfThisRoles(this.currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.InCatalog)){

                this.inCatalogFilterComponent.value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.InCatalog).value;
            }
        }

        // Labellisé RSE

        if(this._offerCreatorSearchService.hasSessionFilter(OfferCreatorFilterKey.HasRseLabel)){

            this.hasRseLabelFilterComponent.value = this._offerCreatorSearchService.getSessionFilter(OfferCreatorFilterKey.HasRseLabel).value;
        }
    }

    public reset(): void {

        this.filterComponents.forEach((filterComponent: FilterComponent): void => {

            filterComponent.reset();
        });

        this._offerCreatorSearchService.resetFilter$.next();
    }

    get filterBuilder(): FilterBuilder {

        return this._offerCreatorSearchService.filterBuilder;
    }
}
