import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Society} from "../models/society";
import {SocietyService} from "../services/society.service";

@Injectable({
    providedIn: 'root'
})
export class SocietyItemSelfResolver  {

    constructor(
        private _societyService: SocietyService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this._societyService.getItemSelfAPI();
    }
}
