import {Injectable, NgZone} from '@angular/core';
import {Offer} from "@core/shared/models/offer";
import {BehaviorSubject, Subject} from "rxjs";
import {OFFER_SEARCH_SESSION_STORAGE_ORIGIN, OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER, OfferSearchSessionFilter} from "@core/shared/models/offer/offer-search";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {JwtToken} from "@core/shared/models/jwt";
import {ModeType} from "@core/shared/models/offer/offer-list";

@Injectable()
export class OfferSearchService {

    public offerSelected$: Subject<Offer> = new Subject<Offer>();

    public offerUnselected$: Subject<Offer> = new Subject<Offer>();

    public selectedOffers: BehaviorSubject<Offer[]> = new BehaviorSubject([]);

    public selectedAccessRequestOffers: BehaviorSubject<Offer[]> = new BehaviorSubject([]);

    public multipleAccessRequestSelectionActivated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public refreshFiltersState$: Subject<void> = new Subject();

    public updateFilter$: Subject<{ key: string, value: any }> = new Subject();

    public extrasData$: BehaviorSubject<{[p: string]: any }> = new BehaviorSubject({});

    public updateOfferAllowed: boolean = false;

    public viewOfferAllowed: boolean = false;

    public accessRequestOfferAllowed: boolean = false;

    public reportOfferAllowed: boolean = false;

    public addOfferToCatalogAllowed: boolean = false;

    public deleteOfferFromCatalogAllowed: boolean = false;

    public selectOfferAllowed: boolean = false;

    public displayReferencedOffer: boolean = false;

    public displayOnlyGiftVoucherOffer: boolean = false;

    public displayOnlyOnlineSaleOffer: boolean = false;

    public restrictPermanentOptionOffer: boolean = false;

    public selectedLocales: Subject<string[]> = new Subject<string[]>();

    public totalFilteredOffers: number = 0;

    constructor(private _authentificationService: AuthenticationService, private ngZone : NgZone) {

        this._handleSession();
    }

    private _handleSession(): void {

        this._authentificationService.jwtToken.subscribe((value: JwtToken): void => {

            if(!value.isAuthenticated){

                OFFER_SEARCH_SESSION_STORAGE_ORIGIN.forEach((origin) => {

                    sessionStorage.removeItem(`${origin}-${OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER}`);

                });

            }
        });
    }

    public selectOffer(offer: Offer): void {

        const items: Offer[] = this.selectedOffers.value;

        items.push(offer);

        this.offerSelected$.next(offer);

        this.selectedOffers.next(items);

        this.updateFilter$.next({ key: 'selectedOffers', value: items });
    }

    public unselectOffer(offer: Offer): void {

        const items: Offer[] = this.selectedOffers.value;

        const index: number = items.findIndex((item: Offer): boolean => {

            return offer.id === item.id;
        });

        items.splice(index, 1);

        this.offerUnselected$.next(offer);

        this.selectedOffers.next(items);

        this.updateFilter$.next({ key: 'selectedOffers', value: items });
    }

    public isSelectedOffer(offer: Offer): boolean {

        let match: Offer;

        this.ngZone.run(() => {

            match = this.selectedOffers.value.find((item: Offer): boolean => {
                return offer.id === item.id;
            });

        });

        return !!match;
    }

    public selectOfferToAccessRequest(offer: Offer): void {

        const items: Offer[] = this.selectedAccessRequestOffers.value;

        items.push(offer);

        this.selectedAccessRequestOffers.next(items);
    }

    public unselectOfferToAccessRequest(offer: Offer): void {

        const items: Offer[] = this.selectedAccessRequestOffers.value;

        const index: number = items.findIndex((item: Offer): boolean => {

            return offer.id === item.id;
        });

        items.splice(index, 1);

        this.selectedAccessRequestOffers.next(items);
    }

    public isSelectedOfferToAccessRequest(offer: Offer): boolean {

        const match: Offer = this.selectedAccessRequestOffers.value.find((item: Offer): boolean => {

            return offer.id === item.id;
        });

        return !!match;
    }

    public getSessionFilter(origin: ModeType, key: string): OfferSearchSessionFilter {

        const filters = this.getSessionFilters(origin);

        if (!filters) {
            return null;
        }

        return  filters.find((sessionFilter: OfferSearchSessionFilter): boolean => {

            return sessionFilter.key === key;
        });
    }

    public hasSessionFilters(origin: ModeType): boolean {

        return !!sessionStorage.getItem( `${origin}-${OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER}`);
    }

    public getSessionFilters(origin: ModeType): OfferSearchSessionFilter[] {

        return JSON.parse(sessionStorage.getItem(`${origin}-${OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER}`));
    }

    public setSessionFilters(origin: ModeType, value: OfferSearchSessionFilter[]): void {

        sessionStorage.setItem( `${origin}-${OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER}`, JSON.stringify(value));
    }

    public updateExtraData(key: string, value: any): void {

        const extrasData = this.extrasData$.getValue();

        extrasData[key] = value;

        this.extrasData$.next(extrasData);
    }
}
