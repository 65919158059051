import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {InputConfig} from "../input";
import { merge } from 'lodash';
import * as _moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {TranslationService} from "@core/shared/services/translation.service";

const moment:any = _moment;

export const MY_FORMATS:any = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

/**
 * Configuration des paramètres du champ
 */
export interface InputDateConfig extends InputConfig {

    /**
     * Attributs du champ de formulaire
     */
    attrs?: {
        required?: boolean;
        placeholder?: string;
        disabled?: boolean;
        max?:string;
        min?:string;
    };

    /**
     * Autorise la réinitialisation du champ via un bouton
     */
    enableReset?: boolean;

    /**
     * Format d'enregistrement de la date
     */
    format?: string;
}

function localeUser(translationService: TranslationService) {
    return translationService.getUserLocale();
}

/**
 * Configuration par défaut du champ
 */


/**
 * Champ input date
 */
@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
    styleUrls: ['./input-date.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        {provide: MAT_DATE_LOCALE, useFactory: localeUser, deps: [TranslationService]}
    ]
})
export class InputDateComponent implements OnInit {

    public DEFAULT_CONFIG: InputDateConfig = {
        id: null,
        attrs: {
            required: false,
            placeholder: null,
            disabled: false,
            max:null,
            min:null
        },
        enableReset: false,
        format: 'YYYY-MM-DD HH:mm:ss'
    };

    /**
     * Paramètres du champ
     */
    @Input() config: InputDateConfig;

    /**
     * Formulaire
     */
    @Input() form: UntypedFormGroup;

    /**
     * Événement à la mise à jour du champ
     */
    @Output() valueChange = new EventEmitter();


    @Input() disabled: boolean;

    /**
     * Constructeur
     *
     * @constructor
     */
    public constructor() {
    }

    /**
     * Implémentation du formControl et des validateurs
     */
    public ngOnInit(): void {

        // Initialisation de la configuration
        this.config = this.config ? merge(this.DEFAULT_CONFIG, this.config) : this.DEFAULT_CONFIG;

        /**
         * Validateurs du champs
         */
        const validators: any[] = [];

        this.form.addControl(this.config.id, new UntypedFormControl(''));

        if (this.config.attrs.required) {

            validators.push(Validators.required);
        }

        this.form.controls[this.config.id].setValidators(validators);

        this.form.value[this.config.id] = null;

        if (('disabled' in this.config.attrs) && this.config.attrs.disabled) {
            this.disabled = this.config.attrs.disabled;
        }

        if(this.disabled){

            this.form.controls[this.config.id].disable();
        }

        this.form.valueChanges.subscribe( (): void => {

            if (this.form.value[this.config.id] && this.form.value[this.config.id] !== '') {

                this.form.value[this.config.id] = moment(this.form.value[this.config.id]).format(this.config.format || this.DEFAULT_CONFIG.format);

            } else {

                this.form.value[this.config.id] = null;
            }
        });
    }

    public dateChange(event:any): void {

        this.form.value[this.config.id] = moment(event.value).format(this.config.format || this.DEFAULT_CONFIG.format);

        this.valueChange.emit(event);
    }

    /**
     * Réinitialisation du champ
     */
    reset(): void {

        this.form.get(this.config.id).reset();

        this.valueChange.emit({
            value: null
        });
    }

    filterDate = (d: Date | null): boolean => {
        if(this.config.attrs.max && this.config.attrs.min){

            var max =  moment(this.config.attrs.max);
            var min =  moment(this.config.attrs.min);

            return d >= min && d <= max;
        }

       if(this.config.attrs.max){
           var max =  moment(this.config.attrs.max);

            return d <= max;
       }

        if(this.config.attrs.min){
            var min =  moment(this.config.attrs.min);
            return d >= min;
        }

        return true;
    };
}
