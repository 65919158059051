<div class="offer_creator_item">

    <ng-container *ngIf="isMine">

        <div class="row marges ligne_form" style="margin-top: 12px;">
            <div class="col_12">
                <div class="form-group">
                    <span class="message-help"><mat-icon>info</mat-icon>{{ 'offerCreator.data.information.value' | translate }}</span>
                </div>
            </div>
        </div>

    </ng-container>

    <form [formGroup]="form" (ngSubmit)="formService.submit()">

        <div class="title_offer">

            <!-- Voir les offres -->

            <ng-container *ngIf="!isMine && (item.details.totalMarketplaceOffers > 0)">

                <div class="row ligne_form x_end actions">

                    <div class="col_content y_items_center">

                        <ng-container *ngIf="(userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])) && (item.details.totalCatalogOffers > 0)">

                            <button class="btn_cta btn_little btn_lines" type="button" (click)="redirectToCatalog()">
                                {{ 'offerCreator.offers.inCatalog.myself.value' | translate }}
                            </button>

                        </ng-container>

                        <button class="btn_cta btn_little" type="button" (click)="redirectToMarketplace()">
                            {{ 'offer.plural.himself.action.see.value' | translate }}
                        </button>

                    </div>

                </div>

            </ng-container>

            <div class="row marges">

                <!-- Logo -->

                <div class="col_content y_items_center">
                    <img [src]="item.logo ? ( item.logo | fileSource | async) : defaultVisualUrl" [alt]="item.name">
                </div>

                <!-- Nom -->

                <div class="col y_items_center">
                    <p class="title">{{ item.name }}</p>
                </div>
            </div>
        </div>

        <!-- Visuels -->

        <ng-container *ngIf="item.pictures.length">

            <div class="row ligne_form offer_pictures">

                <!-- Visuel principal -->

                <div [ngClass]="{ 'col_6' : item.pictures.length > 1, 'col_12' : item.pictures.length <= 1 }" class="col_md_6 col_left">
                    <img [src]="item | offerCreatorPictureSource: item.pictures[0] : 'rectangle' : (item.pictures.length > 1 ? 600 : 1000) : 90"/>
                </div>

                <!-- Visuels secondaires -->

                <div class="col_12 col_md_6 col_right">

                    <div class="row h_full">

                        <ng-container *ngIf="item.pictures.length > 1">

                            <div *ngFor="let picture of item.pictures.slice(1, countDefaultDisplayedPictures); last as isLast" class="col_6">

                                <img [src]="item | offerCreatorPictureSource: picture : 'rectangle' : 300 : 90"/>

                                <ng-container *ngIf="isLast && item.pictures.length <= countDefaultDisplayedPictures">

                                    <div (click)="openPictureGalleryDialog()" class="see_more">
                                        <span>{{ 'offer.picture.open.value' | translate }}</span>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="isLast && item.pictures.length > countDefaultDisplayedPictures">

                                    <div (click)="openPictureGalleryDialog()" class="see_more">
                                        <span>{{ 'offer.picture.more.question.value' | translate }}</span>
                                    </div>

                                </ng-container>

                            </div>

                        </ng-container>

                    </div>

                </div>

            </div>

        </ng-container>

        <!-- Nombre d'offres -->

        <ng-container *ngIf="(isMine && item.details.totalMarketplaceOffers > 0) || (!isMine && userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']))">

            <div class="ligne_form infos">

                <div class="row marges">

                    <div class="col_12 col_lg">

                        <!-- Nombre d'offres dans la marketplace -->

                        <div class="ck-content">
                            <p class="titre_h4">
                                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">
                                    {{ item.details.totalMarketplaceOffers | translationFromQuantity:'offer.inMarketplace.total.empty.value' : 'offer.inMarketplace.total.singular.value' : 'offer.inMarketplace.total.plural.value' }}
                                </ng-container>
                                <ng-container *ngIf="isMine || userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])">
                                    {{ item.details.totalMarketplaceOffers | translationFromQuantity:'offer.inMarketplace.self.total.empty.value' : 'offer.inMarketplace.self.total.singular.value' : 'offer.inMarketplace.self.total.plural.value' }}
                                </ng-container>
                            </p>
                        </div>

                        <br>

                        <div class="flags row marges">
                            <div class="col_6 col_md_4 col_lg_3 flag_item" *ngFor="let offerCountTranslation of marketplaceOfferCountTranslations">
                                <div class="flag flag-{{ offerCountTranslation.locale }}"></div>
                                <div class="count">
                                    <ng-container *ngIf="!isMine; else offerCountTranslationMarketplace">

                                        <a (click)="redirectToMarketplaceByLocale(offerCountTranslation.locale)">
                                            <ng-container *ngTemplateOutlet="offerCountTranslationMarketplace"></ng-container>
                                        </a>

                                    </ng-container>

                                    <ng-template #offerCountTranslationMarketplace>
                                        {{ offerCountTranslation.marketplace | translationFromQuantity:'offer.total.empty.value':'offer.total.singular.value':'offer.total.plural.value' }}
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

                        <div class="col_12 col_lg">

                            <!-- Nombre d'offres dans le catalogue -->

                            <div class="ck-content">
                                <p class="titre_h4">
                                    {{ item.details.totalCatalogOffers | translationFromQuantity: 'offer.inCatalog.total.empty.value' : 'offer.inCatalog.total.singular.value' : 'offer.inCatalog.total.plural.value' }}
                                </p>
                            </div>

                            <br>

                            <div class="flags row marges">
                                <div class="col_6 col_md_4 col_lg_3 flag_item" *ngFor="let offerCountTranslation of catalogOfferCountTranslations">
                                    <div class="flag flag-{{ offerCountTranslation.locale }}"></div>
                                    <div class="count">
                                        <a (click)="redirectToCatalogByLocale(offerCountTranslation.locale)">
                                            {{ offerCountTranslation.catalog | translationFromQuantity:'offer.total.empty.value':'offer.total.singular.value':'offer.total.plural.value' }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>

            </div>

        </ng-container>

        <!-- Présentation -->

        <ng-container *ngIf="translationService.getFallbackTranslation(item.details.societyInformation.translations).presentation">

            <div class="row ligne_form infos no_border no_padding">

                <div class="col_12">
                    <div [innerHTML]="translationService.getFallbackTranslation(item.details.societyInformation.translations).presentation|safeHtml" class="description_offer ck-content"></div>
                </div>
            </div>

        </ng-container>

        <!-- Informations complémentaires -->

        <div class="row marges ligne_form">

            <div class="col_6 additional_informations">

                <div class="infos green">

                    <!-- Cibles clientèle -->

                    <ng-container *ngIf="item.details.customerTypologies.length > 0">

                        <div class="txt_offer">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'customer.target.plural.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <ng-container *ngFor="let customerTypology of item.details.customerTypologies">

                                    <span>{{ translationService.getFallbackTranslation(customerTypology.translations).name }}</span><br/>

                                </ng-container>
                            </p>

                        </div>

                    </ng-container>

                    <!-- Destinations -->

                    <ng-container *ngIf="item.details.allRegions || (item.details.regions.length > 0)">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'destination.plural.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <ng-container *ngIf="item.details.allRegions; else regions">

                                    <span>{{ 'france.all.value' | translate }}</span>

                                </ng-container>

                                <ng-template #regions>

                                    <ng-container *ngFor="let region of item.details.regions">

                                        <span>{{ translationService.getFallbackTranslation(region.translations).label }}</span><br/>

                                    </ng-container>

                                </ng-template>
                            </p>

                        </div>

                    </ng-container>

                    <!-- Types d'offres -->

                    <ng-container *ngIf="offerTypes.length > 0">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'offer.plural.type.plural.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <ng-container *ngFor="let offerType of offerTypes">

                                    <span>{{ ('offer.type.' + offerType + '.value') | translate }}</span><br/>

                                </ng-container>
                            </p>

                        </div>

                    </ng-container>

                </div>

            </div>

            <div class="col_6 additional_informations">

                <div class="infos green">

                    <!-- Contact -->

                    <div class="txt_offer little_border">

                        <div class="ck-content">
                            <p class="titre_h4">{{ 'contact.value' | translate }}</p>
                        </div>
                        <br>
                        <p>
                            <span>{{ item.phone }}</span><br/>
                        </p>

                    </div>

                    <!-- Immatriculation -->

                    <ng-container *ngIf="item.registration">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'immatriculation.value' | translate }} <span class="info">{{ 'immatriculation.info.value' | translate }}</span></p>
                            </div>
                            <br>
                            <p>
                                <span>{{ item.registration }}</span><br/>
                            </p>

                        </div>

                    </ng-container>

                    <!-- Langues parlées -->

                    <ng-container *ngIf="(item.details.societyInformation.spokenLanguages.length > 0) || item.details.societyInformation.hasOtherSpokenLanguages">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'language.plural.spoken.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <ng-container *ngFor="let spokenLanguage of item.details.societyInformation.spokenLanguages">

                                    <span>{{ ('locale.' + spokenLanguage + '.value') | translate }}</span><br/>

                                </ng-container>

                                <ng-container *ngIf="item.details.societyInformation.hasOtherSpokenLanguages">

                                    <br>

                                    <p style="font-weight: bold;">{{ 'other.pluralize.value' | translate }}</p>

                                    <span>{{ translationService.getFallbackTranslation(item.details.societyInformation.translations).spokenLanguages }}</span><br/>

                                </ng-container>
                            </p>

                        </div>

                    </ng-container>

                    <!-- Labellisé RSE -->

                    <ng-container *ngIf="item.details.societyInformation.hasRseLabel">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'rse.labelled.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <span>{{ translationService.getFallbackTranslation(item.details.societyInformation.translations).rseLabel }}</span><br/>
                            </p>

                        </div>

                    </ng-container>

                    <!-- Créateurs d’offres membre -->

                    <ng-container *ngIf="item.networkOfferCreators.length > 0">

                        <div class="txt_offer little_border">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'offerCreator.member.plural.value' | translate }}</p>
                            </div>
                            <br>
                            <p>
                                <ng-container *ngFor="let networkOfferCreator of item.networkOfferCreators">

                                    <span>{{ networkOfferCreator.name }}</span><br/>

                                </ng-container>
                            </p>

                        </div>

                    </ng-container>

                </div>

                <ng-container *ngIf="item.details.termsAndConditions.length > 0">

                    <br>

                    <div class="infos terms_and_conditions no_border no_padding">

                        <div class="txt_offer">

                            <div class="ck-content">
                                <p class="titre_h4">{{ 'termsAndConditions.value' | translate }}</p>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col_4">

                                    <p *ngFor="let termsAndCondition of item.details.termsAndConditions">
                                        <span style="font-weight: bold">{{ translationService.getFallbackTranslation(termsAndCondition.typology.translations).name }} : </span>
                                    </p>

                                </div>
                                <div class="col_8">

                                    <p *ngFor="let termsAndCondition of item.details.termsAndConditions">
                                    <span class="terms_and_conditions_locale_item" *ngFor="let termsAndConditionTranslation of termsAndCondition.translations" (click)="downloadTermsAndCondition(termsAndConditionTranslation)">
                                        {{ termsAndConditionTranslation.locale|uppercase }}
                                    </span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>

        </div>

        <ng-container *ngIf="!isMine && userService.hasOneOfThisRoles(currentUser, ['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">

            <br>

            <div class="row marges ligne_form infos">

                <div class="col_12">

                    <!-- Commentaire -->

                    <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment" id="comment"></textarea>
                        <label for="comment">{{ 'offerCreator.comment.value' | translate }}</label>
                    </div>
                </div>

            </div>

            <div class="row marges ligne_form">

                <div class="col_12 x_start">

                    <!-- Enregistrer -->

                    <button class="btn_cta btn_little" type="submit">
                        {{ 'form.submit.action.value' | translate }}
                    </button>

                </div>

            </div>

        </ng-container>

    </form>
</div>
