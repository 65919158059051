<form [formGroup]="form" (ngSubmit)="submit()">

    <ng-container *ngIf="redirectionToSubscriptionViewEnabled">

        <div class="ligne_form">

            <div class="row marges ligne_form">

                <div class="col x_end">

                    <!-- Retour à mon abonnement -->

                    <button class="btn_cta btn_little" type="button" (click)="redirectToList()">
                        {{ 'subscription.own.back.value' | translate }}
                    </button>
                </div>

            </div>

        </div>

    </ng-container>

    <div class="ligne_form">

        <div class="row marges ligne_form">

            <!-- Traitement en cours -->

            <ng-container *ngIf="isDisabled && hasWaitingProcess">

                <!-- Abonnement sans paiement en cours de traitement -->

                <div *ngIf="!hasWaitingPayment" class="paymentWaiting">
                    {{ 'subscription.process_waiting.value' | translate}}
                </div>

                <!-- Abonnement avec paiement en cours de traitement -->

                <div *ngIf="hasWaitingPayment" class="paymentWaiting">
                    {{ 'subscription.payment_waiting.value' | translate}}
                </div>

            </ng-container>

            <ng-container>

                <!-- Demande de downgrade en cours de traitement -->

                <div *ngIf="isDisabled && hasWaitingDowngrade" class="paymentWaiting">

                    {{'subscription.downgrade_waiting.value' | translate : {date: dateNextInvoice | date : 'dd/MM/yyyy' : null : localeId}  }}<br/><br/>

                    {{ 'subscription.downgrade_waiting.description.value' | translate}}

                    <br/>

                    <ul>
                        <ng-container *ngFor="let subscription of data">
                            <li *ngIf="subscription.paymentStatus === 2">
                                {{'form.user.subscription.fields.subscription.group.value' | translate :{'nbOffer': subscription.subscription.maxOffer} }}
                            </li>
                        </ng-container>
                    </ul>

                    <br/><br/>

                    <div [innerHTML]="'subscription.downgrade_waiting.info.value'| translate"></div>

                </div>

            </ng-container>

            <div class="col_12" *ngIf="!(isDisabled && (hasWaitingProcess))">

                <!-- Date de dernière mise à jour d'abonnement -->

                <div class="form-group" *ngIf="dateStartSubscription">
                    <p class="bold">{{ 'subscription.dateStart.value'  | translate }} :
                        <span>{{ dateStartSubscription  | date : 'dd/MM/yyyy' : null : localeId}}</span>
                    </p>
                </div>

                <!-- Périodicité -->

                <ng-container *ngIf="!isSubscriptionWithoutOffer">

                    <div class="form-group">
                        <p class="bold">{{'form.user.subscription.fields.periodicity.title.value' | translate}}</p>
                    </div>

                    <div class="row marges licenceType_choice_tiles_row">

                        <div class="col_12 col_sm_6 col_md_6 col_lg_3 licenceType_choice_tile_wrapper" (click)="handlePeriodicitySelection('M')">

                            <div class="licenceType_choice_tile" [ngClass]="getPeriodicityClasses('M')">
                                <div class="tile_top">
                                    <span class="ty-check"></span>
                                    <p>{{ 'form.user.subscription.fields.periodicity.month.value' | translate }}</p>
                                </div>
                                <div class="tile_bottom">
                                    <p>{{ 'form.user.subscription.fields.periodicity.month.description.value' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col_12 col_sm_6 col_md_6 col_lg_3 licenceType_choice_tile_wrapper" (click)="handlePeriodicitySelection('Y')">

                            <div class="licenceType_choice_tile" [ngClass]="getPeriodicityClasses('Y')">
                                <div class="tile_top">
                                    <span class="ty-check"></span>
                                    <p>{{ 'form.user.subscription.fields.periodicity.year.value' | translate }}</p>
                                </div>
                                <div class="tile_bottom">
                                    <p>{{ 'form.user.subscription.fields.periodicity.year.description.value' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <mat-error *ngIf="form.get('periodicity').errors && (form.get('periodicity').dirty || form.get('periodicity').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('periodicity').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>

                    </div>

                </ng-container>

                <ng-container *ngIf="form.get('periodicity').valid">

                    <!-- Licence -->

                    <div class="form-group">
                        <p class="bold">{{'form.user.subscription.fields.subscription.title.value' | translate}}</p>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <div class="checkbox">
                                    <input type="checkbox" id="noLicence" [(ngModel)]="isSubscriptionWithoutOffer" [ngModelOptions]="{ standalone: true }" [disabled]="isDisabled" (ngModelChange)="handleSubscriptionWithoutOfferSelection()">
                                    <label for="noLicence">{{ 'form.user.subscription.fields.subscription.noSubcription.value' | translate }}</label>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!isSubscriptionWithoutOffer">

                        <div class="row marges licenceType_choice_tiles_row">

                            <div class="col_12 col_sm_6 col_md_6 col_lg_3 licenceType_choice_tile_wrapper" *ngFor="let subscription of availableSubscriptions; trackBy: identifySubscription" (click)="handleSubscriptionSelection(subscription)">

                                <div class="licenceType_choice_tile" [ngClass]="getSubscriptionClasses(subscription)">
                                    <div class="tile_top">
                                        <ng-container *ngIf="subscription.customizable">
                                            <span class="number">{{'form.user.subscription.fields.subscription.more.value' | translate:{ count: subscription.maxOffer } }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="!subscription.customizable">
                                            <span class="number">{{ subscription.maxOffer }}</span>
                                        </ng-container>
                                        <p>{{'form.user.subscription.fields.subscription.value' | translate }}</p>
                                    </div>
                                    <div class="tile_bottom">
                                        <div class="choice_item" [ngClass]="{ customizable: subscription.customizable }">
                                            <ng-container *ngIf="subscription.customizable">
                                                <div class="action" [ngClass]="decrementCustomizableSubscriptionClasses" (click)="decrementCustomizableSubscription()">
                                                    <span>-</span>
                                                </div>
                                            </ng-container>
                                            <div class="price">
                                                <span class="ty-check"></span>
                                                <ng-container *ngIf="subscription.customizable">
                                                    <p class="customized_max_offer">
                                                        <span><span>{{ (subscription.customizable && customizedSubscription) ? selectedSubscription.maxOffer : subscription.maxOffer }}</span> {{ 'form.user.subscription.fields.subscription.value' | translate }}</span>
                                                    </p>
                                                </ng-container>
                                                <ng-container *ngIf="form.get('periodicity').value === 'M'">
                                                <span class="frequency_price">{{ 'form.user.subscription.fields.subscription.price.month.value' | translate:{
                                                    'price': parsePrice((subscription.customizable && customizedSubscription) ? selectedSubscription.monthlyPrice : subscription.monthlyPrice),
                                                    'currency': '€'
                                                } }}</span>
                                                </ng-container>
                                                <ng-container *ngIf="form.get('periodicity').value === 'Y'">
                                                <span class="frequency_price">{{ 'form.user.subscription.fields.subscription.price.year.value' | translate:{
                                                    'price': parsePrice((subscription.customizable && customizedSubscription) ? selectedSubscription.yearlyPrice : subscription.yearlyPrice),
                                                    'currency': '€'
                                                } }}</span>
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="subscription.customizable">
                                                <div class="action" [ngClass]="incrementCustomizableSubscriptionClasses" (click)="incrementCustomizableSubscription()">
                                                    <span>+</span>
                                                </div>
                                            </ng-container>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </ng-container>

                    <!-- Message d'erreur si aucune licence n'est sélectionnée -->

                    <mat-error *ngIf="(form.get('subscription').errors && (form.get('subscription').dirty || form.get('subscription').touched))" class="form-group">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('subscription').errors.required">{{ 'form.user.subscription.fields.subscription.error.value' | translate }}</div>
                        </div>
                    </mat-error>

                </ng-container>

                <!-- Tarif de l'abonnement -->

                <div class="form-group" *ngIf="!isDisabled && summarySocietySubscription">
                    <p class="total">{{ 'subscription.totalPrice.value' | translate}} :
                        <span>
                            <ng-container *ngIf="form.get('periodicity').value === 'M'">
                                {{ 'form.user.subscription.fields.subscription.totalPrice.month.value' | translate:{price: parsePrice(summarySocietySubscription.totalPrice), currency: '€'} }}
                            </ng-container>
                            <ng-container *ngIf="form.get('periodicity').value === 'Y'">
                                {{ 'form.user.subscription.fields.subscription.totalPrice.year.value' | translate:{price: parsePrice(summarySocietySubscription.totalPrice), currency: '€'} }}
                            </ng-container>
                        </span>
                    </p>
                </div>

                <!-- Promotion appliquée -->

                <div class="form-group" *ngIf="hasPromotion && isDisabled">
                    <p class="bold">
                        {{ 'payment.promo.value' | translate }} : <span>{{ formatPrice(promotion) }}</span>
                    </p>
                    <p>
                        {{ 'subscription.promotion.availableUntil' | translate }} : <span>{{ dateEndPromotion  | date : 'dd/MM/yyyy' : null : localeId}}</span>
                    </p>
                </div>

                <!-- Frais d'admission -->

                <div class="form-group" *ngIf="summarySocietySubscription && summarySocietySubscription.applyAdmission">
                    <p class="bold">{{ 'subscription.admissionPrice.value' | translate }} :
                        <span>{{ formatPrice(summarySocietySubscription.admissionPrice) }}</span>
                    </p>
                </div>

            </div>
        </div>
    </div>

    <!-- Informations relatives au downgrade d'un abonnement -->

    <div class="ligne_form row x_center">
        <div class="form-group">
            <span class="message-help background x_center" *ngIf="isDowngrade && !isSubscriptionWithoutOfferUpdate">
                <mat-icon class="tooltip_icon">info</mat-icon>{{'subscription.actions.validate.info.value' | translate : {date: birthdayDate | date : 'dd/MM/yyyy' : null : localeId} }}
            </span>
        </div>
    </div>

    <!-- Informations relatives au paiement -->

    <div class="ligne_form row x_center" *ngIf="!isDisabled && isSubmitAllowed && isUpgrade && !isSubscriptionWithoutOfferUpdate">
        <div class="form-group">
            <span class="message-help background x_center">
                <mat-icon class="tooltip_icon">info</mat-icon>{{ 'subscription.payment.warning.value' | translate }}
            </span>
        </div>
    </div>

    <div class="ligne_form row x_center" *ngIf="!isDisabled && isSubmitAllowed && isUpgrade && !isSubscriptionWithoutOfferUpdate">
        <div class="without_payment_information">
            <p [innerHTML]="'subscription.without_card_registration.value' | translate"></p>
        </div>
    </div>

    <!-- Validation du formulaire -->

    <div class="ligne_form row x_center" *ngIf="!isDisabled && isSubmitAllowed">

        <!-- Validation dans le cas d'un premier abonnement sans offre -->

        <button type="submit" *ngIf="!this.currentValidSocietySubscription && isSubscriptionWithoutOffer" class="btn_cta btn_little btn_validate_paiement">
            {{ 'subscription.actions.validate.value' | translate }}
        </button>

        <!-- Validation + Paiement dans le cas d'un premier abonnement avec offre -->

        <button type="submit" *ngIf="!this.currentValidSocietySubscription && !isSubscriptionWithoutOffer" class="btn_cta btn_little btn_validate_paiement">
            {{ 'subscription.actions.validatePayment.value' | translate }}
        </button>

        <!-- Validation dans le cas d'un abonnement sans offre, en ayant actuellement un abonnement sans offre -->

        <button type="submit" *ngIf="isSubscriptionWithoutOfferUpdate" class="btn_cta btn_little btn_validate_paiement">
            {{ 'subscription.actions.validate.value' | translate }}
        </button>

        <!-- Validation dans le cas d'un downgrade, en ayant actuellement un abonnement -->

        <button type="submit" *ngIf="currentValidSocietySubscription && isDowngrade && !isSubscriptionWithoutOfferUpdate" class="btn_cta btn_little btn_validate_paiement">
            {{ 'subscription.actions.validate.value' | translate }}
        </button>

        <!-- Validation + Paiement dans le cas d'un upgrade, en ayant actuellement un abonnement -->

        <button type="submit" *ngIf="currentValidSocietySubscription && isUpgrade && !isSubscriptionWithoutOfferUpdate" class="btn_cta btn_little btn_validate_paiement">
            {{ 'subscription.actions.validatePayment.value' | translate }}
        </button>
    </div>

</form>
