<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'offer.permanentOption.list.management.value' | translate }}</h1>
            </div>
            <div class="col_content y_items_center">
                <button (click)="addItem()" *ngIf="hasAccessCreate()" class="btn_cta btn_little btn_add">{{ 'offer.permanentOption.add.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="subscription_information">
        <p [innerHTML]="'offer.permanentOption.list.description.value' | translate"></p>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Référence -->

                <ng-container [matColumnDef]="getFilterColumnDef('reference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.reference.value' | translate" [key]="'reference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Nom -->

                <ng-container [matColumnDef]="getFilterColumnDef('translations.title')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.title.value' | translate" [key]="'translations.title'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Type d’option assurance -->

                <ng-container [matColumnDef]="getFilterColumnDef('price.priceArea')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.priceArea.value' | translate" [key]="'price.priceArea'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="priceAreas"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Type de tarification -->

                <ng-container [matColumnDef]="getFilterColumnDef('price.pricingType')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.pricingType.value' | translate" [key]="'price.pricingType'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="pricingTypes"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Type de prix -->

                <ng-container [matColumnDef]="getFilterColumnDef('price.priceType')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.priceType.value' | translate" [key]="'price.priceType'" [operator]="'eq'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="priceTypes"></app-core-select-filter>
                    </th>
                </ng-container>

                <!-- Tarif TTC public à partir de -->

                <ng-container [matColumnDef]="getFilterColumnDef('startFromPriceTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Nombre d’offres sélectionnées -->

                <ng-container [matColumnDef]="getFilterColumnDef('totalOffers')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Langues -->

                <ng-container [matColumnDef]="getFilterColumnDef('locales')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'offer.permanentOption.locales.value' | translate" [key]="'locales'" [operator]="'andlkin'" [itemValueProperty]="'id'" [itemLabelProperty]="'label'" [items]="locales"></app-core-select-array-multiple-filter>
                    </th>
                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!-- Actions -->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="editItem(element.id)" *ngIf="hasAccessEdit()">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="openDeleteItemDialog(element.id)" *ngIf="hasAccessDelete()">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Référence -->

                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.permanentOption.reference.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).reference }}</td>
                </ng-container>

                <!-- Titre -->

                <ng-container matColumnDef="translations.title">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.permanentOption.title.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{translationService.getFallbackTranslation(getModel(element).translations).title}}</td>
                </ng-container>

                <!-- Type d’option assurance -->

                <ng-container matColumnDef="price.priceArea">
                    <th mat-header-cell *matHeaderCellDef>{{ 'offer.permanentOption.priceArea.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ ('priceArea.' + (getModel(element).price.priceArea | camelize) + '.value' | translate) }}</td>
                </ng-container>

                <!-- Type de tarification -->

                <ng-container matColumnDef="price.pricingType">
                    <th mat-header-cell *matHeaderCellDef>{{ 'offer.permanentOption.pricingType.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ ('offer.permanentOption.pricingType.' + (getModel(element).price.pricingType | camelize) + '.value' | translate) }}</td>
                </ng-container>

                <!-- Type de prix -->

                <ng-container matColumnDef="price.priceType">
                    <th mat-header-cell *matHeaderCellDef>{{ 'offer.permanentOption.priceType.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ ('priceType.' + (getModel(element).price.priceType | camelize) + '.value' | translate) }}</td>
                </ng-container>

                <!-- Tarif TTC public à partir de -->

                <ng-container matColumnDef="startFromPriceTTC">
                    <th mat-header-cell *matHeaderCellDef>{{ 'price.ttc.public.from.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).parsedStartFromPriceTTC }}</td>
                </ng-container>

                <!-- Nombre d’offres sélectionnées -->

                <ng-container matColumnDef="totalOffers">
                    <th mat-header-cell *matHeaderCellDef>{{ 'offer.plural.selected.count.value' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{ getModel(element).totalOffers }}</td>
                </ng-container>

                <!-- Langues -->

                <ng-container matColumnDef="locales">
                    <th mat-header-cell *matHeaderCellDef>{{'offer.permanentOption.locales.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getParsedLocales(getModel(element))}}</td>
                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>

</div>

