import {LocaleItem} from "@core/shared/models/translation";

export const restrictLocales = (localeItems: LocaleItem[]): LocaleItem[] => {

    const allowedLocale: string[] = ['fr', 'en'];

    return localeItems.filter((localItem: LocaleItem): boolean => {

        return allowedLocale.includes(localItem.id);
    });
};
