import {OfferDuration} from "@core/shared/models/offer/offer-duration";
import {TranslationItem} from "@core/shared/models/translation";
import {OfferInterest} from "@core/shared/models/offer/offer-interest";
import {OfferCircuitType, OfferLocation} from "@core/shared/models/offer/offer-location";
import {OfferSociety} from "@core/shared/models/offer/offer-society";
import {OfferIncluded} from '@core/shared/models/offer/offer-included';
import {Exclude, Expose, Transform, TransformFnParams} from "class-transformer";
import {DATETIME_FORMAT} from "@app/data";
import {Moment} from "moment";
import * as moment from "moment";
import {OfferPresential} from '@core/shared/models/offer/offer-presential';
import {OfferAttributeOffer} from "@core/shared/models/offer-attribute-offer";
import {MediaImage} from "@lib/media/image/image";
import {OfferPrice} from "@core/shared/models/offer/offer-price";
import {OfferPriceLevel} from "@core/shared/models/offer/offer-price-level";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {Currency} from "@core/shared/models/currency";
import {OfferOption} from "@core/shared/models/offer/offer-option";
import {OfferPermanentOptionOffer} from "@core/shared/models/offer/offer-permanent-option";
import {User} from "@core/shared/models/user";
import {parseMarkup} from "@core/shared/utils/markup";
import {Society} from "@core/shared/models/society";
import {OfferPriceTypeType} from "@core/shared/models/offer/offer-price-type";
import {OfferPricePublicType} from "@core/shared/models/offer/offer-price-public";
import {OfferSeo} from "@core/shared/models/offer/offer-seo";
import {OfferProgram} from "@core/shared/models/offer/offer-program";
import {TranslationPictureType} from "@core/shared/models/translation/translation-picture";

export const OFFER_WITHOUT_NIGHT = 1;

export type OfferStockType = 'dissociated' | 'common';

@Exclude()
export class Offer {

    @Expose()
    id: number;

    @Expose()
    duration: OfferDuration;

    @Expose()
    reference: string;

    @Expose()
    offerCreator: string;

    @Expose()
    isOnAlert:boolean;

    @Expose()
    onRequest:boolean;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    createdAt: string;

    @Expose()
    @Transform(({value}) => value ? moment(value, DATETIME_FORMAT) : null, {toClassOnly: true})
    @Transform(({value}) => value ? (value as Moment).format(DATETIME_FORMAT) : null, {toPlainOnly: true})
    updatedAt: string;

    @Expose()
    locales: string[];

    @Expose()
    localesPendingTranslationRequest: string[];

    @Expose()
    timezone: string;

    @Expose()
    interests: OfferInterest[];

    @Expose()
    customerTypology: CustomerTypology;

    @Expose()
    locations: OfferLocation[];

    @Expose()
    society: OfferSociety;

    @Expose()
    giftVoucher?: OfferGiftVoucherConfiguration;

    @Expose()
    onlineSale?: OfferOnlineSaleConfiguration;

    @Expose()
    societyName:string;

    @Expose()
    societyEmail: string;

    @Expose()
    societyPhone: string;

    @Expose()
    @Transform((params: TransformFnParams): OfferIncluded[] => {

        const items: OfferIncluded[] = params.value;

        if(!items){

            return [];
        }

        return items.sort((a: OfferIncluded, b: OfferIncluded): number => {

            return a.position - b.position;
        });

    }, {toClassOnly: true})
    included: OfferIncluded[];

    @Expose()
    commentAlert: string;

    @Expose()
    presential: OfferPresential;

    @Expose()
    programs: OfferProgram[];

    @Expose()
    providerSocieties: Society[];

    @Expose()
    attributes: OfferAttributeOffer[];

    @Expose()
    prices: OfferPrice[];

    @Expose()
    priceLevels: OfferPriceLevel[];

    @Expose()
    @Transform((params: TransformFnParams): OfferTranslation[] => {

        const value = params.value;

        return (!!value && typeof value === 'string') ? parseFloat(value) : value;

    }, {toClassOnly: true})
    markup: number;

    @Expose()
    currency: Currency;

    @Expose()
    published: string;

    @Expose()
    publics: OfferPricePublicType[];

    @Expose()
    @Transform((params: TransformFnParams): OfferTranslation[] => {

        const translations: OfferTranslation[] = params.value;

        if(!translations){

            return [];
        }

        translations.forEach((translation: OfferTranslation): void => {

            if(translation.pictures){

                translation.pictures.sort((a: OfferTranslationPicture, b: OfferTranslationPicture): number => {

                    return a.position - b.position;
                });
            }
        });

        return params.value as OfferTranslation[];

    }, {toClassOnly: true})
    translations: OfferTranslation[];

    @Expose()
    startFromPriceHT: number;

    @Expose()
    startFromPriceTTC: number;

    @Expose()
    startFromPublicPriceHT: number;

    @Expose()
    startFromPublicPriceTTC: number;

    @Expose()
    percentPromotion: number;

    @Expose()
    dateEndPromotion: string;

    @Expose()
    startFromPromotionPriceHT: number;

    @Expose()
    startFromPromotionPriceTTC: number;

    @Expose()
    dateEndHighlighting: string;

    @Expose()
    options: OfferOption[];

    @Expose()
    permanentOptions: OfferPermanentOptionOffer[];

    @Expose()
    allowBookingRequest: boolean;

    @Expose()
    isAllowBookingRequest: boolean;

    @Expose()
    hasProgramsPictures: boolean;

    @Expose()
    minChildrenAge: number;

    @Expose()
    maxChildrenAge: number;

    @Expose()
    lastUpdatedUser: User;

    @Expose()
    seo: OfferSeo;

    @Expose()
    type: string;

    @Expose()
    displayPrice: OfferPriceTypeType;

    @Expose()
    hasLinkedGiftVouchers: boolean;

    @Expose()
    circuitType?: OfferCircuitType;

    @Expose()
    enableMultipleMainLocation: boolean;

    @Expose()
    vatPercent: number;

    @Expose()
    isRestricted: boolean;

    @Expose()
    restrictedSocieties: Society[];

    @Expose()
    translationPictureType: TranslationPictureType;

    @Expose()
    hasTranslationRequestToProcess: boolean;

    get isNew(): boolean {

        const now = moment();

        return now.diff(this.createdAt, 'days') <= 30;
    }

    get firstLocation(): OfferLocation {

        if(!this.locations || !this.locations.length){

            return null;
        }

        const items: OfferLocation[] = this.locations.sort((a: OfferLocation, b: OfferLocation): number => {

            return a.position - b.position;
        });

        return items[0];
    }

    get parsedMarkup(): string {

        return this.markup !== null ? (parseMarkup(this.markup * 100) + '%') : '';
    }

    get hasOfferPromotion(): boolean {

        if(this.displayPrice === 'HT'){

            return this.startFromPriceHT !== this.startFromPromotionPriceHT;
        }
        else{

            return this.startFromPriceTTC !== this.startFromPromotionPriceTTC;
        }
    }

    get isDisplayPriceHT(): boolean {

        return this.displayPrice === 'HT';
    }
}

export interface OfferTranslation extends TranslationItem {

    id: number;

    name: string;

    teaser: string;

    salesPitch: string;

    pictures: OfferTranslationPicture[];

    description: string;
}

export interface OfferTranslationPicture {

    id: number;

    position: number;

    copyright: string;

    image: MediaImage;
}

export interface OfferGiftVoucherConfiguration {

    id?: number;

    enable?: boolean;

    adultPriceTTC?: number;

    childPriceTTC?: number;

    isPhysical?: boolean;

    isDematerialized?: boolean;

    isAvailableOnlineSale?: boolean;
}

export interface OfferOnlineSaleConfiguration {

    id?: number;

    enable?: boolean;

    stopSale?: boolean;

    isAvailableOnlineSale?: boolean;

    stockManagementType?: OfferStockType;
}
