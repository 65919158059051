import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmDialogData} from "./confirm-dialog";

/**
 * Modale de confirmation
 */
@Component({
    selector: 'app-lib-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    @Output() confirm: EventEmitter<void> = new EventEmitter<void>();

    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {
    }

    ngOnInit(): void {

        const events: EventEmitter<void>[] = [
            this.confirm,
            this.cancel,

        ];

        events.forEach((event: EventEmitter<void>): void => {

            event.subscribe((): void => {

                this._dialogRef.close();
            })
        });
    }
}
