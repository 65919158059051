<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'network.offerCreator.add.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'network.offerCreator.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="submit">
                    {{ 'form.submit.action.value'|translate }}
                </button>

            </div>
        </div>
    </div>

    <mat-tab-group [@.disabled]="true">
        <mat-tab label="Données générales">

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                        <label for="name">{{'network.offerCreator.list.fields.name.value' | translate}}</label>
                        <mat-error
                            *ngIf="form.get('name').errors && (form.get('name').dirty || form.get('name').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <textarea class="form-control" formControlName="comment" id="comment" required="required"></textarea>
                        <label for="comment">{{'network.offerCreator.list.fields.comment.value' | translate}}</label>
                        <mat-error
                            *ngIf="form.get('comment').errors && (form.get('comment').dirty || form.get('comment').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('comment').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <div class="form-group add_picture big">
                        <div class="row">
                            <div class="form-group">
                                <label>{{'network.offerCreator.registrationLogo.value' | translate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col_content">
                                <form-field-file [config]="registrationLogoConfig" [form]="form" [file]="form.get('registrationLogo').value"></form-field-file>
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'network.offerCreator.registrationLogo.info.value' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col_6">
                    <div class="form-group add_picture big">
                        <div class="row">
                            <div class="form-group">
                                <label>{{'network.offerCreator.administrationLogo.value' | translate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col_content">
                                <form-field-file [config]="administrationLogoConfig" [form]="form" [file]="form.get('administrationLogo').value"></form-field-file>
                                <span class="message-help">
                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'network.offerCreator.administrationLogo.info.value' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ligne_form row marges">
                <div class="col_12">
                    <app-core-network-offer-creator-society-collection-select [parentForm]="form" [formName]="'offerCreators'"></app-core-network-offer-creator-society-collection-select>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_12">
                    <div class="form-group">
                        <textarea class="form-control" formControlName="parsedSocieties" id="societies"></textarea>
                        <span class="tooltip_info"> <mat-icon  class="tooltip_icon">info</mat-icon> {{'network.offerCreator.inscription.future.info.value' | translate}}</span>
                        <label for="societies">{{'network.offerCreator.inscription.future.value' | translate}}</label>
                        <mat-error *ngIf="form.get('parsedSocieties').errors && (form.get('parsedSocieties').dirty || form.get('parsedSocieties').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('parsedSocieties').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>
</form>
