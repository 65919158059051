import {AfterViewInit, Component, Input} from '@angular/core';
import {INavigationItem} from '@app/navigation/shared/models/navigation';
import {NavigationService} from "@navigation/shared/services/navigation.service";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: 'app-navigation-sidenav-item',
    templateUrl: './sidenav-item.component.html',
    styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements AfterViewInit {

    @Input() item: INavigationItem;

    @Input() disableImage: boolean = false;

    constructor(
        private _router: Router,
        private _navigationService: NavigationService
    ) {
    }

    ngAfterViewInit(): void {

        this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd): void => {

            this.item.opened = event.url === this.item.path;

            if(this.hasChildren && this._navigationService.hasChildOpened(this.item)) {

                this.item.opened = true;
            }
        });
    }

    toggle(path): void {

        this.item.opened = !this.item.opened;

        if(path){
            this._router.navigate([path]);
        }

    }

    get hasChildren(): boolean {

        return this.item.children.length > 0;
    }

    get parentClasses(): any {

        return {
            'open' : this.item.opened
        }
    }
}
