import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FilterBuilder, FilterComponent} from '@core/shared/models/filter';
import {LOCALE_ITEMS, LocaleItem} from '@core/shared/models/translation';
import {LOCALE_PUBLISHED_ITEMS, LocalePublishedItem, LocalePublishedItemAccess} from '@core/shared/models/published';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '@core/shared/models/role';
import {OfferSearchService} from '@core/shared/services/offer/offer-search.service';
import {TextFilterComponent} from '@core/components/filter/text-filter/text-filter.component';
import {SelectArrayMultipleFilterComponent} from '@core/components/filter/select-array-multiple-filter/select-array-multiple-filter.component';
import {SelectFilterComponent} from '@core/components/filter/select-filter/select-filter.component';
import {DateIntervalFilterComponent} from '@core/components/filter/date-interval-filter/date-interval-filter.component';
import {CheckboxBooleanFilterComponent} from '@core/components/filter/checkbox-boolean-filter/checkbox-boolean-filter.component';
import {Society} from '@core/shared/models/society';
import {ModeType} from '@core/shared/models/offer/offer-list';

@Component({
    selector: 'app-core-offer-filter',
    templateUrl: './offer-filter.component.html',
    styleUrls: ['./offer-filter.component.scss']
})
export class OfferFilterComponent implements OnInit, AfterViewInit {

    @Input() society: Society;

    @Input() roles: Role[];

    @Input() mode: ModeType;

    @Input() filterBuilder: FilterBuilder;

    @Input() prefixFilter: string;

    @Output() resetFilterRequestEvent: EventEmitter<QueryList<FilterComponent>> = new EventEmitter();

    @ViewChildren('filter') filterComponents: QueryList<FilterComponent>;

    @ViewChild('publishedFilter', { static: false }) publishedFilterComponent: SelectFilterComponent;

    @ViewChild('createdAtFilter', { static: true }) createdAtFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('promotionsFilter', { static: true }) promotionsFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('nameFilter', { static: true }) nameFilterComponent: TextFilterComponent;

    @ViewChild('referenceFilter', { static: true }) referenceFilterComponent: TextFilterComponent;

    @ViewChild('localesFilter', { static: true }) localesFilterComponent: SelectArrayMultipleFilterComponent;

    @ViewChild('onlineSaleFilter', { static: true }) onlineSaleFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('giftVoucherFilter', { static: true }) giftVoucherFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('isRestrictedFilter', { static: false }) isRestrictedFilterComponent: CheckboxBooleanFilterComponent;

    @ViewChild('permanentOptionFilter', { static: false }) permanentOptionFilterComponent: SelectFilterComponent;

    @ViewChild('permanentOptionsFilter', { static: false }) permanentOptionsFilterComponent: SelectFilterComponent;

    @ViewChild('permanentOptionsLocaleFilter', { static: false }) permanentOptionsLocaleFilterComponent: CheckboxBooleanFilterComponent;

    public locales: LocaleItem[] = [];

    public publishes: LocalePublishedItem[] = [];

    public publishesAccess: LocalePublishedItemAccess[] = [];

    constructor(
        private _translateService: TranslateService,
        private _offerSearchService: OfferSearchService
    ) {
    }

    ngOnInit(): void {

        this._initLocales();

        if ( this.mode === 'reservoir' || this.mode === 'catalog' || this.mode === 'channel' || this.mode === 'offer-permanent-option-catalog' ) {

            this._initPublishedAccess();
        }

        this._initPublished();
    }

    ngAfterViewInit(): void {

        setTimeout((): void => {

            this.initFilters();

            this._offerSearchService.updateFilter$.subscribe((data: { key: string, value: any }): void => {

                this.updateFilter(data.key, data.value);
            });
        });
    }

    initFilters(): void {

        if (!this._offerSearchService.hasSessionFilters(this.mode)) {

            return;
        }

        if (this.hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            // Publication

            this.publishedFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `published`).value;
        }

        // Nouveautés

        this.createdAtFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `createdAt`).value;

        // Vente en ligne

        this.onlineSaleFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `onlineSale.enable`).value;

        // Bon cadeau

        this.giftVoucherFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `giftVoucher.enable`).value;

        // Offre ciblée

        if(this.hasRole('ROLE_OFFER_CREATOR') && (this.mode === 'personnal-offers' || this.mode === 'catalog' || this.mode === 'channel' || this.mode === 'offer-permanent-option-catalog' || this.mode === 'offer-permanent-option-personnal-offers')) {

            this.isRestrictedFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `isRestricted`).value;
        }

        // Promotions

        this.promotionsFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `promotions`).value;

        // Nom

        this.nameFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `translations.name`).value;

        // Référence

        this.referenceFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `reference`).value;

        // Langues

        this.localesFilterComponent.values = this._offerSearchService.getSessionFilter(this.mode, `locales`).value;

        // Option assurance

        if(this.isOneOfTheseModes(['offer-permanent-option-personnal-offers', 'offer-permanent-option-catalog'])) {

            this.permanentOptionFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `permanentOption`).value;
        }

        // Options assurances

        if(this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && this.isOneOfTheseModes(['personnal-offers', 'catalog'])) {

            this.permanentOptionsFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.id`).value;
        }

        // Options assurances en fonction de la langue

        if(this.isMode('channel')) {

            this.permanentOptionsLocaleFilterComponent.value = this._offerSearchService.getSessionFilter(this.mode, `permanentOptions.offerPermanentOption.locales`).value;
        }
    }

    private _initLocales(): void {

        this.locales = LOCALE_ITEMS.map((item: LocaleItem): LocaleItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label)
            };
        });
    }

    private _initPublishedAccess(): void {

        if (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            if (!this.society.hasAccessPublishedOffer){

                this.publishesAccess.push({name: 'published'});
            }

            if (!this.society.hasAccessRestrictedOffer){

                this.publishesAccess.push({name: 'restricted'});
            }
        }
    }

    private _initPublished(): void {

        const filteredPublishes: LocalePublishedItem[] = LOCALE_PUBLISHED_ITEMS.filter((item: LocalePublishedItem): boolean => {

            return item.requiredRolesForDisplay.some((role: Role): boolean => {

                return this.hasRole(role) && !this.hasPublishesAccess(item);
            });
        });

        this.publishes = filteredPublishes.map((item: LocalePublishedItem): LocalePublishedItem => {

            return {
                id: item.id,
                label: this._translateService.instant(item.label),
                name: item.name,
                requiredRolesForDisplay: item.requiredRolesForDisplay
            };
        });
    }

    public reset(): void {

        this.resetFilterRequestEvent.emit(this.filterComponents);

        this.updateLocales([]);
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public isMode(value: ModeType): boolean {

        return this.mode === value;
    }

    public isOneOfTheseModes(items: ModeType[]): boolean {

        return items.some((item: ModeType): boolean => {

            return this.isMode(item);
        });
    }

    public updateLocales(locales: string[]): void {

        this._offerSearchService.selectedLocales.next(locales);
    }

    public updateFilter(key: string, value: any): void {

        switch (key){

            case 'locales':

                // Langues

                this.localesFilterComponent.values = (value as string[]);

                break;

            case 'giftVoucher.enable':

                // Bon cadeau

                this.giftVoucherFilterComponent.value = (value as boolean);

                this._offerSearchService.displayOnlyGiftVoucherOffer = (value as boolean);

                break;

            case 'permanentOption':

                // Option assurance

                this._offerSearchService.restrictPermanentOptionOffer = (value as boolean);

                break;

            case 'permanentOptions.offerPermanentOption.locales':

                // Options assurances en fonction de la langue

                this.permanentOptionsLocaleFilterComponent.value = (value as boolean);

                this._offerSearchService.displayOnlyOnlineSaleOffer = (value as boolean);

                break;

            case 'onlineSale.enable':

                // Vente en ligne

                this.onlineSaleFilterComponent.value = (value as boolean);

                this._offerSearchService.displayOnlyOnlineSaleOffer = (value as boolean);

                break;
        }
    }

    private hasPublishesAccess(item: LocalePublishedItem): boolean {

        return this.publishesAccess.findIndex((access) => access.name === item.name) >= 0;
    }
}
