export const OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER: string = 'offer-search';

export const OFFER_SEARCH_SESSION_STORAGE_ORIGIN = [
    'personnal-offers',
    'reservoir',
    'catalog',
    'channel'
];

export interface OfferSearchSessionFilter {

    key: string;

    value: any;

    extraData?: { [p: string]: any };
}
