import {AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FileConfig} from "@lib/form/fields/file/file.component";
import {Role} from "@core/shared/models/role";
import {LOCALE_ITEMS, LocaleItem, TranslationBuilder} from "@core/shared/models/translation";
import {BehaviorSubject, forkJoin, Observable, of} from "rxjs";
import {Country} from "@core/shared/models/country";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {SocietyService} from "@core/shared/services/society.service";
import {ApiService} from "@core/shared/services/api.service";
import {CountryService} from "@core/shared/services/country.service";
import {FormService} from "@core/shared/services/form.service";
import {REGEX_EMAIL, REGEX_IP_V4_V6, REGEX_WEBSITE} from "@core/shared/models/regex";
import {User} from "@core/shared/models/user";
import {Address, AddressType} from "@core/shared/models/address";
import {LocationField, LocationFieldType} from "@core/shared/models/location";
import {DATE_FORMAT} from "@app/data";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfirmDialogComponent} from "@lib/confirm-dialog/confirm-dialog.component";
import {AuthenticationService} from "@core/shared/services/authentication.service";
import {TermsAndCondition, TermsAndConditionTranslation} from "@core/shared/models/terms-and-condition";
import {UserService} from "@core/shared/services/user.service";
import {SocietyAdminRequest} from "@core/shared/models/society-admin-request";
import {SocietyAdminRequestService} from "@core/shared/services/society-admin-request.service";
import {Access} from "@core/shared/models/access";
import {SubscriptionDialogComponent} from "@core/components/account/subscription/subscription-dialog/subscription-dialog.component";
import {Subscription} from "@core/shared/models/subscription";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {InputDateConfig} from "@lib/form/fields/input-date/input-date.component";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {map, tap} from "rxjs/operators";
import {TranslationService} from "@core/shared/services/translation.service";
import {restrictLocales} from "@core/shared/utils/locale";
import { FormTabValidationItem,  OfferFormTabValidationService } from "@core/shared/services/form/form-tab-validation.service";
import {ChannelService} from "@core/shared/services/channel.service";
import {Channel} from "@core/shared/models/channel";
import {MarketplacePreference} from "@core/shared/models/marketplace-preference";
import {NetworkOfferCreatorService} from "@core/shared/services/network/network-offer-creator.service";
import {NetworkOfferCreator} from "@core/shared/models/network/network-offer-creator";
import {OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER, OFFER_SEARCH_SESSION_STORAGE_ORIGIN} from "@core/shared/models/offer/offer-search";
import {SocietyCommission} from "@core/shared/models/society-commission";
import {environment} from "../../../../../environments/environment";
import {SocietyIp} from "@core/shared/models/society/society-ip";
import {SocietyIpService} from "@core/shared/services/society/society-ip.service";
import {SOCIETY_TYPE_ITEMS, SocietyTypeItem} from "@core/shared/models/society-type-item";
import {CkeditorConfig} from "@lib/form/fields/ckeditor/ckeditor.component";
import * as ClassicEditor from "@lib/ckeditor";
import {ImageConfig} from "@lib/form/fields/image/image.component";
import {SocietyPicture} from "@core/shared/models/society/society-picture";
import {SocietyInformation, SocietyInformationTranslation} from "@core/shared/models/society/society-information";
import {SocietyInformationService} from "@core/shared/services/society/society-information.service";
import {Society} from "@core/shared/models/society";
import {OfferCreatorService} from "@core/shared/services/offer-creator.service";
import {CustomerTypology} from "@core/shared/models/customer-typology";
import {CustomerTypologyService} from "@core/shared/services/customer-typology.service";
import {OfferDurationType} from "@core/shared/models/offer/offer-duration";
import {OfferAttribute} from "@core/shared/models/offer-attribute";
import {ArrayFilterField} from "@core/shared/models/filter/array-filter-field";
import {OfferAttributeService} from "@core/shared/services/offer-attribute.service";
import {MatLegacyOption} from "@angular/material/legacy-core";
import {IntroductionDialogService} from "@core/shared/services/introduction-dialog.service";

@Component({
    selector: 'app-core-page-user-account',
    templateUrl: './page-user-account.component.html',
    styleUrls: ['./page-user-account.component.scss'],
    providers: [
        FormService,
        OfferFormTabValidationService
    ]
})
export class PageUserAccountComponent implements OnInit, AfterViewInit, AfterContentInit {

    @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;

    @ViewChild('tabGroupOfferCreator') tabGroupOfferCreator: MatTabGroup;

    @ViewChild('tabGroupDistributor') tabGroupDistributor: MatTabGroup;

    @ViewChild('tabGroupProvider') tabGroupProvider: MatTabGroup;

    @ViewChild('tabGroupCard') tabGroupCard: MatTabGroup;

    @ViewChild('personnalInformations', {static: true}) personnalInformationsRef: TemplateRef<any>;

    @ViewChild('societyInformations', {static: true}) societyInformationsRef: TemplateRef<any>;

    @ViewChild('formTermsAndConditions', {static: true}) formTermsAndConditionsRef: TemplateRef<any>;

    @ViewChild('legalRepresentative', {static: true}) legalRepresentativeRef: TemplateRef<any>;

    @ViewChild('accountManagement', {static: true}) accountManagementRef: TemplateRef<any>;

    @ViewChild('tywinApi', {static: true}) tywinApiRef: TemplateRef<any>;

    @ViewChild('deactivateAccount', {static: true}) deactivateAccountRef: TemplateRef<any>;

    @ViewChild('roleManagement', {static: true}) roleManagementRef: TemplateRef<any>;

    @ViewChild('card', {static: true}) cardRef: TemplateRef<any>;

    private _societyId: number;

    public user: BehaviorSubject<User> = new BehaviorSubject(null);

    public societyLogoConfig: FileConfig = {
        id: 'logo',
        gallery: {
            id: null,
            type: 'file',
            context: 'society_logo'
        },
        uploadMaxFileSize: null,
        required: true,
        uploadApiUrl: this._apiService.getApiUrl(false, true)
    };

    public roles: Role[] = [];

    public countries$: Observable<Country[]>;

    public locales$: Observable<LocaleItem[]>;

    public types$: Observable<SocietyTypeItem[]>;

    public societyAdminRequests$: Observable<SocietyAdminRequest[]>;

    public userSocietyAdminRequests$: Observable<SocietyAdminRequest[]>;

    public differentBillingEmail: boolean = false;

    public differentBillingAddress: boolean = false;

    public disableAddress: boolean = true;

    public disableAddressBilling: boolean = true;

    public notificationEnabled: boolean = false;

    public notificationCreateEnabled: boolean = false;

    public displayPreferenceNetworkOfferCreatorOffersEnabled: boolean = false;

    public subscriptionsSociety: SocietySubscription[] = [];

    public societyDelete : boolean =  false;

    public societyDeleteDate : string =  null;

    public subscriptions: Subscription[] = [];

    public requiredVatNumberCountryCodes: string[] = [];

    public tabItems: { tag: string, label: string, template: TemplateRef<any> }[] = [];

    public displayMangopayDocumentsRequirements: boolean = false;

    public marketplacePreferenceForm: UntypedFormGroup;

    public commission: SocietyCommission;

    public societyInformationTranslationBuilder: TranslationBuilder;

    public societyPresentationEditor: any = ClassicEditor;

    public societySpokenLanguages: string[] = ['fr', 'en', 'es', 'pt', 'it', 'de', 'nl'];

    public otherSpokenLanguagesIdentifier: string = '_OTHER_';

    public cardSociety$: Observable<Society>;

    public customerTypologies: CustomerTypology[] = [];

    public durationTypes: OfferDurationType[] = [];

    public regions: OfferAttribute[] = [];

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder,
        private _translateService: TranslateService,
        private _societyService: SocietyService,
        private _apiService: ApiService,
        private _offerCreatorService: OfferCreatorService,
        private _countryService: CountryService,
        private _networkOfferCreatorService: NetworkOfferCreatorService,
        private _authenticationService: AuthenticationService,
        private _userService: UserService,
        private _societyAdminRequestService: SocietyAdminRequestService,
        private _channelService: ChannelService,
        private _societyIpService: SocietyIpService,
        private _societyInformationService: SocietyInformationService,
        private _customerTypologyService: CustomerTypologyService,
        private _offerAttributeService: OfferAttributeService,
        private _introductionDialogService: IntroductionDialogService,
        public formService: FormService,
        public subscriptionService: SubscriptionService,
        public translationService: TranslationService,
        public offerFormTabValidationService: OfferFormTabValidationService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { user: User }): void => {

            this.user.next(data.user);

            // Mise à jour des rôles de l'utilisateur

            this.roles = data.user.roles;

            this._initTabItems();

            if (this.hasRole('ROLE_OFFER_CREATOR')) {

                this._initSocietyPresentationEditor();

                this._initCardSociety();

                if(this.hasAccessSubscription) {

                    this.subscriptionService.getItemSubscriptionBySocietyWithoutPagination(data.user.society.id).subscribe((subscriptionsSociety): void => {

                        this.subscriptionsSociety = subscriptionsSociety;

                        if(this.currentValidSocietySubscription && this.currentValidSocietySubscription.toDelete){

                            this.societyDelete = true;

                            this.societyDeleteDate = this.currentValidSocietySubscription.toDeleteAt;
                        }
                    });
                }
            }

            if (this.hasAccessChannel && !this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR')) {

                if(!this.user.getValue().society.isValidMangoPayAccount){

                    const params: string[] = [
                        'allowGiftVoucher[eq]=1'
                    ];

                    this._channelService.getSocietyItemsAPI(this.user.getValue().society.id, params).subscribe((items: Channel[]): void => {

                        this.displayMangopayDocumentsRequirements = items.length > 0;
                    });
                }
            }

            if (this.hasRole('ROLE_SUPER_ADMIN') || this.hasRole('ROLE_ADMIN')) {

                return;
            }

            // Mise à jour de la société

            this._societyId = data.user.society.id;

            // Initialisation des données

            this._loadCountries();

            this._loadLocales();

            this._loadTypes();

            if (this.isAccountAdmin()) {

                this._loadSocietyAdminRequests();

                if(this.user.getValue().society.hasAccessAPI){

                    this._loadSocietyIps();
                }

                if(this.hasRole('ROLE_OFFER_CREATOR')){

                    this._initCustomerTypologies();

                    this._initDurationTypes();

                    this._initRegions();
                }
            }

            if (this.isCollaborator()) {

                this._loadUserSocietyAdminRequests();
            }

            if (this.isAccountAdmin() && this.hasRole("ROLE_OFFER_DISTRIBUTOR")) {

                this._initSubscriptionList();
            }

            this._initForm();

            this._initMarketplacePreferenceForm();

            this._loadCommissionConfiguration();

            this.offerFormTabValidationService.init(this.tabGroup, this.form, this.formTabValidationItemsCallback);

            this._initFileConfigs();

            // Mise à jour des données du formulaire

            if (data.user.society.notification.updateOffer !== 'disabled') {

                this.notificationEnabled = true;
            }

            if (data.user.society.notification.createOffer !== 'disabled') {

                this.notificationCreateEnabled = true;
            }

            data.user.marketplacePreferences.forEach((item: MarketplacePreference): void => {

                switch (item.filter){

                    case 'society.networkOfferCreators.id':

                        this.displayPreferenceNetworkOfferCreatorOffersEnabled = true;

                        let ids: number[] = (item.value as string).replace(/[\[\]]/g, '').split(',').map((item: string): number => { return parseInt(item); });

                        const params: string[] = ids.map((item: number): string => {

                            return `id[in][]=${item}`;
                        });

                        this._networkOfferCreatorService.getItemsAPI(params).subscribe((items: NetworkOfferCreator[]): void => {

                            const existingNetworkOfferCreatorsIds: number[] = items.map((item: NetworkOfferCreator): number => {

                                return item.id;
                            });

                            ids = [...ids].filter((id: number): boolean => {

                                return existingNetworkOfferCreatorsIds.includes(id);
                            });

                            this.marketplacePreferenceForm.get('networkOfferCreators').patchValue(ids.map((id: number): Partial<NetworkOfferCreator> => {

                                return items.find((item: NetworkOfferCreator): boolean => {

                                    return item.id === id;
                                });
                            }));
                        });

                        break;
                }
            });

            data.user.society.notification.offerPriceVariation = (data.user.society?.notification?.offerPriceVariation * 100);

            this.form.patchValue(data.user);

            if (data.user.society.notification.updateOffer == 'disabled') {

                this.societyForm.controls['notification'].get('offerTextUpdate').patchValue(true);

                this.societyForm.controls['notification'].get('offerContentUpdate').patchValue(true);

                this.societyForm.controls['notification'].get('giftVoucherUpdate').patchValue(true);
            }

            // Mise à jour des adresses

            data.user.society.addresses.forEach((address: Address): void => {

                const addressForm: UntypedFormGroup = this._formBuilder.group({
                    id: [address.id],
                    type: [address.type, [Validators.required]],
                    address: [address.address, [Validators.required]],
                    additionalAddress: [address.additionalAddress],
                    zipcode: [address.zipcode, [Validators.required]],
                    city: [address.city, [Validators.required]],
                    region: [address.region, [Validators.required]],
                    country: [address.country, [Validators.required]]
                });

                addressForm.get('country').valueChanges.subscribe((): void => {

                    addressForm.get('region').markAsTouched();
                    addressForm.get('region').updateValueAndValidity();
                });

                this.addressesForm.push(addressForm);
            });

            // Mise à jour des documents CGV

            data.user.society.termsAndConditions.forEach((termsAndCondition: TermsAndCondition): void => {

                const control: AbstractControl = this._formBuilder.group({
                    id: termsAndCondition.id,
                    label: this.translationService.getFallbackTranslation(termsAndCondition.typology.translations).name,
                    typology: this._formBuilder.group({
                        id: [termsAndCondition.typology.id],
                    }),
                    translations: new UntypedFormArray([])
                });

                termsAndCondition.translations.forEach((translation: TermsAndConditionTranslation): void => {

                    (control.get('translations') as UntypedFormArray).push(this._formBuilder.group({
                        id: [translation.id],
                        locale: [translation.locale],
                        phone: [translation.phone],
                        email: [translation.email, Validators.pattern(REGEX_EMAIL)],
                        file: [translation.file, Validators.required]
                    }));
                });

                this.termsAndConditionsForm.push(control);
            });

            if(this.hasRole('ROLE_OFFER_CREATOR')){

                // Mise à jour des visuels

                data.user.society.pictures.forEach((picture: SocietyPicture): void => {

                    this.societyPicturesForm.push(this._formBuilder.group({
                        id: [picture.id],
                        copyright: [picture.copyright, [Validators.required]],
                        image: this._formBuilder.group({
                            image: [picture.image.image, [Validators.required]],
                            id: [picture.image.id, [Validators.required]]
                        }),
                        position:[picture.position, [Validators.required]]
                    }));
                });
            }

            this.differentBillingEmail = !!data.user.society.billingEmail && data.user.society.billingEmail !== data.user.society.email;

            this.differentBillingAddress = !this.sameAddresses(this.getAddressByType('billing'), this.getAddressByType('mailing'));

            this._handleFormState();

            this._initEvents();

            this.form.markAllAsTouched();
        });
    }

    ngAfterViewInit(): void {

        // Redirection automatique sur un onglet

        this._activatedRoute.fragment.subscribe((fragment: ('cgv'|'cardPreview')): void => {

            switch (fragment) {

                case "cgv":

                    if(this.tabGroup){

                        this.tabGroup.selectedIndex = 3;
                    }

                    if(this.tabGroupOfferCreator){

                        this.tabGroupOfferCreator.selectedIndex = 3;
                    }

                    if(this.tabGroupDistributor){

                        this.tabGroupDistributor.selectedIndex = 3;
                    }

                    if(this.tabGroupProvider) {

                        this.tabGroupProvider.selectedIndex = 3;
                    }

                    break;

                case "cardPreview":

                    this.tabGroup.selectedIndex = 4;

                    this.tabGroupCard.selectedIndex = 1;

                    break;
            }
        });
    }

    ngAfterContentInit() {

        this._changeDetectorRef.detectChanges();
    }

    private _handleFormState(): void {

        this.form.get('email').disable();
    }

    private _initEvents(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        mailingGroup.get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('vatNumber').updateValueAndValidity();

            this.societyForm.get('vatNumber').markAsTouched();

        });

        mailingGroup.valueChanges.subscribe((): void => {

            this._patchAddress();
        });

        if(this.hasRole('ROLE_OFFER_CREATOR')){

            this.societyInformationForm.get('hasRseLabel').valueChanges.subscribe((): void => {

                this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                    control.get('rseLabel').patchValue(null);

                    control.get('rseLabel').markAsTouched();
                });
            });

            this.societyInformationForm.get('spokenLanguages').valueChanges.subscribe((value: string[]): void => {

                if(value.includes(this.otherSpokenLanguagesIdentifier)){

                    this.societyInformationForm.get('hasOtherSpokenLanguages').patchValue(true);

                    this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                        control.get('spokenLanguages').markAsTouched();
                    });
                }
                else{

                    this.societyInformationForm.get('hasOtherSpokenLanguages').patchValue(false);

                    this.societyInformationTranslationBuilder.itemsControl.controls.forEach((control: FormGroup): void => {

                        control.get('spokenLanguages').patchValue(null);
                    });
                }

                this._changeDetectorRef.detectChanges();
            });
        }
    }

    public handleRegion(option: MatLegacyOption): void {

        const isAllRegionsOption: boolean = option.value.id === null;

        if(isAllRegionsOption){

            this.societyInformationForm.get('regions').patchValue(option.selected ? [...[{id: null}],...this.regions] : []);

            this.societyInformationForm.get('allRegions').patchValue(option.selected);
        }
        else{

            const currentRegions: { id: number }[] = [...(this.societyInformationForm.get('regions').value as OfferAttribute[])].filter((item: OfferAttribute): boolean => {

                return item.id !== null;
            });

            const isAllRegion: boolean = (currentRegions.length === this.regions.length);

            if(isAllRegion){

                currentRegions.push({id: null});
            }

            this.societyInformationForm.get('regions').patchValue(currentRegions);

            this.societyInformationForm.get('allRegions').patchValue(isAllRegion);
        }
    }

    private _initSocietyPresentationEditor(): void {

        this.societyPresentationEditor.defaultConfig.toolbar.items = [
            'Heading',
            '|',
            'FontColor',
            '|',
            'Bold',
            'Italic',
            'Underline',
            '|',
            'Alignment',
            'BulletedList',
            'Indent',
            'Outdent',
            '|',
            'Undo',
            'Redo'
        ];

        this.societyPresentationEditor.defaultConfig.fontColor = {
            colors : [
                {
                    "color" : "#000000",
                    "label" : "Noir"
                },
                {
                    "color" : "#1d2028",
                    "label" : "Gris foncé"
                },
                {
                    "color" : "#969696",
                    "label" : "Gris"
                },
                {
                    "color" : "#fafafa",
                    "label" : "Gris clair"
                },
                {
                    "color" : "#FFFFFF",
                    "label" : "Blanc",
                    "hasBorder" : true
                },
                {
                    "color" : "#34e0a1",
                    "label" : "Vert"
                },
                {
                    "color" : "#208d65",
                    "label" : "Vert foncé"
                },
                {
                    "color" : "#d9e2df",
                    "label" : "Vert clair"
                }
            ]
        };

        this.societyPresentationEditor.defaultConfig.heading = {
            options: [
                {
                    model: "heading3",
                    view: {
                        name : "h3",
                        classes : "titre_h3"
                    },
                    title: "ckeditor.heading.title.h3.value",
                    class: "titre_h3"
                },
                {
                    model: "paragraph",
                    title: "ckeditor.heading.title.text.value",
                    class: "txt"
                }
            ]
        };
    }

    private _initCardSociety(): void {

        this.cardSociety$ = this._offerCreatorService.getItemAPI(this.user.value.society.id);
    }

    private _initCustomerTypologies(): void {

        this._customerTypologyService.getItemsAPI().subscribe((items: CustomerTypology[]): void => {

            const societyTermsAndConditionTypologies: CustomerTypology[] = this.user.getValue().society.termsAndConditions.map((termsAndCondition: TermsAndCondition): CustomerTypology => {

                return termsAndCondition.typology;
            });

            // Filtre des types de clientèles en fonction des documents CGV définis pour la société

            this.customerTypologies = items.filter((customerTypology: CustomerTypology): boolean => {

                return societyTermsAndConditionTypologies.some((item: CustomerTypology): boolean => {

                    return customerTypology.id === item.id;
                });
            });
        });
    }

    private _initDurationTypes(): void {

        this.durationTypes = ['day', 'stay'];
    }

    private _initRegions(): void {

        const typeFilter: ArrayFilterField = new ArrayFilterField('type.tag', 'andin', 'region');

        const params: string[] = [
            `sort[label]=ASC`,
            typeFilter.serialize
        ];

        this._offerAttributeService.getItemsAPI(params).subscribe((regions: OfferAttribute[]): void => {

            this.regions = regions;

            this._loadSocietyInformation();
        });
    }

    private _initSocietyInformationTranslationForm(): void {

        this.societyInformationTranslationBuilder = new TranslationBuilder(this._formBuilder);

        this.societyInformationTranslationBuilder.form = this.societyInformationForm;

        this.societyInformationTranslationBuilder.addItemCallback = (): UntypedFormGroup => {

            return this._formBuilder.group({
                presentation: [null],
                spokenLanguages: [null, [(control: FormControl) => {

                    if(!(this.societyInformationForm.get('hasOtherSpokenLanguages').value)){

                        return null;
                    }

                    if(!!control.value && Boolean(control.value.length)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]],
                rseLabel: [null, [(control: FormControl) => {

                    if(!this.societyInformationForm.get('hasRseLabel').value){

                        return null;
                    }

                    if(!!control.value && Boolean(control.value.length)){

                        return null;
                    }

                    return {
                        'isRequired': {
                            valid: false
                        }
                    };
                }]]
            });
        };
    }

    private _initSubscriptionList(): void {

        this.subscriptionService.getItemsSubscription().subscribe((subscriptions: Subscription[]): void => {

            this.subscriptions = subscriptions;
        });
    }

    private _initTabItems(): void {

        this.tabItems = [{
            tag: 'personnalInformations',
            label: 'user.account.personnalInformations.value',
            template: this.personnalInformationsRef
        }];


        switch (true) {

            // Rôle Créateur d'offre & Distributeur d'offre

            case this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                if (this.isAccountAdmin()) {

                    this.tabItems.push({
                            tag: 'societyInformations',
                            label: 'society.informations.value',
                            template: this.societyInformationsRef
                        },
                        {
                            tag: 'legalRepresentative',
                            label: 'society.legalRepresentative.value',
                            template: this.legalRepresentativeRef

                        },
                        {
                            tag: 'formTermsAndConditions',
                            label: 'termsAndConditions.value',
                            template: this.formTermsAndConditionsRef
                        });

                    this.tabItems.push({
                        tag: 'card',
                        label: 'card.myself.value',
                        template: this.cardRef
                    });

                }

                this.tabItems.push({
                    tag: 'accountManagement',
                    label: 'user.account.settings.value',
                    template: this.accountManagementRef
                });

                if(this.isAccountAdmin() && this.user.getValue().society.hasAccessAPI){

                    this.tabItems.push({
                        tag: 'tywinApi',
                        label: 'api.tywin.value',
                        template: this.tywinApiRef
                    });
                }

                if (this.hasAccessDelete) {

                    this.tabItems.push({
                        tag: 'deactivateAccount',
                        label: 'user.account.deactivate.value',
                        template: this.deactivateAccountRef
                    });
                }

                break;

            // Rôle Créateur d'offre ou Distributeur d'offre
            case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):
            case this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'):

                if (this.isAccountAdmin()) {

                    this.tabItems.push({
                        tag: 'societyInformations',
                        label: 'society.informations.value',
                        template: this.societyInformationsRef
                    },
                    {
                        tag: 'legalRepresentative',
                        label: 'society.legalRepresentative.value',
                        template: this.legalRepresentativeRef

                    },
                    {
                        tag: 'formTermsAndConditions',
                        label: 'termsAndConditions.value',
                        template: this.formTermsAndConditionsRef
                    });

                    if(this.hasRole('ROLE_OFFER_CREATOR')) {

                        this.tabItems.push({
                            tag: 'card',
                            label: 'card.myself.value',
                            template: this.cardRef
                        });
                    }

                    this.tabItems.push(
                    {
                        tag: 'roleManagement',
                        label: 'role.management.plural.value',
                        template: this.roleManagementRef
                    });

                }

                this.tabItems.push({
                    tag: 'accountManagement',
                    label: 'user.account.settings.value',
                    template: this.accountManagementRef
                });

                if(this.isAccountAdmin() && this.user.getValue().society.hasAccessAPI){

                    this.tabItems.push({
                        tag: 'tywinApi',
                        label: 'api.tywin.value',
                        template: this.tywinApiRef
                    });
                }

                if (this.isOfferCreator && this.isAccountAdmin() && this.hasAccessDelete ||
                    !this.isOfferCreator && this.isAccountAdmin()) {

                    this.tabItems.push({
                        tag: 'deactivateAccount',
                        label: 'user.account.deactivate.value',
                        template: this.deactivateAccountRef
                    });

                }

                break;

            case this.hasRole('ROLE_PROVIDER'):
            case this.hasRole('ROLE_INSTITUTIONAL'):
            case this.hasRole('ROLE_FEDERATION'):

                if (this.isAccountAdmin()) {

                    this.tabItems.push({
                        tag: 'societyInformations',
                        label: 'society.informations.value',
                        template: this.societyInformationsRef
                    },
                    {
                        tag: 'legalRepresentative',
                        label: 'society.legalRepresentative.value',
                        template: this.legalRepresentativeRef

                    });

                }

                this.tabItems.push({
                    tag: 'accountManagement',
                    label: 'user.account.settings.value',
                    template: this.accountManagementRef
                });


                if (this.isAccountAdmin()) {

                    if(this.user.getValue().society.hasAccessAPI) {

                        this.tabItems.push({
                            tag: 'tywinApi',
                            label: 'api.tywin.value',
                            template: this.tywinApiRef
                        });
                    }

                    this.tabItems.push({
                        tag: 'deactivateAccount',
                        label: 'user.account.deactivate.value',
                        template: this.deactivateAccountRef
                    });
                }

                break;

        }

    }

    private _loadSocietyAdminRequests(): void {

        this.societyAdminRequests$ = this._societyAdminRequestService.getItemsAPI(this._societyId);
    }

    private _loadUserSocietyAdminRequests(): void {

        this.userSocietyAdminRequests$ = this._societyAdminRequestService.getUserItemsAPI(this._authenticationService.id);
    }

    private _loadCountries(): void {

        const params: string[] = [
            `paymentSupported[eq]=1`
        ];

        this.countries$ = this._countryService.getItemsAPI(params).pipe(tap((countries: Country[]): void => {

            this.requiredVatNumberCountryCodes = countries.filter((country: Country) => {

                return country.requireSocietyVatNumber;

            }).map((country: Country) => {

                return country.code;

            });

            if(this.societyForm) {

                this.societyForm.get('vatNumber').updateValueAndValidity();

                this.societyForm.get('vatNumber').markAsTouched();
            }
        }));
    }

    private _loadLocales(): void {

        this.locales$ = of(LOCALE_ITEMS).pipe(
            map((localeItems: LocaleItem[] ):LocaleItem[] => restrictLocales(localeItems) )
        );
    }

    private _loadTypes(): void {

        this.types$ = of(SOCIETY_TYPE_ITEMS);
    }

    private _initForm(): void {

        this.formService.form = this._formBuilder.group({
            society: this._formBuilder.group({
                locale: ['', [Validators.required]],
                name: ['', Validators.required],
                mainWebsite: ['', [Validators.required, Validators.pattern(REGEX_WEBSITE)]],
                identificationNumber: ['', [Validators.required]],
                registration: [''],
                corporateName: ['', Validators.required],
                type: ['', Validators.required],
                capital: [''],
                businessActivityCode: [''],
                rcsRegistration: [''],
                vatNumber: ['', [(control: AbstractControl): { [key: string]: any } => {

                    if(!(this.form) || !(this.societyForm)) {

                        return null;
                    }

                    if (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_FEDERATION', 'ROLE_INSTITUTIONAL'])) {

                        return null;
                    }

                    const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

                    if(!mailingGroup) {

                        return null;
                    }

                    const countryCode: string = mailingGroup.get('country').value;

                    if(this.requiredVatNumberCountryCodes.includes(countryCode) && (!control.value || !control.value.length)) {

                        return {
                            'isRequired': {
                                valid: false
                            }
                        };
                    }

                    return null;
                }]],
                logo: [null],
                addresses: new UntypedFormArray([]),
                email: ['', [Validators.required, Validators.pattern(REGEX_EMAIL)]],
                billingEmail: ['', [Validators.pattern(REGEX_EMAIL), (control: UntypedFormControl) => {

                    return (this.hasRole('ROLE_OFFER_CREATOR') && this.differentBillingEmail && !control.value.length) ? {isRequired: {valid: false}} : null;
                }]],
                phone: ['', [Validators.required]],
                termsAndConditions: new UntypedFormArray([], [Validators.nullValidator]),
                automaticCatalogOffersAcceptance: [false],
                notification: this._formBuilder.group({
                    id: [null],
                    createOffer: ['email'],
                    updateOffer: ['disabled'],
                    offerPriceVariation: [0],
                    offerTextUpdate: [false],
                    offerContentUpdate: [false],
                    giftVoucherUpdate: [false]
                }),
                legalRepresentative: this._formBuilder.group({
                    id: [null],
                    civility: [null, [Validators.required]],
                    firstName: ['', [Validators.required]],
                    lastName: ['', [Validators.required]],
                    birthDay: ['', [Validators.required]],
                    nationality: ['', [Validators.required]],
                    phone: [''],
                    address: ['', [Validators.required]],
                    additionalAddress: [''],
                    zipcode: ['', [Validators.required]],
                    city: ['', [Validators.required]],
                    region: ['', [Validators.required]],
                    country: ['', [Validators.required]]
                }),
                customInvoiceLegalInformation: [null]
            }),
            civility: [null, [Validators.required]],
            lastName: ['', [Validators.required]],
            firstName: ['', [Validators.required]],
            email: ['', [Validators.pattern(REGEX_EMAIL)]],
            switchboardPhone: [null],
            directPhone: [null],
            cellphone: [null],
            service: [''],
            timezone: [null, [Validators.required]],
            locale: ['', [Validators.required]],
            ips: new UntypedFormArray([], [(control: UntypedFormArray) => {

                const items: Partial<SocietyIp>[] = (control.value as Partial<SocietyIp>[]).filter((item: Partial<SocietyIp>): boolean => {

                    return !!item.address && Boolean(item.address.length);
                });

                const addresses: string[] = items.map((item: Partial<SocietyIp>): string => {

                    return item.address;
                });

                const uniqueAddresses: string[] = [...new Set(addresses)];

                if(uniqueAddresses.length === addresses.length){

                    return null;
                }

                return {
                    'invalidUnicity': true
                };
            }])
        });

        this.societyForm.get('legalRepresentative').get('country').valueChanges.subscribe((): void => {

            this.societyForm.get('legalRepresentative').get('region').markAsTouched();

            this.societyForm.get('legalRepresentative').get('region').updateValueAndValidity();
        });

        if (this.hasRole('ROLE_OFFER_CREATOR')) {

            this.societyForm.controls["automaticCatalogOffersAcceptance"].setValidators([Validators.required]);

            this.societyForm.addControl('pictures', new UntypedFormArray([]));

            this.societyForm.addControl('information', this._formBuilder.group({
                id: [null],
                hasRseLabel: [false, [Validators.required]],
                spokenLanguages: [[]],
                hasOtherSpokenLanguages: [false, [Validators.required]],
                customerTypologies: [[]],
                durationTypes: [[]],
                regions: [[]],
                allRegions: [false, [Validators.required]],
                translations: new UntypedFormArray([])
            }));

            this._initSocietyInformationTranslationForm();
        }

        this.formService.submitCallback = (configuration?: { fragment?: string }): void => {

            const observables: Observable<any>[] = [
                this._userService.updateItemAPI(this.user.value.id, {
                    civility: this.form.get('civility').value,
                    lastName: this.form.get('lastName').value,
                    firstName: this.form.get('firstName').value,
                    email: this.form.get('email').value,
                    switchboardPhone: this.form.get('switchboardPhone').value.e164Number,
                    directPhone: this.form.get('directPhone').value?.e164Number,
                    cellphone: this.form.get('cellphone').value?.e164Number,
                    service: this.form.get('service').value,
                    locale: this.form.get('locale').value,
                    timezone: this.form.get('timezone').value,
                    marketplacePreferences: this.persistedMarketplacePreferences
                })
            ];

            this._userService.currentUser.value.locale = this.form.get('locale').value;

            if (this.isAccountAdmin()) {

                this.societyForm.get('notification').get('offerPriceVariation').patchValue(this.societyForm.get('notification').get('offerPriceVariation').value / 100);

                if (this.societyForm.controls['notification'].get('updateOffer').value == 'disabled') {
                    this.societyForm.controls['notification'].get('offerTextUpdate').patchValue(false);
                    this.societyForm.controls['notification'].get('offerContentUpdate').patchValue(false);
                    this.societyForm.controls['notification'].get('giftVoucherUpdate').patchValue(false);
                    this.societyForm.controls['notification'].get('offerPriceVariation').patchValue(0);
                }

                let data = this.societyForm.value;

                data.phone = this.societyForm.get('phone').value?.e164Number;

                data.legalRepresentative.phone = this.societyLegalRepresentativeControl.get('phone').value?.e164Number;

                data.termsAndConditions.forEach((termsAndCondition: TermsAndCondition): void => {

                    termsAndCondition.translations.forEach((termsAndConditionTranslation: TermsAndConditionTranslation): void => {

                        //@ts-ignore
                        termsAndConditionTranslation.phone = termsAndConditionTranslation.phone?.e164Number;
                    });
                });

                observables.push(this._societyService.updateItemAPI(this._societyId, data));

                if(this.hasRole('ROLE_OFFER_CREATOR')){

                    observables.push(this._societyInformationService.updateItemAPI(this._societyId, Object.assign(this.societyInformationForm.value, {
                        regions: this.societyInformationForm.get('allRegions').value ? [] : (this.societyInformationForm.get('regions').value as OfferAttribute[]).filter((item: OfferAttribute): boolean => {

                            return item.id !== null;
                        }),
                        spokenLanguages: (this.societyInformationForm.get('spokenLanguages').value as string[]).filter((item: string): boolean => {

                            return ![this.otherSpokenLanguagesIdentifier].includes(item);
                        })
                    })));
                }

                if(this.user.getValue().society.hasAccessAPI){

                    observables.push(this._societyIpService.updateItemsAPI(this._societyId, this.ipsControl.getRawValue()));
                }
            }

            forkJoin(observables).subscribe((): void => {

                this._introductionDialogService.displayEnabled = false;

                // Mise à jour de l'utilisateur connecté

                this._userService.refreshUser(this._authenticationService.id);

                // Réinitialisation de la session pour la liste des offres

                OFFER_SEARCH_SESSION_STORAGE_ORIGIN.forEach((origin): void => {

                    sessionStorage.removeItem(`${origin}-${OFFER_SEARCH_SESSION_STORAGE_IDENTIFIER}`);
                });

                this._translateService.use(this.form.get('locale').value).subscribe((): void => {

                    this._snackBar.open(this._translateService.instant('user.account.update.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    const extras: NavigationExtras = {};

                    if(configuration && configuration.fragment){

                        extras.fragment = configuration.fragment;
                    }

                    this._router.navigate(['/account'], extras);
                })
            });
        };
    }

    private _initMarketplacePreferenceForm(): void {

        this.marketplacePreferenceForm = this._formBuilder.group({
            networkOfferCreators: [[]]
        });
    }

    private _loadSocietyIps(): void {

        this._societyIpService.getItemsAPI(this._societyId).subscribe((items: SocietyIp[]): void => {

            items.forEach((item: SocietyIp): void => {

                this.ipsControl.push(this._createIpGroup(item));
            });
        });
    }

    private _loadSocietyInformation(): void {

        this._societyInformationService.getItemAPI(this._societyId).subscribe((item: SocietyInformation): void => {

            this.societyInformationForm.patchValue(Object.assign(item, {
                regions: item.allRegions ? [...[{id: null}],...this.regions] : item.regions,
                spokenLanguages: item.hasOtherSpokenLanguages ? item.spokenLanguages.concat(this.otherSpokenLanguagesIdentifier) : item.spokenLanguages
            } as Partial<SocietyInformation>));

            item.translations.forEach((translation: SocietyInformationTranslation): void => {

                const group: UntypedFormGroup = this.societyInformationTranslationBuilder.addItemControl(this.societyInformationTranslationBuilder.getLocaleItem(translation.locale), translation);

                group.addControl('id', this._formBuilder.control(translation.id));
            });
        });
    }

    private _loadCommissionConfiguration(): void {

        if(!this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])) {

            return;
        }

        const commission: SocietyCommission = this.user.getValue().society.commission;

        if(commission.type !== 'custom') {

            return;
        }

        this.commission = commission;
    }

    private _initFileConfigs(): void {

        this._translateService.get('file.extension.list.allowed.value', {list: '.png, .jpeg, .svg'}).subscribe((help: string): void => {

            this.societyLogoConfig = {
                id: 'logo',
                gallery: {
                    id: null,
                    type: 'file',
                    context: 'society_logo'
                },
                uploadMaxFileSize: 100000,
                required: true,
                uploadApiUrl: this._apiService.getApiUrl(false, true),
                help: help,
            };
        });

    }

    private  _patchAddress(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        if(!this.differentBillingAddress){

            billingGroup.patchValue({
                address: mailingGroup.value.address,
                additionalAddress: mailingGroup.value.additionalAddress,
                zipcode: mailingGroup.value.zipcode,
                city: mailingGroup.value.city,
                region: mailingGroup.value.region,
                country: mailingGroup.value.country
            });
        }
    }

    public selectAddress(index: number): void {

        if (this.getAddressTypeLabel(this.getAddressControl(index).get('type').value) === 'Adresse') {

            this.disableAddress = false;

        } else {

            this.disableAddressBilling = false;
        }
    }

    public resetAddresses(): void {

        const mailingGroup: AbstractControl = this.getAddressControlByType('mailing');

        const billingGroup: AbstractControl = this.getAddressControlByType('billing');

        const childControlsToUpdate: AbstractControl[] = [
            billingGroup.get('address'),
            billingGroup.get('additionalAddress'),
            billingGroup.get('zipcode'),
            billingGroup.get('city'),
            billingGroup.get('region'),
            billingGroup.get('country')
        ];

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.clearValidators();
        });

        if (this.differentBillingAddress) {

            billingGroup.get('address').setValidators([Validators.required]);
            billingGroup.get('zipcode').setValidators([Validators.required]);
            billingGroup.get('city').setValidators([Validators.required]);
            billingGroup.get('region').setValidators([Validators.required]);
            billingGroup.get('country').setValidators([Validators.required]);
            billingGroup.patchValue({
                address: '',
                additionalAddress: '',
                zipcode: '',
                city: '',
                region: '',
                country: ''
            });
        } else {

            billingGroup.patchValue({
                address: mailingGroup.value.address,
                additionalAddress: mailingGroup.value.additionalAddress,
                zipcode: mailingGroup.value.zipcode,
                city: mailingGroup.value.city,
                region: mailingGroup.value.region,
                country: mailingGroup.value.country
            });
        }

        childControlsToUpdate.forEach((control: AbstractControl): void => {

            control.updateValueAndValidity();

        });
    }

    public resetBillingAddress(): void {

        this.societyForm.get('billingEmail').patchValue(this.differentBillingEmail ? '' : this.societyForm.get('email').value);
    }

    public isAddressToDisplay(type: AddressType): boolean {

        switch (type) {

            case "mailing":

                return true;

            case "billing":

                return this.differentBillingAddress;

            default:

                return false;
        }
    }

    public getAddressByType(type: AddressType): Address {

        const addresses: Address[] = this.addressesForm.value;

        return addresses.find((address: Address): boolean => {

            return address.type === type;
        });
    }

    public getAddressControlByType(type: AddressType): AbstractControl {

        return this.addressesForm.controls.find((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public getAddressControlIndexByType(type: AddressType): number {

        return this.addressesForm.controls.findIndex((control: AbstractControl): boolean => {

            return (control.value as Address).type === type;
        });
    }

    public indexAsString(index: number): string {

        return index.toString();
    }

    public getAddressTypeLabel(type: AddressType): string {

        return this._translateService.instant(`form.user.fields.address.${type}.value`);
    }

    public getAddressControl(index: number): AbstractControl {

        return (this.addressesForm.controls.length > 0) ? this.addressesForm.controls[index] : null;
    }

    public sameAddresses(addressA: Address, addressB: Address): boolean {

        const addressAValues: object = {
            address: addressA.address,
            additionalAddress: addressA.additionalAddress,
            zipcode: addressA.zipcode,
            city: addressA.city,
            region: addressA.region,
            country: addressA.country
        };

        const addressBValues: object = {
            address: addressB.address,
            additionalAddress: addressB.additionalAddress,
            zipcode: addressB.zipcode,
            city: addressB.city,
            region: addressB.region,
            country: addressB.country
        };

        return JSON.stringify(addressAValues) === JSON.stringify(addressBValues);
    }

    public getAddressLocationFields(form: AbstractControl): LocationField[] {

        return [
            {
                type: LocationFieldType.Street,
                reference: form.get('address')
            },
            {
                type: LocationFieldType.Postcode,
                reference: form.get('zipcode')
            },
            {
                type: LocationFieldType.City,
                reference: form.get('city')
            },
            {
                type: LocationFieldType.Region,
                reference: form.get('region')
            },
            {
                type: LocationFieldType.CountryISO,
                reference: form.get('country')
            }
        ]
    }

    public addRoleDialog(role: Role): void {

        const title: string = this._translateService.instant('role.add.value');

        let content: string = '';

        switch (role) {

            case 'ROLE_OFFER_DISTRIBUTOR':

                content = this._translateService.instant('role.add.distributor.confirmation.value');

                break;
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            const data: object = {
                roles: [...this.roles, role]
            };

            this._userService.updateItemAPI(this._authenticationService.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('role.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._authenticationService.logout();
            });
        });
    }

    public adminAccountRequestDialog(): void {

        const title: string = this._translateService.instant('user.account.admin.request.value');

        let content: string = this._translateService.instant('user.account.admin.request.confirmation.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyAdminRequestService.createItemAPI(this._authenticationService.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.admin.request.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadUserSocietyAdminRequests();
            });
        });
    }

    public acceptAdminAccountRequestDialog(societyAdminRequest: SocietyAdminRequest): void {

        const title: string = this._translateService.instant('user.account.admin.request.value');

        let content: string = this._translateService.instant('user.account.admin.request.accept.confirmation.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            const data: object = {
                isValid: true
            };

            this._societyAdminRequestService.updateItemAPI(societyAdminRequest.id, data).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.admin.request.accept.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });


                this._authenticationService.logout();
            });
        });
    }

    public cancelAdminAccountRequestDialog(societyAdminRequest: SocietyAdminRequest): void {

        const title: string = this._translateService.instant('user.account.admin.request.cancel.value');

        let content: string = this._translateService.instant('user.account.admin.request.cancel.confirmation.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyAdminRequestService.deleteItemAPI(societyAdminRequest.id).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.admin.request.cancel.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._loadSocietyAdminRequests();
            });
        });
    }

    public subscriptionDialog(): void {

        const title: string = this._translateService.instant('user.account.management.beCreator.dialog.title.value');

        this._dialog.open(SubscriptionDialogComponent, {
            width: '1000px',
            data: {
                title: title,
                subscriptions: this.subscriptions
            }
        });
    }

    public desactivateAccountDialog(): void {

        const title: string = this._translateService.instant('user.account.deactivate.value');

        let content: string = '';

        switch (true) {

            // Rôle Créateur d'offre & Distributeur d'offre

            case this.hasRole('ROLE_OFFER_CREATOR') && this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.offerCreatorDistributor.self.confirmation.value`);

                break;

            // Rôle Créateur d'offre

            case this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.offerCreator.self.confirmation.value`);

                break;

            // Rôle Distributeur d'offre

            case this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.offerDistributor.self.confirmation.value`);

                break;

            // Rôle Prestataire

            case this.hasRole('ROLE_PROVIDER'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.provider.self.confirmation.value`);

                break;

            // Rôle Institutionel

            case this.hasRole('ROLE_INSTITUTIONAL'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.provider.self.confirmation.value`);

                break;


            // Rôle Fédération

            case this.hasRole('ROLE_FEDERATION'):

                content = this._translateService.instant(`user.account.deactivate.warn.role.provider.self.confirmation.value`);

                break;
        }

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.deleteItemAPI(this._societyId).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.deactivate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this._authenticationService.logout();
            });
        });
    }

    public desactivativationAccountDialog(): void {

        const title: string = this._translateService.instant('user.account.deactivate.value');

        let content: string = this._translateService.instant(`user.account.deactivate.warn.role.offerCreatorDistributor.self.confirmation.value`);


        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title: title,
                content: content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.deleteItemAPI(this._societyId).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('user.account.program.deactivate.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.societyDelete = true;

                this.subscriptionService.getItemSubscriptionBySocietyWithoutPagination(this._societyId).subscribe((items: SocietySubscription[]): void => {

                    this.subscriptionsSociety = items;

                    this.societyDeleteDate = this.currentValidSocietySubscription.toDeleteAt;
                });
            });
        });
    }

    public isAccountAdmin(): boolean {

        return !!this.user.value?.societyAdmin;
    }

    public isCollaborator(): boolean {

        return !!!this.user.value?.societyAdmin;
    }

    public hasRole(role: Role): boolean {

        return this.roles.indexOf(role) >= 0;
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public cancelDeactivationDialog(): void {

        const title: string = this._translateService.instant('subscription.deleteAccount.cancel.value');

        const content: string = this._translateService.instant(`subscription.deleteAccount.cancel.confirm.value`);

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._societyService.cancelDeleteItemAPI(this._societyId).subscribe((): void => {

                this._snackBar.open(this._translateService.instant('subscription.deleteAccount.cancel.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });

                this.societyDelete = false;

                this.societyDeleteDate = null;
            });
        });
    }

    public goToMangoPayDocuments(): void{

        this._router.navigate(['account/service/list'], { queryParams : { targetTab: 'document' }});
    }

    public notificationEnabledUpdate(formName: string, event): void {

        if (event.checked === false) {

            this.societyForm.controls['notification'].get(formName).patchValue('disabled');

        } else {

            this.societyForm.controls['notification'].get(formName).patchValue('email');
        }
    }

    public getMarketplacePreference(filter: string): MarketplacePreference {

        return this.user.getValue().marketplacePreferences.find((item: MarketplacePreference): boolean => {

            return item.filter === filter;
        });
    }

    public formatCommission(value: number): string {

        return (value * 100).toFixed(2);
    }

    private _createIpGroup(data: Partial<SocietyIp>): UntypedFormGroup {

        const group: UntypedFormGroup = this._formBuilder.group({
            address: new UntypedFormControl({ value: data.address, disabled: ('id' in data) }, [Validators.required, (control: UntypedFormControl) => {

                if(!control.value || !control.value.length){

                    return null;
                }

                if(REGEX_IP_V4_V6.test(control.value)){

                    return null;
                }

                return {
                    'isInvalid': {
                        valid: false
                    }
                };
            }]),
            isDeletable: new UntypedFormControl(data.isDeletable, [Validators.required])
        });

        if('id' in data){

            group.addControl('id', new UntypedFormControl(data.id, [Validators.required]));
        }

        return group;
    }

    public addIpControl(): void {

        const group: UntypedFormGroup = this._createIpGroup({
            address: null,
            isDeletable: true
        })

        this.ipsControl.push(group);
    }

    public removeIpControl(index: number): void {

        this.ipsControl.removeAt(index);

        this.ipsControl.updateValueAndValidity();
    }

    public increasePicturePosition(index: number): void {

        const groupToIncrease: UntypedFormGroup = this.societyPicturesForm.at(index) as UntypedFormGroup;

        const currentPosition: number = groupToIncrease.value.position;

        const groupToDecrease: AbstractControl = this.societyPicturesForm.controls.find((group: UntypedFormGroup): boolean => {

            return group.value.position === currentPosition + 1;
        });

        groupToIncrease.patchValue({
            position: currentPosition + 1
        });

        groupToDecrease.patchValue({
            position: groupToDecrease.value.position - 1
        });
    }

    public decreasePicturePosition(index: number): void {

        const groupToDecrease: UntypedFormGroup = this.societyPicturesForm.at(index) as UntypedFormGroup;

        const currentPosition: number = groupToDecrease.value.position;

        const groupToIncrease: AbstractControl = this.societyPicturesForm.controls.find((group: UntypedFormGroup): boolean => {

            return group.value.position === currentPosition - 1;
        });

        groupToDecrease.patchValue({
            position: currentPosition - 1
        });

        groupToIncrease.patchValue({
            position: groupToIncrease.value.position + 1
        });
    }

    public isIncreasePicturePositionAllowed(index: number): boolean {

        const group: UntypedFormGroup = this.societyPicturesForm.controls[index] as UntypedFormGroup;

        const position: number = group.value.position;

        return position !== this.societyPicturesForm.controls.length;
    }

    public isDecreasePicturePositionAllowed(index: number): boolean {

        const group: UntypedFormGroup = this.societyPicturesForm.controls[index] as UntypedFormGroup;

        const position: number = group.value.position;

        return position !== 1;
    }

    public addPicture(): void {

        const position: number = (this.societyPicturesForm.value as []).length + 1;

        this.societyPicturesForm.push(
            this._formBuilder.group({
                copyright: ['', [Validators.required]],
                image: this._formBuilder.group({
                    image: [null, [Validators.required]]
                }),
                position: [position, [Validators.required]]
            })
        );
    }

    public removePicture(index: number): void {

        this.societyPicturesForm.removeAt(index);

        const groups: UntypedFormGroup[] = (this.societyPicturesForm.controls as UntypedFormGroup[]).filter((group: UntypedFormGroup): boolean => {

            const item: SocietyPicture = group.value;

            return item.position > (index + 1);
        });

        groups.forEach((group: UntypedFormGroup): void => {

            group.patchValue({
                position: group.value.position - 1
            });
        });
    }

    public compareById(a: { id: number }, b: { id: number }): boolean {

        return a.id === b.id;
    }

    public submit(configuration?: { fragment?: string }): void {

        this.formService.submit(configuration);
    }

    get sortedPictureControls(): AbstractControl[] {

        return this.societyPicturesForm.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup): number => {

            return a.value.position - b.value.position;
        });
    }

    get isOfferCreator(): boolean {

        return this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR');
    }

    get form(): UntypedFormGroup {

        return this.formService.form;
    }

    get societyForm(): UntypedFormGroup {

        return this.form.get('society') as UntypedFormGroup;
    }

    get societyInformationForm(): UntypedFormGroup {

        return this.societyForm.get('information') as UntypedFormGroup;
    }

    get societyPicturesForm(): UntypedFormArray {

        return this.societyForm.get('pictures') as UntypedFormArray;
    }

    get termsAndConditionsForm(): UntypedFormArray {

        return this.societyForm.get('termsAndConditions') as UntypedFormArray;
    }

    get addressesForm(): UntypedFormArray {

        return this.societyForm.get('addresses') as UntypedFormArray;
    }

    get societyLegalRepresentativeControl(): UntypedFormGroup {

        return this.societyForm.get('legalRepresentative') as UntypedFormGroup;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get hasAccessDelete(): boolean {

        return !!this.user.value.accesses.find((access: Access): boolean => {

            return access.tag === 'USER_DELETE_IS_MINE';
        });
    }

    get hasAccessSubscription(): boolean {

        return !!this.user.value.accesses.find((access: Access): boolean => {

            return (access.tag === 'SOCIETY_SUBSCRIPTION_LIST') || (access.tag === 'SOCIETY_SUBSCRIPTION_CREATE_IS_MINE');
        });
    }

    get hasAccessGiftVoucher(): boolean {

        return !!this.user.value.accesses.find((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    get hasAccessChannel(): boolean {

        return this.user.value.accesses.some((access: Access): boolean => {

            return access.tag === 'CHANNEL_LIST_IS_MINE';
        });
    }

    get birthDayConfig(): InputDateConfig {

        return {
            id: 'birthDay',
            enableReset: false,
            attrs: {
                required: true,
                placeholder: this._translateService.instant('form.user.fields.birthDay.value'),
                disabled: false
            },
            format: DATE_FORMAT
        };
    }

    get currentValidSocietySubscription(): SocietySubscription {

        return this.subscriptionsSociety.find((item: SocietySubscription): boolean => {

            return item.isValid && (item.paymentStatus === 1);
        });
    }

    get formTabValidationItemsCallback(): () => FormTabValidationItem[] {

        return (): FormTabValidationItem[] => {

            const items = [];
            let index = 1;

            items.push({
                tag: 'personnalInformations',
                position: index++,
                controls: [
                    this.form.get('civility'),
                    this.form.get('lastName'),
                    this.form.get('firstName'),
                    this.form.get('switchboardPhone'),
                    this.form.get('directPhone'),
                    this.form.get('cellphone'),
                    this.form.get('timezone'),
                    this.form.get('service'),
                    this.form.get('locale')
                ]
            });

            if (this.isAccountAdmin()) {

                const societyInformationsControls: AbstractControl[]  = [
                    this.societyForm.get('name'),
                    this.societyForm.get('mainWebsite'),
                    this.societyForm.get('locale'),
                    this.societyForm.get('registration'),
                    this.societyForm.get('corporateName'),
                    this.societyForm.get('type'),
                    this.societyForm.get('capital'),
                    this.societyForm.get('businessActivityCode'),
                    this.societyForm.get('rcsRegistration'),
                    this.societyForm.get('identificationNumber'),
                    this.societyForm.get('vatNumber'),
                    this.societyForm.get('logo'),
                    this.societyForm.get('email'),
                    this.societyForm.get('phone'),
                    this.societyForm.get('addresses'),
                    this.societyForm.get('billingEmail')
                ];

                items.push({
                    tag: 'societyInformations',
                    position: index++,
                    controls: societyInformationsControls
                });

                items.push({
                    tag: 'legalRepresentative',
                    position: index++,
                    controls: [
                        this.societyLegalRepresentativeControl.get('civility'),
                        this.societyLegalRepresentativeControl.get('lastName'),
                        this.societyLegalRepresentativeControl.get('firstName'),
                        this.societyLegalRepresentativeControl.get('phone'),
                        this.societyLegalRepresentativeControl.get('nationality'),
                        this.societyLegalRepresentativeControl.get('address'),
                        this.societyLegalRepresentativeControl.get('additionalAddress'),
                        this.societyLegalRepresentativeControl.get('zipcode'),
                        this.societyLegalRepresentativeControl.get('city'),
                        this.societyLegalRepresentativeControl.get('region'),
                        this.societyLegalRepresentativeControl.get('country'),
                    ]
                });

                // Créateur/Distributeur d'offre - Créateur d'offre ou Distributeur d'offre

                if (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])) {

                    items.push({
                        tag: 'formTermsAndConditions',
                        position: index++,
                        controls: [
                            ...this.termsAndConditionsForm.controls
                        ]
                    });

                }

            }

            // Créateur OU Distributeur d'offres

            if ((this.hasRole('ROLE_OFFER_CREATOR') && !this.hasRole('ROLE_OFFER_DISTRIBUTOR')) ||
                (this.hasRole('ROLE_OFFER_DISTRIBUTOR') && !this.hasRole('ROLE_OFFER_CREATOR'))) {

                if (this.isAccountAdmin()) {

                    items.push({
                        tag: 'roleManagement',
                        position: index++,
                        controls: []
                    });
                }
            }

            items.push({
                tag: 'accountManagement',
                position: index++,
                controls: [
                    this.societyForm.get('automaticCatalogOffersAcceptance'),
                    this.societyForm.get('notification').get('offerPriceVariation'),
                    this.societyForm.get('notification').get('offerTextUpdate'),
                    this.societyForm.get('notification').get('offerContentUpdate'),
                    this.societyForm.get('notification').get('giftVoucherUpdate')
                ]
            });

            if(this.hasRole('ROLE_OFFER_CREATOR')){

                items.push({
                    tag: 'card',
                    position: index++,
                    controls: [
                        this.societyInformationForm
                    ]
                });
            }

            if (this.isAccountAdmin()) {

                if(this.user.getValue().society.hasAccessAPI){

                    items.push({
                        tag: 'tywinApi',
                        position: index++,
                        controls: [this.ipsControl]
                    });
                }

                items.push({
                    tag: 'deactivateAccount',
                    position: index++,
                    controls: []
                });

            }

            return items;
        };
    }

    get persistedMarketplacePreferences(): Partial<MarketplacePreference>[] {

        const marketplacePreferences: Partial<MarketplacePreference>[] = [];

        const networkOfferCreators: Partial<NetworkOfferCreator>[] = this.marketplacePreferenceForm.get('networkOfferCreators').value;

        if(this.displayPreferenceNetworkOfferCreatorOffersEnabled && networkOfferCreators.length){

            marketplacePreferences.push({
                filter : 'society.networkOfferCreators.id',
                operator: 'in',
                value : `[${networkOfferCreators.map((item: Partial<NetworkOfferCreator>): number => { return item.id }).join()}]`
            });
        }

        marketplacePreferences.forEach((item: Partial<MarketplacePreference>): void => {

            const match: MarketplacePreference = this.getMarketplacePreference(item.filter);

            if(!match){

                return;
            }

            Object.assign(item, {
                id: match.id
            } as MarketplacePreference);
        });

        return marketplacePreferences;
    }

    get networkOfferCreatorNames(): string[] {

        return this.user.getValue().society.networkOfferCreators.map((item: NetworkOfferCreator): string => {

            return item.name;

        });
    }

    get ipsControl(): UntypedFormArray {

        return this.form.get('ips') as UntypedFormArray;
    }

    get ipControls(): UntypedFormGroup[] {

        return this.ipsControl.controls as UntypedFormGroup[];
    }

    get tywinApiVersion(): string {

        return environment.tywinApiVersion;
    }

    get tywinApiToken(): string {

        const user: User = this.user.getValue();

        return user ? user.society.tokenApi : null;
    }

    get tywinApiDocumentationLink(): string {

        return environment.tywinApiDocumentationLink;
    }

    get tywinApiProductionBetaEnvironment(): string {

        return environment.tywinApiProductionBetaEnvironment;
    }

    get presentationEditorConfig(): CkeditorConfig {

        return {
            id: 'presentation',
            editor: this.societyPresentationEditor,
            attrs: {
                required: false,
                maxLength: 1200
            }
        };
    }

    get picturesConfig(): ImageConfig {

        return {
            id: 'image',
            gallery_context: 'offer_picture',
            required: false,
            uploadApiUrl: this._apiService.getApiUrl(false, true),
            options: {
                enableTitle: false,
                enableSubtitle: false,
                enableAlt: false,
                enableLink: false,
                enableTargetBlank: false,
                label: this._translateService.instant('offer.form.fields.picture.value')
            },
        }
    }

    get hasOfferSubscription(): boolean {

        if(!this.subscriptionsSociety.length){

            return false;
        }

        const item: SocietySubscription = this.subscriptionsSociety.find((item: SocietySubscription): boolean => {

            return item.subscription.maxOffer > 0;
        });

        return !!item;
    }
}
