import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {User} from "@core/shared/models/user";
import {Society} from "@core/shared/models/society";
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {Role} from "@core/shared/models/role";
import {SocietyService} from "@core/shared/services/society.service";
import {OfferCatalogService} from "@core/shared/services/offer/offer-catalog.service";
import {OfferCatalog} from "@core/shared/models/offer/offer-catalog";
import {Offer} from "@core/shared/models/offer";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {OfferAccessRequestMultipleCreateDialogComponent} from "@core/components/offer/offer-access-request/offer-access-request-multiple/offer-access-request-multiple-create/offer-access-request-multiple-create-dialog/offer-access-request-multiple-create-dialog.component";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "@core/shared/services/user.service";
import {Access} from "@core/shared/models/access";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {SubscriptionService} from "@core/shared/services/subscription.service";
import {SocietySubscription} from "@core/shared/models/society-subscription";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-page-offer-list',
    templateUrl: './page-offer-list.component.html',
    styleUrls: ['./page-offer-list.component.scss'],
    providers: [
        OfferSearchService
    ]
})
export class PageOfferListComponent implements OnInit{

    public user: User;

    public society: Society;

    public societySubscriptions: SocietySubscription[] = [];

    public displaySubscriptionInformation$: Observable<boolean>;

    public totalItems: number;

    public items: Offer[] = [];

    public origin: { key: string, backLabel: string } = null;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _offerCatalogService: OfferCatalogService,
        private _societyService: SocietyService,
        private _subscriptionService: SubscriptionService,
        public offerSearchService: OfferSearchService,
        public _userService: UserService
    ) {
    }

    ngOnInit(): void {

        this._activatedRoute.data.subscribe((data: { society: Society }): void => {

            this.user = this._userService.currentUser.value;

            this._societyService.currentUserSociety.next(data.society);

            this.society = data.society;

            this._initOrigin();

            this._configureSearchService();

            if((this.hasRole('ROLE_OFFER_CREATOR') || this.hasRole('ROLE_OFFER_DISTRIBUTOR') || this.hasRole('ROLE_PROVIDER') || this.hasRole('ROLE_INSTITUTIONAL') || this.hasRole('ROLE_FEDERATION')) && this.hasAccessCatalogList()){

                this._loadSelfOfferCatalogs();
            }

            this._initDisplaySubscriptionInformation();

            this._initDisplayOffersInformation();

        });

    }

    private _initOrigin(): void {

        this.origin = this._activatedRoute.snapshot.queryParams['origin'] ? {
            key: this._activatedRoute.snapshot.queryParams['origin'],
            backLabel: `offer.list.origin.${ this._activatedRoute.snapshot.queryParams['origin'] }.value`
        } : null;
    }


    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    private _configureSearchService(): void {

        this.offerSearchService.accessRequestOfferAllowed = true;

        this.offerSearchService.addOfferToCatalogAllowed = true;

        this.offerSearchService.deleteOfferFromCatalogAllowed = true;

        this.offerSearchService.reportOfferAllowed = true;

        this.offerSearchService.updateOfferAllowed = true;

        this.offerSearchService.viewOfferAllowed = true;
    }

    private _initDisplaySubscriptionInformation(): void {

        const hasAccess: boolean = this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'SOCIETY_SUBSCRIPTION_LIST_IS_MINE';
        });

        if (!hasAccess) {

            this.displaySubscriptionInformation$ = of(false);

            return;
        }

        this.displaySubscriptionInformation$ = this._subscriptionService.getItemSubscriptionBySocietyWithoutPagination(this.society.id).pipe(
            map((subscriptions: SocietySubscription[]): boolean => {

                return subscriptions.every((item: SocietySubscription) => {

                    return item.subscription.admissionPrice === 0;
                });

            })
        );
    }

    public addItem(): void {

        this._router.navigate(['account/offer/create']);
    }

    public hasRole(role: Role): boolean {

        return this.user.roles.includes(role);
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasAccessCreate(): boolean{

        const accesses: Access[] = this.user.accesses.filter((access : Access) => {

            return access.tag == 'OFFER_CREATE_IS_MINE';
        });

        return (accesses.length > 0)
    }

    public hasAccessCatalogList(): boolean{

        const accesses: Access[] = this.user.accesses.filter((access : Access) => {

            return access.tag == 'OFFER_CATALOG_LIST';
        });

        return (accesses.length > 0)
    }

    public hasAccessSubscription(): boolean {

        return  this.user.accesses.some((access: Access) => {

           return access.tag === 'SOCIETY_SUBSCRIPTION_LIST_IS_MINE';

        });
    }

    public activateMultipleAccessRequestSelection(): void {

        this.offerSearchService.selectedAccessRequestOffers.next([]);

        this.offerSearchService.multipleAccessRequestSelectionActivated.next(true);
    }

    public cancelMultipleAccessRequestSelection(): void {

        this.offerSearchService.selectedAccessRequestOffers.next([]);

        this.offerSearchService.multipleAccessRequestSelectionActivated.next(false);
    }

    public openConfirmMultipleAccessRequestSelectionDialog(offers: Offer[]): void {

        const dialogRef: MatDialogRef<OfferAccessRequestMultipleCreateDialogComponent> = this._dialog.open(OfferAccessRequestMultipleCreateDialogComponent, {
            width: '500px',
            data: {
                offers: offers
            }
        });

        dialogRef.componentInstance.create.subscribe((): void => {

            this.offerSearchService.selectedAccessRequestOffers.next([]);

            this.offerSearchService.multipleAccessRequestSelectionActivated.next(false);

            this._snackBar.open(this._translateService.instant('offer.accessRequest.multiple.confirm.success.value'), this._translateService.instant('notification.close.action.value'), {
                duration: 5000
            });
        });

    }

    public redirectToOrigin(): void {

        switch (this.origin.key){

            case 'offerCreatorRead':

                this._router.navigate(['account/offer-creator/read', this._activatedRoute.snapshot.queryParams['societyId']]);

                break;
        }
    }

    get mode(): ModeType {

        return this._activatedRoute.snapshot.params['mode'];
    }

    private _initDisplayOffersInformation(): void {

        if (!this.hasAccessSubscription()){

            return;
        }

        if (this.mode === 'personnal-offers'){

            this._subscriptionService.getItemSubscriptionBySocietyWithoutPagination(this.society.id).subscribe((societySubscriptions: SocietySubscription[]) => {

                this.societySubscriptions = societySubscriptions;
            });
        }
    }

    get currentValidSocietySubscription(): SocietySubscription {

        return this.societySubscriptions.find((item: SocietySubscription): boolean => {

            return item.isValid && (item.paymentStatus === 1);
        });
    }

    get offerFilterType(): string {

        const hasNight: boolean = this.offerSearchService.getSessionFilter(this.mode, `duration.value`) ? (this.offerSearchService.getSessionFilter(this.mode, `duration.value`).extraData.operator === 'gt') : true;

        return hasNight ? 'stay' : 'day';
    }
}
