import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {Image, ImageFormat} from "./image";
import {ApiService} from "@core/shared/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    /**
     * API URL
     */
    private API_URL: string;

    private readonly MEDIA_URL: string = '/media';

    private readonly IMAGE_URL: string = '/images';

    private readonly CROP_URL: string = '/crop';

    private readonly FORMAT_URL: string = '/format';

    private _iterator: number;

    public constructor(private _http: HttpClient, private _apiService: ApiService) {

        this._iterator = 1;
    }




    /**
     * @param apiUrl
     */
    public setApiUrl(apiUrl: string): void {

        this.API_URL = apiUrl;
    }

    /**
     * @return string
     */
    public getApiUrl(): string {

        return this.API_URL;
    }

    /**
     * @return string
     */
    public getImageUrl(): string {

        return this.IMAGE_URL;
    }

    /**
     * @return string
     */
    public getMediaUrl(): string {

        return this.MEDIA_URL;
    }

    /**
     * @param image
     * @param format
     * @param width
     * @param quality
     */
    public getPath(image: Image, format: ImageFormat, width: number, quality: number): string {

        return `${this._apiService.getApiUrl(false, false)}/public${this.MEDIA_URL}${this.IMAGE_URL}/${image.id}/filter/${format.name}/${width}/${quality}`;
    }

    public crop(image: Image): Observable<object> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post(`${this._apiService.getApiUrl(false, true)}${this.IMAGE_URL}/${image.id}${this.CROP_URL}`, {
            formats: image.formats
        }, httpOptions);
    }

    public incrementIterator(): void {

        this._iterator++;
    }

    /**
     * @return number
     */
    public getIterator(): number {

        return this._iterator;
    }

    public setFormatImage(image: Image): Observable<object> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        const httpOptions: object = {
            headers: headers
        };

        return this._http.post(`${this.API_URL}${this.IMAGE_URL}/${image.id}${this.FORMAT_URL}`, {
            image: image
        }, httpOptions);
    }

    public getCloneImage(image: Image): Observable<Image>{

        return this._http.get<Image>(`${this._apiService.getApiUrl(false, true)}${this.IMAGE_URL}/${ image.id }/clone`);
    }
}
