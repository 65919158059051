<form [formGroup]="form" (ngSubmit)="formService.submit()" (keydown)="submitEnter($event)">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'channel.read.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">
                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'channel.list.back.value' | translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <mat-tab [label]="'offer.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <mat-tab [label]="'offer.list.selection.value' | translate">
            <ng-container *ngTemplateOutlet="offersSelection"></ng-container>
        </mat-tab>
        <mat-tab [label]="'channel.integration.js.value' | translate" *ngIf="channel.enableJS">
            <ng-container *ngTemplateOutlet="JSIntegration"></ng-container>
        </mat-tab>
        <mat-tab [label]="'channel.integration.subDomain.value' | translate" *ngIf="channel.enableSubDomain">
            <ng-container *ngTemplateOutlet="SubdomainIntegration"></ng-container>
        </mat-tab>
        <mat-tab [label]="'channel.integration.api.value' | translate" *ngIf="channel.enableApi">
            <ng-container *ngTemplateOutlet="APIIntegration"></ng-container>
        </mat-tab>
        <ng-container *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR') && form.get('isDistributor').value">
            <mat-tab [label]="'offer.permanentOptions.value' | translate">
                <ng-container *ngTemplateOutlet="permanentOptions"></ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>
    <div class="ligne_form row marges" *ngIf="channel.individualQuota || channel.groupQuota">
        <div class="col_12"  style="background-color:#eaeaea;">
            <div style="padding:10px">
            Nombre max d'offre individuel : {{ channel.individualQuota }}
            <br/>
            Nombre max d'offre groupe : {{ channel.groupQuota }}
        </div>
        </div>
    </div>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">

            <div class="col_12">

                <div class="mat-tab-translations">

                    <ng-container formArrayName="translations">

                        <mat-tab-group [@.disabled]="true">

                            <div *ngFor="let _ of translationBuilder.itemsControl.controls; let it = index">
                                <mat-tab label="{{translationBuilder.getLocaleLabel(translationBuilder.getItemControl(it).value.locale) | translate }}">
                                    <ng-container [formGroupName]="translationBuilder.indexAsString(it)">
                                        <div class="row marges ligne_form">

                                            <div class="col_6">

                                                <!-- Nom -->

                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                                                    <label for="name">{{ 'channel.name.value' | translate }}</label>
                                                </div>
                                            </div>

                                            <div class="col_6">

                                                <!-- Titre -->
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="title" required="required">
                                                    <label for="name">{{ 'channel.title.value' | translate }}</label>
                                                    <div class="form-group col_content y_items_center">
                                                    <span class="message-help">
                                                        <mat-icon class="tooltip_icon">info</mat-icon>
                                                        {{ 'channel.title.info.value' | translate }}
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row marges ligne_form">
                                            <div class="col_12">

                                                <!-- Description -->
                                                <div class="form-group">
                                                    <input type="text" value="" placeholder=" " class="form-control" formControlName="description" id="description">
                                                    <label for="description">{{'description.value' | translate}}</label>
                                                    <div class="form-group col_content y_items_center">
                                                        <span class="message-help">
                                                            <mat-icon class="tooltip_icon">info</mat-icon>
                                                            {{ 'description.info.value' | translate }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>
                                </mat-tab>
                            </div>

                        </mat-tab-group>

                    </ng-container>

                </div>

            </div>

        </div>

        <ng-container *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR') || hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">

            <div class="row marges ligne_form">
                <div class="col_12">

                    <!-- Distributeur du canal -->

                    <div class="form-group">
                        <label>{{'channel.distributor.question.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="isDistributor" class="form-control" id="isDistributorYes">
                            <label for="isDistributorYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="isDistributor" class="form-control" id="isDistributorNo">
                            <label for="isDistributorNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ ('channel.distributor.' + (form.get('isDistributor').value ? 'checked' : 'unchecked') + '.description.value') | translate }}
                        </span>
                    </div>
                </div>
            </div>

        </ng-container>

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Activation du canal -->

                <div class="form-group">
                    <label>{{'channel.enable.question.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="true" formControlName="enable" class="form-control" id="isEnableYes">
                        <label for="isEnableYes">{{'form.user.fields.yes.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="false" formControlName="enable" class="form-control" id="isEnableNo">
                        <label for="isEnableNo">{{'form.user.fields.no.value' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR')">

            <div class="row marges ligne_form">
                <div class="col_12">

                    <!-- Référencer les offres du canal -->

                    <div class="form-group">
                        <label>{{'channel.referenced.question.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="referenced" class="form-control"
                                   id="referencedYes">
                            <label for="referencedYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="referenced" class="form-control" id="referencedNo">
                            <label for="referencedNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ ('channel.referenced.description.' + (form.get('referenced').value ? 'checked' : 'unchecked') + '.value') | translate }}
                        </span>
                    </div>
                </div>
            </div>

        </ng-container>

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Mode d'intégration du canal  -->

                <div class="form-group">
                    <label>{{'channel.integrationMode.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="'subDomain'" formControlName="integrationMode" class="form-control" id="integrationModeDomain">
                        <label for="integrationModeDomain">{{'channel.integrationMode.domain.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="'JS'" formControlName="integrationMode" class="form-control" id="integrationModeJs">
                        <label for="integrationModeJs">{{'channel.integrationMode.js.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="'API'" formControlName="integrationMode" class="form-control" id="integrationModeApi">
                        <label for="integrationModeApi">{{'channel.integrationMode.api.value' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="channel.enableJS">

            <div class="row marges ligne_form">
                <div class="col_8">

                    <!-- Url canonical -->

                    <div class="form-group">
                        <div class="row">
                            <div class="col_content addon_input y_items_center">
                                <span>{{marketplace}}</span>
                            </div>
                            <div class="col content_input">
                                <input type="text" value="/" placeholder=" " class="form-control" formControlName="canonicalUrl" id="canonicalUrl">
                                <label for="canonicalUrl">{{'channel.canonicalUrl.value' | translate}}</label>
                            </div>
                        </div>
                        <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                            {{ 'channel.canonicalUrl.info.value' | translate }}
                        </span>
                    </div>

                </div>
            </div>
        </ng-container>

        <div class="row marges ligne_form">
            <div class="col_12">
                <!-- Commentaire -->
                <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment"
                                  id="comment"></textarea>
                    <label for="comment">{{'comment.value' | translate}}</label>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Nom du proprietaire -->

                <div class="form-group">
                    <select class="form-control" id="ownerName" formControlName="ownerName" required="required">
                        <option value=""></option>
                        <option *ngFor="let society of owners | async" [value]="society.name">{{ society.name }}</option>
                    </select>
                    <label class="required" for="ownerName">{{ 'channel.owner.value' | translate }}</label>
                </div>

            </div>

        </div>

        <div class="row marges ligne_form">

            <div class="col_6">

                <!-- Marketplace -->

                <div class="form-group">
                    <select class="form-control" id="marketplace" formControlName="marketplace" required="required" [compareWith]="compareSocietyWebsite">
                        <option *ngFor="let societyWebsite of societyWebsites$ | async" [ngValue]="societyWebsite">
                            {{ societyWebsite.website }}
                        </option>
                    </select>
                    <label for="marketplace">{{'channel.restricted.list.fields.marketplace.value' | translate}}</label>
                </div>
            </div>
        </div>

        <div class="row marges ligne_form">

            <div class="col_6">

                <!-- Langue -->

                <div class="ligne_form row marges">
                    <div class="col_12">
                        <div class="form-group">
                            <select class="form-control" id="locale" formControlName="locale" required="required" (change)="changeLocale()">
                                <option value=""></option>
                                <option *ngFor="let locale of locales$ | async" [value]="locale.id">{{ locale.label | translate }}</option>
                            </select>
                            <label class="required" for="locale">{{ 'locale.value' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col_6">

                <!-- Sélection de la devise -->

                <div class="form-group">
                    <select class="form-control" id="currency" formControlName="currency" required="required">
                        <option value=""></option>
                        <option *ngFor="let currency of currencies$ | async" [value]="currency.id">
                            {{ translationService.getFallbackTranslation(currency.translations).label }}
                        </option>
                    </select>
                    <label class="required" for="currency">{{ 'currency.value' | translate }}</label>
                </div>

            </div>

        </div>

        <div class="row marges ligne_form">
            <div class="col_12">

                <!-- Affichage du moteur de recherche -->
                <div class="form-group">
                    <label>{{'channel.searchEngine.display.value' | translate}}</label>
                    <div class="radio">
                        <input type="radio" [value]="true" formControlName="searchEngine" class="form-control" id="searchEngineYes">
                        <label for="searchEngineYes">{{'form.user.fields.yes.value' | translate}}</label>
                    </div>
                    <div class="radio">
                        <input type="radio" [value]="false" formControlName="searchEngine" class="form-control" id="searchEngineNo">
                        <label for="searchEngineNo">{{'form.user.fields.no.value' | translate}}</label>
                    </div>

                </div>
            </div>
        </div>

        <ng-container formArrayName="style">
            <div class="row marges ligne_form">
                <div class="col_6">
                    <app-select-search [config]="fieldCollection.getFieldConfig('font')" [value]="form.controls['style'].get('font').value" [form]="form" (valueChange)="changeFont($event)" [disabled]="true"></app-select-search>
                </div>

                <div class="col_6">
                    <!-- colorHighlighting -->
                    <div class="form-group color">
                        <input type="text" autocomplete="off" [value]="colorHighlighting" (colorPickerChange)="updateColorField($event,'colorHighlighting')"  [(colorPicker)]="colorHighlighting" placeholder=" " class="form-control" formControlName="colorHighlighting" id="colorHighlighting">
                        <label for="colorHighlighting">{{'channel.fields.colorHighlighting.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorHighlighting"></span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <!-- colorTitle -->
                    <div class="form-group color">
                        <input type="text" (colorPickerChange)="updateColorField($event,'colorTitle')" autocomplete="off" [value]="colorTitle" [(colorPicker)]="colorTitle" placeholder=" " class="form-control" formControlName="colorTitle" id="colorTitle">
                        <label for="colorTitle">{{'channel.fields.colorTitle.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorTitle"></span>
                    </div>
                </div>
                <div class="col_6">
                    <!-- colorTextButton -->
                    <div class="form-group color">
                        <input type="text" autocomplete="off" [value]="colorTextButton" (colorPickerChange)="updateColorField($event,'colorTextButton')"  [(colorPicker)]="colorTextButton" placeholder=" " class="form-control" formControlName="colorTextButton" id="colorTextButton">
                        <label for="colorTextButton">{{'channel.fields.colorTextButton.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorTextButton"></span>
                    </div>
                </div>
            </div>

            <div class="row marges ligne_form">
                <div class="col_6">
                    <!-- colorSubTitle -->
                    <div class="form-group color">
                        <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorSubTitle')" [value]="colorSubTitle" [(colorPicker)]="colorSubTitle" placeholder=" " class="form-control" formControlName="colorSubTitle" id="colorSubTitle">
                        <label for="colorSubTitle">{{'channel.fields.colorSubTitle.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorSubTitle"></span>
                    </div>
                </div>
                <div class="col_6">
                    <!-- colorBackgroundButton -->
                    <div class="form-group color">
                        <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorBackgroundButton')" [value]="colorBackgroundButton" [(colorPicker)]="colorBackgroundButton" placeholder=" " class="form-control" formControlName="colorBackgroundButton" id="colorBackgroundButton">
                        <label for="colorBackgroundButton">{{'channel.fields.colorBackgroundButton.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorBackgroundButton"></span>
                    </div>
                </div>
            </div>
            <div class="row marges ligne_form">
                <div class="col_6">
                    <!-- colorDescription -->
                    <div class="form-group color">
                        <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorDescription')" [value]="colorDescription" [(colorPicker)]="colorDescription" placeholder=" " class="form-control" formControlName="colorDescription" id="colorDescription">
                        <label for="colorDescription">{{'channel.fields.colorDescription.value' | translate}}</label>
                        <span class="show_color" [style.background]="colorDescription"></span>
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>
</ng-template>

<!-- Sélection des offres -->

<ng-template #offersSelection>

    <div [formGroup]="form" class="tab_content_overflow">
        <div class="row marges">
            <div class="col y_items_center">
                <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">
                    <span>{{ 'offer.list.selected.value' | translate }} :</span>
                    <ul>
                        <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #JSIntegration>

    <div class="ck-content">

        <h2 style="font-weight: bold">{{ 'channel.help.jsIntegration.title.value' | translate }}</h2>

        <p>- {{ 'channel.help.jsIntegration.description.start.value' | translate }} <span class="modify" [innerText]="'<body>'"></span>{{ 'channel.help.jsIntegration.description.end.value' | translate }}</p>

        <div class="copy-content" (click)="copyJSIntegrationWCContent()" title="{{ 'channel.help.jsIntegration.copy.value' | translate }}">

            <pre><<span>tywin-channel</span><span class="no_modify">&nbsp;token="{{ channel.tokenJs }}</span>" <span class="modify">base-url="{{ canonicalUrl }}</span>"><<span>/tywin-channel</span>></pre>

        </div>

        <div class="form-group">

            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>
                <div [innerHTML]="('channel.help.jsIntegration.help.token.value' | translate)"></div>
            </span>

            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>
                <div [innerHTML]="(('channel.help.jsIntegration.help.baseUrl.value' | translate: {marketplace : marketplace}) | safeHtml)"></div>
            </span>

        </div>

        <p>- {{ 'channel.help.jsIntegration.script.value' | translate }} <span class="modify" [innerText]="'<body>'"></span> :</p>

        <div class="copy-content" (click)="copyJSIntegrationSourceContent()" title="{{'channel.help.jsIntegration.copy.value' | translate }}">

            <pre><span class="modify" [innerText]="JSIntegrationSourceTag"></span></pre>

        </div>

        <ng-container *ngIf="channel.referenced">

            <br>

            <h2 style="font-weight: bold">{{ 'channel.help.jsIntegration.referenced.title.value' | translate }}</h2>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.baseUrl.value' | translate: {channelId : channel.id})"></p>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.wildcard.value' | translate)"></p>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.warning.value' | translate)"></p>

            <p [innerHTML]="(('channel.help.jsIntegration.referenced.description.warning.example.value' | translate: {marketplace: marketplace}) | safeHtml)"></p>

            <p [innerHTML]="(('channel.help.jsIntegration.referenced.description.sitemap.value' | translate: {sitemap: sitemapUrl}) | safeHtml)"></p>

        </ng-container>
    </div>

</ng-template>

<ng-template #SubdomainIntegration>

    <span style="padding-top: 10px;">{{ 'channel.help.subDomain.description.value' | translate }} <a href="{{ subDomainIntegrationLink }}" target="_blank"><span style="padding-top: 10px; color: orange; font-weight: bold" [innerText]="subDomainIntegrationLink"></span></a></span>

</ng-template>

<ng-template #APIIntegration>

    <div class="ck-content">

        <h3 style="font-weight: bold">{{ 'channel.help.apiIntegration.title.value' | translate }}</h3>

        <p>- {{ 'channel.id.value' | translate }} : <span class="modify">{{ channel.id }}</span></p>

        <div class="row">
            <div class="col_content">
                <p>- {{ 'ipAddress.plural.selected.value' | translate }} :&nbsp;</p>
            </div>
            <div class="col">
                <p *ngFor="let allowedIp of allowedIps" style="margin-bottom: 4px;">
                    <span class="modify">{{ allowedIp }}</span>
                </p>
            </div>
        </div>
    </div>

</ng-template>

<ng-template #permanentOptions>

    <ng-container [formGroup]="form">

        <div class="ligne_form row marges">

            <div class="col_12 y_items_center">

                <div class="form-group">
                    <mat-select formControlName="permanentOptions" multiple>
                        <mat-option *ngFor="let permanentOption of permanentOptions$ | async;" [value]="permanentOption.id">
                            {{ translationService.getFallbackTranslation(permanentOption.translations).name }}
                        </mat-option>
                    </mat-select>
                </div>

            </div>

        </div>

    </ng-container>

</ng-template>
