import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import {Offer, OfferTranslation} from '@core/shared/models/offer';
import {featureGroup, LatLngBounds, Layer, Map as LeafletMap, MapOptions, tileLayer} from 'leaflet';
import {ApiService} from '@core/shared/services/api.service';
import {TranslateService} from '@ngx-translate/core';
import {OfferCatalogService} from '@core/shared/services/offer/offer-catalog.service';
import {Router} from '@angular/router';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OfferDurationTranslation} from '@core/shared/models/offer/offer-duration-translation';
import {OfferInterest} from '@core/shared/models/offer/offer-interest';
import {ConfirmDialogComponent} from '@lib/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import {Role} from '@core/shared/models/role';
import {OfferCatalog} from '@core/shared/models/offer/offer-catalog';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SocietyService} from '@core/shared/services/society.service';
import {OfferService} from '@core/shared/services/offer.service';
import {OfferSearchService} from "@core/shared/services/offer/offer-search.service";
import {OfferAccessRequestCreateDialogComponent} from "@core/components/offer/offer-access-request/offer-access-request-create/offer-access-request-create-dialog/offer-access-request-create-dialog.component";
import {CustomerTypologyTranslation} from "@core/shared/models/customer-typology";
import {DatePipe} from "@angular/common";
import {Access} from "@core/shared/models/access";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {classToPlain} from "class-transformer";
import {OfferPriceLevel} from "@core/shared/models/offer/offer-price-level";
import {OfferIncluded} from "@core/shared/models/offer/offer-included";
import {OfferProgram} from "@core/shared/models/offer/offer-program";
import {OfferLocation} from "@core/shared/models/offer/offer-location";
import {map, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {ModeType} from "@core/shared/models/offer/offer-list";
import {DEFAULT_MARKUP} from "@core/shared/models/data";
import {TranslationService} from "@core/shared/services/translation.service";
import {parseMarkup} from "@core/shared/utils/markup";
import {OfferUpdateConfirmationConfiguration, OfferUpdateConfirmationConfigurationType, OfferUpdateConfirmationData} from "@core/shared/models/offer/offer-update/offer-update-confirmation";
import {OfferUpdateConfirmationDialogComponent} from "@core/components/offer/offer-update/offer-update-confirmation-dialog/offer-update-confirmation-dialog.component";
import {GiftVoucherService} from "@core/shared/services/gift-voucher.service";
import {OfferLocationService} from "@core/shared/services/offer/offer-location/offer-location-service";
import {LeafletDirective} from "@asymmetrik/ngx-leaflet";
import {OfferPermanentOption, OfferPermanentOptionOffer, OfferPermanentOptionOfferCatalog} from "@core/shared/models/offer/offer-permanent-option";

type ViewType = 'map' | 'visual';

type DisplayOnlineSaleType = 'available' | 'warning' | 'disable';

type DisplayGiftVoucherType = 'available' | 'disable';

@Component({
    selector: 'app-core-offer-card',
    templateUrl: './offer-card.component.html',
    styleUrls: ['./offer-card.component.scss'],
    providers: [DatePipe]
})
export class OfferCardComponent implements OnInit, OnChanges {

    @Input() item: Offer;

    @Input() roles: Role[];

    @Input() layoutView: 'list' | 'map';

    @Input() identificationNumber: string;

    @Input() mode: ModeType;

    @Input() publicPrice: number;

    @Input() displayLocation: OfferLocation = null;

    @Input() showAddress: boolean = false;

    @Output() closeItem: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(LeafletDirective, { static: false }) directive: LeafletDirective;

    public offerTranslation: OfferTranslation;

    public markers: Layer[] = [];

    public newOffer = false;

    public cardClosed = true;

    public view: ViewType = 'visual';

    public mapOptions: MapOptions;

    public user: User;

    public displayedPermanentOptions: OfferPermanentOption[] = [];

    constructor(
        private _apiService: ApiService,
        private _translateService: TranslateService,
        private _offerCatalogService: OfferCatalogService,
        private _societyService: SocietyService,
        private _offerService: OfferService,
        private _router: Router,
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _giftVoucherService: GiftVoucherService,
        private datePipe: DatePipe,
        public translationService: TranslationService,
        public _userService: UserService,
        public offerSearchService: OfferSearchService,
        public offerLocationService: OfferLocationService
    ) {
    }

    ngOnInit(): void {

        this.user = this._userService.currentUser.value;

        this._init();

        if(this.hasOneOfThisMode(['catalog', 'channel']) && this.isLayoutView('list')){

            this._offerCatalogService.selfItems.subscribe((): void => {

                this._initDisplayedPermanentOptions();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if ('item' in changes) {

            this._loadTranslation();

            this._handleDefaultView();

            this._initDisplayedPermanentOptions();
        }
    }

    private _init(): void {

        this.newOffer = this.isNewOffer();

        this.mapOptions = {
            layers: [
                tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 18
                })
            ],
            zoom: 5,
            center: {
                lat: 46.85,
                lng: 2.3518
            }
        };
    }

    private _handleDefaultView(): void {

        switch (this.layoutView){

            case 'list':

                const params: string[] = [
                    'isMain[eq]=1'
                ];

                this.offerLocationService.getOfferItemsAPI(this.item.id, params).subscribe((locations: OfferLocation[]): void => {

                    this.setView(this.offerTranslation.pictures.length ? 'visual' : (locations.length ? 'map' : 'visual'));

                    this.loadMarkers(locations);
                });

                break;

            case 'map':

                this.setView('visual');

                break;
        }
    }

    private _loadSelfOfferCatalogs(): void {

        this._offerCatalogService.getItemsAPI().subscribe((items: OfferCatalog[]): void => {

            this._offerCatalogService.selfItems.next(items);
        });
    }

    private _loadTranslation(): void {

        this.offerTranslation = this.translationService.getFallbackTranslation(this.item.translations);
    }

    private _initDisplayedPermanentOptions(): void {

        if(this.hasOneOfThisMode(['personnal-offers', 'offer-permanent-option-personnal-offers'])){

            this.displayedPermanentOptions = this.item.permanentOptions.map((item: OfferPermanentOptionOffer): OfferPermanentOption => {

                return item.offerPermanentOption;
            });
        }

        if(this.hasOneOfThisMode(['catalog', 'channel', 'offer-permanent-option-catalog'])){

            const extrasData = this.offerSearchService.extrasData$.getValue();

            const isChannel: boolean = ('isDistributor' in extrasData);

            const isDistributor: boolean = isChannel ? extrasData['isDistributor'] as boolean : true;

            const hasRoleDistributor: boolean = this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR']);

            const offerItems: OfferPermanentOption[] = (!hasRoleDistributor || !isDistributor || this.item.society.id === this.user.society?.id) ? this.item.permanentOptions.map((item: OfferPermanentOptionOffer): OfferPermanentOption => {

                return item.offerPermanentOption;

            }) : [];

            if(this.isLayoutView('list')){

                const offerCatalogItems: OfferPermanentOption[] = isDistributor && this._offerCatalogService.getSelfItemByOffer(this.item) ? this._offerCatalogService.getSelfItemByOffer(this.item).permanentOptions.map((item: OfferPermanentOptionOfferCatalog): OfferPermanentOption => {

                    return item.offerPermanentOption;

                }) : [];

                const items: OfferPermanentOption[] = [...offerItems, ...offerCatalogItems];

                this.displayedPermanentOptions = [...new Map(items.map((item: OfferPermanentOption) => [item.id, item])).values()];
            }

            if(this.isLayoutView('map')){

                const params: string[] = [
                    `offer.id[eq]=${this.item.id}`
                ]

                this._offerCatalogService.getItemsAPI(params)
                    .pipe(
                        map((items: OfferCatalog[]): OfferCatalog => { return items.find((): boolean => true); })
                    )
                    .subscribe((offerCatalog: OfferCatalog): void => {

                        const offerCatalogItems: OfferPermanentOption[] = isDistributor ? offerCatalog.permanentOptions.map((item: OfferPermanentOptionOfferCatalog): OfferPermanentOption => {

                            return item.offerPermanentOption;
                        }) : [];

                        const items: OfferPermanentOption[] = [...offerItems, ...offerCatalogItems];

                        this.displayedPermanentOptions = [...new Map(items.map((item: OfferPermanentOption) => [item.id, item])).values()];
                    })
                ;
            }
        }
    }

    public loadMarkers(offerLocations: OfferLocation[]): void {

        this.markers = [];

        offerLocations.forEach((offerLocation: OfferLocation): void => {

            const marker = offerLocation.marker;

            marker.on('click', (): void => {

                this.map.panTo(offerLocation.latLng);
            });

            this.markers.push(marker);
        });
    }

    public handleMapInitialization(): void {

        setTimeout((): void => {

            this.map.fitBounds(this.fitBounds);

        });
    }

    public getDurationTranslation(): OfferDurationTranslation {

        return this.translationService.getFallbackTranslation( this.item.duration.translations);
    }

    public getCustomerTypologyTranslation(): CustomerTypologyTranslation {

        return this.translationService.getFallbackTranslation(this.item.customerTypology.translations);
    }

    public getVisuelOffer(idOffer: number, idImage: number): string {

        return !!idImage ? `${this._apiService.getApiUrl(false, false)}/public/offers/${idOffer}/pictures/${idImage}/filter/rectangle/1500` : null;
    }

    public isNewOffer(): boolean {
        const now = moment();
        return now.diff(this.item.createdAt, 'days') <= 30;
    }

    public isOnAlert(): boolean {

        return this.item.isOnAlert && this.item.society.id === this.user.society?.id;
    }

    public updateItem(id: number): void {

        if(this.hasTranslationOnProcess){

            return;
        }

        const types: OfferUpdateConfirmationConfigurationType[] = [];

        if(this.item.hasLinkedGiftVouchers){

            types.push('gift-voucher');
        }

        if(types.length){

            // Certains éléments doivent être confirmés avant de procéder à la redirection

            const confirmationDialogRef: MatDialogRef<OfferUpdateConfirmationDialogComponent> = this._dialog.open(OfferUpdateConfirmationDialogComponent, {
                width: '500px',
                data: {
                    types: types
                } as OfferUpdateConfirmationConfiguration
            });

            confirmationDialogRef.componentInstance.confirm.subscribe((): void => {

                const data: OfferUpdateConfirmationData = confirmationDialogRef.componentInstance.form.value;

                switch (true){

                    case (types.includes('gift-voucher') && (data.giftVoucherManagement === 'dissociate')):

                        this._giftVoucherService.detachOfferItemsAPI(this.item.id).subscribe((): void => {

                            this._router.navigate(['account/offer/update/' + id]);
                        });

                        break;

                    default:

                        this._router.navigate(['account/offer/update/' + id]);
                }
            });
        }
        else{

            // Aucun élément n'est à confirmer avant de procéder à la redirection

            this._router.navigate(['account/offer/update/' + id]);
        }
    }

    public openAddCatalogueDialog(): void {

        window.dispatchEvent(new Event('resize')); // Fix bug affichage

        const data: object = {
            offer: {
                id: this.item.id
            },
            markup: this.hasOneOfThisRoles(['ROLE_PROVIDER']) ? null : (this.item.markup === undefined ? DEFAULT_MARKUP : this.item.markup)
        };

        const title: string = this._translateService.instant('offer.list.item.action.catalog.add.title.value');

        const content: string = this._translateService.instant('offer.list.item.action.catalog.add.content.value');

        // Si createur d'offre ou prestataire sans immatriculation

        if (this.hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_PROVIDER', 'ROLE_OFFER_DISTRIBUTOR'])){

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title,
                    content
                }
            });

            dialogRef.componentInstance.cancel.subscribe((): void => {

                window.dispatchEvent(new Event('resize')); // Fix bug affichage
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._offerCatalogService.createItemAPI(data).subscribe((): void => {

                    this._loadSelfOfferCatalogs();

                    this._snackBar.open(this._translateService.instant('offer.catalog.actions.add.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    window.dispatchEvent(new Event('resize')); // Fix bug affichage
                });
            });
        }
    }

    public openDeleteCatalogueDialog(): void {

        const title: string = this._translateService.instant('offer.list.item.action.catalog.delete.title.value');

        const content: string = this._translateService.instant('offer.list.item.action.catalog.delete.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._offerCatalogService.deleteItemAPI(this.offerCatalog.id).subscribe((): void => {

                this._loadSelfOfferCatalogs();

                this._snackBar.open(this._translateService.instant('offer.catalog.actions.delete.success.value'), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            });
        });
    }

    public openOfferAccessDialog(item: Offer): void {

        if (this.hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR'])){

            const dialogRef: MatDialogRef<OfferAccessRequestCreateDialogComponent> = this._dialog.open(OfferAccessRequestCreateDialogComponent, {
                width: '500px',
                data: {
                    offer: item,
                    society: this._societyService.currentUserSociety.value
                }
            });

            dialogRef.componentInstance.create.subscribe((data: {item: OfferCatalog}): void => {

                this._snackBar.open(this._translateService.instant(`offer.list.item.action.access.${data.item.status === 'accepted' ? 'success_accepted' : 'success_pending'}.value`), this._translateService.instant('notification.close.action.value'), {
                    duration: 5000
                });
            });
        }

        if (this.hasOneOfThisRoles(['ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            const data: object = {
                offer: {
                    id: this.item.id
                },
                markup: null
            };

            const title: string = this._translateService.instant('offer.list.item.action.access.title.value');

            const content: string = this._translateService.instant('offer.list.item.action.access.content.value');

            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
                width: '500px',
                data: {
                    title,
                    content
                }
            });

            dialogRef.componentInstance.confirm.subscribe((): void => {

                this._offerCatalogService.createItemAPI(data).subscribe((data: OfferCatalog): void => {

                    this._loadSelfOfferCatalogs();

                    this._snackBar.open(this._translateService.instant(`offer.list.item.action.access.${data.status === 'accepted' ? 'success_accepted' : 'success_pending'}.value`), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });
                });
            });
        }
    }

    public openDuplicationDialog(): void {

        if(this.hasTranslationOnProcess){

            return;
        }

        const title: string = this._translateService.instant('offer.duplication.value');

        const content: string = this._translateService.instant('offer.duplication.content.value');

        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this._dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: {
                title,
                content
            }
        });

        dialogRef.componentInstance.confirm.subscribe((): void => {

            this._offerService.getItemAPI(this.item.id).subscribe((item: Offer): void => {

                const data: any = classToPlain(item);

                // Nettoyage des données

                delete data.id;

                delete data.presential.id;

                data.translations.forEach((translation: OfferTranslation): void => {

                    delete translation.id;

                    translation.name = this._translateService.instant('offer.duplication.name.value', { initialName: translation.name });
                });

                data.included.forEach((included: OfferIncluded): void => {

                    delete included.id;
                });

                data.interests.forEach((interest: OfferInterest): void => {

                    delete interest.id;
                });

                data.locations.forEach((location: OfferLocation): void => {

                    delete location.id;
                });

                data.priceLevels.forEach((priceLevel: OfferPriceLevel): void => {

                    delete priceLevel.id;
                });

                data.programs.forEach((program: OfferProgram): void => {

                    delete program.id;
                });

                data.published = 'draft';

                // Création de l'offre dupliquée

                this._offerService.createItemAPI(data, this._societyService.currentUserSociety.getValue().id).pipe(
                    mergeMap((offer: Offer): Observable<Offer> => {

                        return this._offerService.duplicateDatesItemAPI(this.item.id, offer.id).pipe(
                            map((): Offer => {

                                return offer;
                            })
                        );
                    })
                ).subscribe((duplicatedOffer: Offer): void => {

                    this._snackBar.open(this._translateService.instant('offer.duplication.success.value'), this._translateService.instant('notification.close.action.value'), {
                        duration: 5000
                    });

                    this._router.navigate(['account/offer/update', duplicatedOffer.id], {queryParams: { fromDuplication: 1 }});
                });
            });
        });
    }

    public setView(value: ViewType): void {

        this.view = value;
    }

    public isView(value: ViewType): boolean {

        return this.view === value;
    }

    public hasRole(role: Role): boolean {

        return this.user ? this.user.roles.includes(role) : false;
    }

    public hasAllOfThisRoles(roles: Role[]): boolean {

        return roles.every((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public hasOneOfThisRoles(roles: Role[]): boolean {

        return roles.some((role: Role): boolean => {

            return this.hasRole(role);
        });
    }

    public isMode(mode: ModeType): boolean {

        return this.mode === mode;
    }

    public hasOneOfThisMode(modes: ModeType[]): boolean{

        return modes.some((mode: ModeType) => {

            return this.isMode(mode);
        });

    }

    public isLayoutView(value: ('list'|'map')): boolean {

        return this.layoutView === value;
    }

    public hasAccessCreate(): boolean{

        const accesses = this.user.accesses.filter((access: Access) => {

            return access.tag == 'OFFER_CREATE_IS_MINE';
        });

        return (accesses.length > 0);
    }

    public hasAccessEdit(): boolean{
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'OFFER_EDIT_IS_MINE';
        });

        return (accesses.length > 0);
    }

    public hasAccessCatalogList(): boolean{
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'OFFER_CATALOG_LIST';
        });

        return (accesses.length > 0);
    }

    public hasAccessCatalogCreate(): boolean{
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'OFFER_CATALOG_CREATE';
        });

        return (accesses.length > 0);
    }

    public hasAccessCatalogDelete(): boolean{
        const accesses = this.user.accesses.filter((access: Access) => {
            return access.tag == 'OFFER_CATALOG_DELETE_IS_MINE';
        });

        return (accesses.length > 0);
    }

    public parseMarkup(value: number): string {

        return !!value ? (parseMarkup(value) + '%') : '';
    }

    public goToOffer(offer: Offer): void {

        if(this.hasTranslationOnProcess){

            return;
        }

        this._router.navigate(['/account/offer/read', offer.id],{ queryParams: { origin: 'offerList', mode: this.mode, layoutView: this.layoutView }});
    }

    public displayOnlineSaleStatus(type: DisplayOnlineSaleType): boolean {

        if (!this.displayOfferOnlineSale) {

            return false;
        }

        const extrasData = this.offerSearchService.extrasData$.getValue();

        const isChannel: boolean = ('isDistributor' in extrasData);

        const isDistributor: boolean = isChannel ? extrasData['isDistributor'] as boolean : false;

        const disableConditions: boolean[] = [
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && !this.hasAccessOnlineSale,
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && (isChannel && !this.user.society?.isValidMangoPayAccount && isDistributor),
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && (isChannel && !this.item.onlineSale.isAvailableOnlineSale && !isDistributor)
        ];

        const warningConditions: boolean[] = [
            this.item.onlineSale.stopSale
        ];

        const availableConditions: boolean[] = [
            (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && !this.item.onlineSale.stopSale,
            this.hasAccessOnlineSale && !this.item.onlineSale.stopSale && (!isChannel || (this.user.society.isValidMangoPayAccount && isDistributor) || (this.item.onlineSale.isAvailableOnlineSale && !isDistributor))
        ];

        const disable: boolean = disableConditions.some((value: boolean) => {
            return value;
        });

        const warning: boolean = warningConditions.some((value: boolean) => {
            return value;
        });

        const available: boolean = availableConditions.some((value: boolean) => {
            return value;
        });

        switch (type) {

            case 'disable' :

                return disable;

            case 'warning' :

                return warning && !disable && !available;

            case 'available' :

                return available && !disable && !warning;

            default :

                return false;
        }
    }

    public onlineSaleContent(type: DisplayOnlineSaleType): string {

        if(type === 'available') {

            return this._translateService.instant('offer.onlineSale.status.availableOnlineSale.value');
        }

        if(type === 'warning') {

            return this.user.society !== null && this.item.society.id === this.user.society.id ?
                this._translateService.instant('offer.onlineSale.status.warningOnlineSale.offerCreator.value') :
                this._translateService.instant('offer.onlineSale.status.warningOnlineSale.value');
        }

        if(type === 'disable') {

            const extrasData = this.offerSearchService.extrasData$.getValue();

            const isChannel: boolean = ('isDistributor' in extrasData);

            const isDistributor: boolean = isChannel ? extrasData['isDistributor'] as boolean : false;

            switch(true) {

                case this.user.society !== null && this.item.society.id === this.user.society.id && !this.user.society.isValidMangoPayAccount :

                    return this._translateService.instant('offer.onlineSale.status.disableOnlineSale.offerCreator.value');

                case !isChannel :

                    return this._translateService.instant('offer.onlineSale.status.disableOnlineSale.distributor.value');

                case isDistributor :

                    return this._translateService.instant('offer.onlineSale.status.disableOnlineSale.channel.isDistributor.value');

                case !isDistributor :

                    return this._translateService.instant('offer.onlineSale.status.disableOnlineSale.channel.isNotDistributor.value');
            }
        }

        return null;
    }

    public displayGiftVoucherStatus(type: DisplayGiftVoucherType): boolean {

        if (!this.displayOfferGiftVoucher) {

            return false;
        }

        const extrasData = this.offerSearchService.extrasData$.getValue();

        const isChannel: boolean = ('isDistributor' in extrasData);

        const isDistributor: boolean = isChannel ? extrasData['isDistributor'] as boolean : false;

        const disableConditions: boolean[] = [
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && !this.hasAccessOnlineSale,
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && (isChannel && !this.user.society?.isValidMangoPayAccount && isDistributor),
            !(this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null) && (isChannel && !this.item.giftVoucher.isAvailableOnlineSale && !isDistributor)
        ];

        const availableConditions: boolean[] = [
            (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) || this.user.society === null),
            this.hasAccessOnlineSale && (!isChannel || (this.user.society.isValidMangoPayAccount && isDistributor) || (this.item.giftVoucher.isAvailableOnlineSale && !isDistributor))
        ];

        const disable: boolean = disableConditions.some((value: boolean) => {
            return value;
        });

        const available: boolean = availableConditions.some((value: boolean) => {
            return value;
        });

        switch (type) {

            case 'disable' :

                return disable;

            case 'available' :

                return available && !disable;

            default :

                return false;
        }
    }

    public giftVoucherContent(type: DisplayGiftVoucherType): string {

        if(type === 'available') {

            return this._translateService.instant('offer.giftVoucher.status.availableGiftVoucher.value');
        }

        if(type === 'disable') {

            const extrasData = this.offerSearchService.extrasData$.getValue();

            const isChannel: boolean = ('isDistributor' in extrasData);

            const isDistributor: boolean = isChannel ? extrasData['isDistributor'] as boolean : false;

            switch(true) {

                case this.user.society !== null && this.item.society.id === this.user.society.id && !this.user.society.isValidMangoPayAccount :

                    return this._translateService.instant('offer.giftVoucher.status.disableGiftVoucher.offerCreator.value');

                case !isChannel :

                    return this._translateService.instant('offer.giftVoucher.status.disableGiftVoucher.distributor.value');

                case isDistributor :

                    return this._translateService.instant('offer.giftVoucher.status.disableGiftVoucher.channel.isDistributor.value');

                case !isDistributor :

                    return this._translateService.instant('offer.giftVoucher.status.disableGiftVoucher.channel.isNotDistributor.value');
            }
        }

        return null;
    }

    get hasOfferPromotion(): boolean {

        if (this.publicPrice){

            const extrasData = this.offerSearchService.extrasData$.getValue();

            if(('isDistributor' in extrasData) && !(extrasData['isDistributor'] as boolean)){

                return this.item.hasOfferPromotion;
            }
            else{

                if(this.item.isDisplayPriceHT){

                    return this.offerCatalog?.startFromPriceHT !== this.offerCatalog?.startFromPromotionPriceHT;
                }
                else{

                    return this.offerCatalog?.startFromPriceTTC !== this.offerCatalog?.startFromPromotionPriceTTC;
                }
            }
        }
        else{

            return this.item.hasOfferPromotion;
        }
    }

    get timerPromotion(): string {

        let response = '';

        const now = new Date();

        let dateEnd = new Date();

        if (this.publicPrice) {

            const extrasData = this.offerSearchService.extrasData$.getValue();

            if(('isDistributor' in extrasData) && !(extrasData['isDistributor'] as boolean)){

                if (!this.item.dateEndPromotion){

                    return '';
                }

                dateEnd = new Date(this.item.dateEndPromotion);
            }
            else{

                if (!this.offerCatalog?.dateEndPromotion){

                    return '';
                }

                dateEnd = new Date(this.offerCatalog?.dateEndPromotion);
            }

        } else{

            if (!this.item.dateEndPromotion){

                return '';
            }

            dateEnd = new Date(this.item.dateEndPromotion);
        }

        let delta = Math.abs(dateEnd.getTime() - now.getTime()) / 1000;

        const result = {};

        const structure = {
            day: 86400,
            hour: 3600,
            minute: 60,
        };

        let count = 0;

        Object.keys(structure).forEach((key): void => {

            result[key] = Math.floor(delta / structure[key]);

            delta -= result[key] * structure[key];

            if (result[key] > 0 && count < 2){

                response += result[key] + this._translateService.instant('timer.' + key + '.value') + ' ';

                count++;
            }
        });

        return response;
    }

    get localeId(): string {

        return this._translateService.currentLang;
    }

    get offerCatalog(): OfferCatalog {

        return this._offerCatalogService.selfItems.value.find((item: OfferCatalog): boolean => {

            return item.offer.id === this.item.id;
        });
    }

    get isAcceptedOfferCatalog(): boolean {

        if (!this.isInCatalog){

            return false;
        }

        return this.offerCatalog.status === 'accepted';
    }

    get isPendingOfferCatalog(): boolean {

        if (!this.isInCatalog){

            return false;
        }

        return this.offerCatalog.status === 'pending';
    }

    get isRefusedOfferCatalog(): boolean {

        if (!this.isInCatalog){

            return false;
        }

        return this.offerCatalog.status === 'refused';
    }

    get isInCatalog(): boolean {

        return !!this.offerCatalog;
    }

    get isHighlighted(): boolean {

        if(!this.item.dateEndHighlighting){

            return false;
        }

        const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

        return this.item.dateEndHighlighting >= today;
    }

    get displayOfferPublishedStatus(): boolean {

        if (this.hasOneOfThisRoles(['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])){

            return true;
        }

        if (!this.hasRole('ROLE_OFFER_CREATOR')){

            return false;
        }

        return this.user.society.id === this.item.society.id;
    }

    get displayOfferOnlineSale(): boolean {

        const extrasData = this.offerSearchService.extrasData$.getValue();

        if(('allowShowcase' in extrasData) && (extrasData['allowShowcase'] as boolean)){

            return false;
        }

        if (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])) {

            return this.item.onlineSale && this.item.onlineSale.enable && this.item.onlineSale.isAvailableOnlineSale;
        }

        return this.item.onlineSale && this.item.onlineSale.enable;
    }

    get displayOfferGiftVoucher(): boolean {

        const extrasData = this.offerSearchService.extrasData$.getValue();

        if(('allowShowcase' in extrasData) && (extrasData['allowShowcase'] as boolean)){

            return false;
        }

        if (this.hasOneOfThisRoles(['ROLE_PROVIDER', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])) {

            return this.item.giftVoucher && this.item.giftVoucher.enable && this.item.giftVoucher.isAvailableOnlineSale;
        }

        return this.item.giftVoucher && this.item.giftVoucher.enable;
    }

    get isReferenced(): boolean {

        const match: OfferCatalog = this._offerCatalogService.getSelfItemByOffer(this.item);

        if (!match){

            return false;
        }

        return match.isReferenced;
    }

    get map(): LeafletMap {

        return this.directive.getMap();
    }

    get fitBounds(): LatLngBounds {

        const bounds: LatLngBounds = featureGroup(this.markers).getBounds();

        return bounds.isValid() ? bounds : null;
    }

    get hasAccessOnlineSale(): boolean {

        return this.user.accesses.some((access: Access): boolean => {

            return access.tag === 'ONLINE_SALE';
        });
    }

    get hasTranslationOnProcess(): boolean {

        return this.item.hasTranslationRequestToProcess && this.user.society?.id === this.item.society.id;
    }

    get locales(): string[]{

        return this.item.locales.filter((locale: string): boolean => {

            return !this.item.localesPendingTranslationRequest.includes(locale);
        });
    }

    get displayPermanentOptions(): boolean {

        return Boolean(this.displayedPermanentOptions.length);
    }

    get permanentOptionsContent(): string {

        return `${this._translateService.instant('offer.permanentOptions.available.value')} : ${this.displayedPermanentOptions.map((item: OfferPermanentOption): string => {

            return this.translationService.getFallbackTranslation(item.translations).title;

        }).join(', ')}`;
    }
}
