<div>

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ (userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN']) ? 'invoice.list.management.value' : 'invoice.self.tywin.value') | translate }}</h1>
            </div>
            <div class="col_12 y_items_center">
                <button  (click)="openInvoiceDonwloadDialog('pdf')" class="btn_cta btn_little">{{ 'invoice.download.pdf.all.action.value' | translate }}</button>
                <button  (click)="openInvoiceDonwloadDialog('csv')" class="btn_cta btn_little">{{ 'invoice.download.csv.all.action.value' | translate }}</button>
            </div>
        </div>
    </div>

    <div class="content_table">
        <table mat-table matSort [dataSource]="items" class="mat-elevation-z8">

            <!-- Filtres -->

            <ng-container>

                <!--Actions-->

                <ng-container [matColumnDef]="getFilterColumnDef('actions')">
                    <th mat-header-cell *matHeaderCellDef>
                        <button (click)="resetFilters()" mat-icon-button>
                            <mat-icon>loop</mat-icon>
                        </button>
                    </th>
                </ng-container>

                <!-- Date de facturation -->

                <ng-container [matColumnDef]="getFilterColumnDef('createdAt')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-date-interval-filter #filter [builder]="filterBuilder" [key]="'createdAt'" [startOperator]="'gte'" [endOperator]="'lte'"></app-core-date-interval-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <!-- Nom de la société -->

                    <ng-container [matColumnDef]="getFilterColumnDef('societyName')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.societyName.value' | translate" [key]="'societyName'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                    <!-- Nom contact principal -->

                    <ng-container [matColumnDef]="getFilterColumnDef('contactLastname')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.contactLastname.value' | translate" [key]="'contactLastname'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                    <!-- Prénom contact principal -->

                    <ng-container [matColumnDef]="getFilterColumnDef('contactFirstname')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.contactFirstname.value' | translate" [key]="'contactFirstname'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                    <!-- Email facturation -->

                    <ng-container [matColumnDef]="getFilterColumnDef('contactEmail')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.contactEmail.value' | translate" [key]="'contactEmail'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                </ng-container>

                <!-- Montant de la facture HT -->

                <ng-container [matColumnDef]="getFilterColumnDef('amountHT')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- Montant de la facture TTC -->

                <ng-container [matColumnDef]="getFilterColumnDef('amountTTC')">
                    <th mat-header-cell *matHeaderCellDef></th>
                </ng-container>

                <!-- N° de facture -->

                <ng-container [matColumnDef]="getFilterColumnDef('number')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.number.value' | translate" [key]="'number'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Référence de paiement -->

                <ng-container [matColumnDef]="getFilterColumnDef('paymentReference')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.paymentReference.value' | translate" [key]="'paymentReference'" [operator]="'lk'"></app-core-text-filter>
                    </th>
                </ng-container>

                <!-- Types -->

                <ng-container [matColumnDef]="getFilterColumnDef('types')">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-core-select-array-multiple-filter #filter [builder]="filterBuilder" [label]="'invoice.type.value' | translate" [key]="'types'" [operator]="'andlkin'" [items]="types" [itemLabelProperty]="'label'" [itemValueProperty]="'id'"></app-core-select-array-multiple-filter>
                    </th>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR'])">

                    <!-- Nom de la société additionnelle (commission apporteur d'affaires) -->

                    <ng-container [matColumnDef]="getFilterColumnDef('additionalSociety')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-text-filter #filter [builder]="filterBuilder" [label]="'invoice.additionalSociety.value' | translate" [key]="'additionalSociety.name'" [operator]="'lk'"></app-core-text-filter>
                        </th>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <!-- Statut client -->

                    <ng-container [matColumnDef]="getFilterColumnDef('isUserEnabled')">
                        <th mat-header-cell *matHeaderCellDef>
                            <app-core-select-boolean-filter #filter [builder]="filterBuilder" [label]="'invoice.isUserEnabled.value' | translate" [key]="'society.id'" [truthyLabel]="'activation.enabled.value' | translate" [falsyLabel]="'activation.disabled.value' | translate"></app-core-select-boolean-filter>
                        </th>
                    </ng-container>

                </ng-container>

            </ng-container>

            <!-- Ligne de données -->

            <ng-container>

                <!--Actions-->

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{'actions.label.value' | translate}}</th>
                    <mat-cell *matCellDef="let element; let i = index">
                        <button mat-button (click)="downloadItem(element, 'pdf')" matTooltip="{{ 'invoice.download.pdf.action.value' | translate }}">
                            <mat-icon>picture_as_pdf</mat-icon>
                        </button>
                        <button mat-button (click)="downloadItem(element, 'csv')" matTooltip="{{ 'invoice.download.csv.action.value' | translate }}">
                            <mat-icon>table_view</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Date de facturation -->

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.createdAt.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).createdAt | customDate }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <!-- Nom de la société -->

                    <ng-container matColumnDef="societyName">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.societyName.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).societyName}}</td>
                    </ng-container>

                    <!-- Nom contact principal -->

                    <ng-container matColumnDef="contactLastname">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.contactLastname.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).contactLastname}}</td>
                    </ng-container>

                    <!-- Prénom contact principal -->

                    <ng-container matColumnDef="contactFirstname">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.contactFirstname.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).contactFirstname}}</td>
                    </ng-container>

                    <!-- Email facturation -->

                    <ng-container matColumnDef="contactEmail">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.contactEmail.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).contactEmail}}</td>
                    </ng-container>

                </ng-container>

                <!-- Montant de la facture HT -->

                <ng-container matColumnDef="amountHT">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.amountHT.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getAmount(getModel(element), getModel(element).amount)}}</td>
                </ng-container>

                <!-- Montant de la facture TTC -->

                <ng-container matColumnDef="amountTTC">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.amountTTC.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getAmount(getModel(element), getModel(element).amountTTC)}}</td>
                </ng-container>

                <!-- N° de facture -->

                <ng-container matColumnDef="number">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.number.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).number}}</td>
                </ng-container>

                <!-- Référence de paiement -->

                <ng-container matColumnDef="paymentReference">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.paymentReference.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{getModel(element).paymentReference}}</td>
                </ng-container>

                <!-- Types -->

                <ng-container matColumnDef="types">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.type.value' | translate}}</th>
                    <td mat-cell *matCellDef="let element">{{ getTypesLabel(getModel(element).types) }}</td>
                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OFFER_CREATOR'])">

                    <!-- Nom de la société additionnelle (commission apporteur d'affaires) -->

                    <ng-container matColumnDef="additionalSociety">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.additionalSociety.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).additionalSociety?.name}}</td>
                    </ng-container>

                </ng-container>

                <ng-container *ngIf="userService.hasOneOfThisRoles(currentUser, ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'])">

                    <!-- Statut client -->

                    <ng-container matColumnDef="isUserEnabled">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'invoice.isUserEnabled.value' | translate}}</th>
                        <td mat-cell *matCellDef="let element">{{getModel(element).isUserEnabled | activationState }}</td>
                    </ng-container>

                </ng-container>

            </ng-container>

            <!-- Entêtes de colonne -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <!-- Filtres -->

            <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"></tr>

            <!-- Ligne de données -->

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>

    <!--Pagination-->

    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalItems"></mat-paginator>
</div>
