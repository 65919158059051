<form [formGroup]="form" (ngSubmit)="formService.submit()" (keydown)="submitEnter($event)">

    <div class="page_title">
        <div class="row marges">
            <div class="col y_items_center">
                <h1 class="title">{{ 'channel.update.value' | translate }}</h1>
            </div>

            <div class="col_content y_items_center">

                <!-- Retour -->

                <button class="btn_cta btn_lines btn_little" type="button" (click)="redirectToList()">
                    {{ 'channel.list.back.value' | translate }}
                </button>

                <!-- Soumission -->

                <button class="btn_cta btn_little" type="button" (click)="submit(false)">
                    {{ 'form.submit.action.value'|translate }}
                </button>

                <ng-container>

                    <!-- Enregistrer et continuer -->
                    <button class="btn_cta btn_little" type="button" (click)="submit(true)">
                        {{ 'channel.add.saveAndContinue.action.value'|translate }}
                    </button>

                </ng-container>

            </div>
        </div>
    </div>

    <!-- Gestion des onglets -->

    <mat-tab-group #tabGroup>
        <mat-tab [label]="'offer.generalData.value' | translate">
            <ng-container *ngTemplateOutlet="generalData"></ng-container>
        </mat-tab>
        <ng-container *ngIf="isClassicMode">
            <mat-tab [label]="'offer.list.selection.value' | translate">
                <ng-container *ngTemplateOutlet="offersSelection"></ng-container>
            </mat-tab>
            <mat-tab [label]="'channel.integration.js.value' | translate" *ngIf="channel.enableJS">
                <ng-container *ngTemplateOutlet="JSIntegration"></ng-container>
            </mat-tab>
            <mat-tab [label]="'channel.integration.subDomain.value' | translate" *ngIf="channel.enableSubDomain">
                <ng-container *ngTemplateOutlet="SubdomainIntegration"></ng-container>
            </mat-tab>
            <mat-tab [label]="'channel.integration.api.value' | translate" *ngIf="channel.enableApi">
                <ng-container *ngTemplateOutlet="APIIntegration"></ng-container>
            </mat-tab>
        </ng-container>
        <mat-tab #offerCardPreviewTab [label]="'offer.card.preview.value' | translate" *ngIf="isOneOfTheseIntegrationModes(['subDomain', 'JS'])">
            <ng-container *ngTemplateOutlet="offerCardPreview; context:{ parentTab: offerCardPreviewTab }"></ng-container>
        </mat-tab>
        <mat-tab #offerViewPreviewTab [label]="'offer.view.preview.value' | translate" *ngIf="isOneOfTheseIntegrationModes(['subDomain', 'JS'])">
            <ng-container *ngTemplateOutlet="offerViewPreview; context:{ parentTab: offerViewPreviewTab }"></ng-container>
        </mat-tab>
    </mat-tab-group>

</form>

<!-- Données générales -->

<ng-template #generalData>

    <div class="ligne_form row marges" *ngIf="channel.individualQuota || channel.groupQuota">
        <div class="col_12" style="background-color:#eaeaea;">
            <div style="padding:10px">
                {{ 'channel.individualQuota.max.value' | translate }} : {{ channel.individualQuota }}
                <br/>
                {{ 'channel.groupQuota.max.value' | translate }} : {{ channel.groupQuota }}
            </div>
        </div>
    </div>

    <ng-container [formGroup]="form">

        <ng-container *ngIf="society">

            <div class="form_part">

                <p class="title_part">{{'channel.form.generalData.title.web.value' | translate}}</p>

                <ng-container *ngIf="hasRole('ROLE_OFFER_DISTRIBUTOR') || hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Distributeur du canal -->

                            <div class="form-group">
                                <label>{{'channel.distributor.question.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="true" formControlName="isDistributor" class="form-control" id="isDistributorYes">
                                    <label for="isDistributorYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="isDistributor" class="form-control" id="isDistributorNo">
                                    <label for="isDistributorNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ ('channel.distributor.' + (form.get('isDistributor').value ? 'checked' : 'unchecked') + '.description.value') | translate }}
                        </span>
                                <mat-error *ngIf="form.get('isDistributor').errors && (form.get('isDistributor').dirty || form.get('isDistributor').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('isDistributor').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">

                    <div class="col_12">

                        <!-- Nom du proprietaire -->

                        <div class="form-group">
                            <select class="form-control" id="owner" formControlName="owner" required="required">
                                <option [ngValue]="null"></option>
                                <option *ngFor="let society of societies" [ngValue]="society.id">{{ society.name }}</option>
                            </select>
                            <label class="required" for="owner">{{ 'channel.owner.value' | translate }}</label>
                        </div>

                    </div>

                </div>

                <div class="row marges ligne_form">

                    <div class="col_6">

                        <!-- Marketplace -->

                        <div class="form-group">
                            <select class="form-control" id="marketplace" formControlName="marketplace" required="required" [compareWith]="compareSocietyWebsite">
                                <option *ngFor="let societyWebsite of societyWebsites$ | async" [ngValue]="societyWebsite">
                                    {{ societyWebsite.website }}
                                </option>
                            </select>
                            <label class="required" for="marketplace">{{ 'channel.restricted.list.fields.marketplace.value' | translate }}</label>
                            <mat-error *ngIf="form.get('marketplace').errors && (form.get('marketplace').dirty || form.get('marketplace').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('marketplace').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                            <span class="message-help" *ngIf="channel.enableJS">
                            <mat-icon class="tooltip_icon">info</mat-icon>{{ (isClassicMode ? 'channel.marketplace.update.integration.value' : 'channel.restricted.marketplace.update.integration.value') | translate }}
                        </span>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Mode d'intégration du canal  -->

                        <div class="form-group">
                            <label>{{'channel.integrationMode.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="'subDomain'" formControlName="integrationMode" class="form-control" id="integrationModeDomain">
                                <label for="integrationModeDomain">{{'channel.integrationMode.domain.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="'JS'" formControlName="integrationMode" class="form-control" id="integrationModeJs">
                                <label for="integrationModeJs">{{'channel.integrationMode.js.value' | translate}}</label>
                            </div>
                            <div class="radio" *ngIf="society.hasAccessAPI">
                                <input type="radio" [value]="'API'" formControlName="integrationMode" class="form-control" id="integrationModeApi">
                                <label for="integrationModeApi">{{'channel.integrationMode.api.value' | translate}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="channel.enableJS">

                    <div class="row marges ligne_form">
                        <div class="col_8">

                            <!-- Url canonical -->

                            <div class="form-group">
                                <div class="row">
                                    <div class="col_content addon_input y_items_center">
                                        <span>{{ marketplace }}</span>
                                    </div>
                                    <div class="col content_input">
                                        <input type="text" value="/" placeholder=" " class="form-control" formControlName="canonicalUrl" id="canonicalUrl">
                                        <label for="canonicalUrl">{{'channel.canonicalUrl.value' | translate}}</label>
                                        <mat-icon class="tooltip_icon_edit" (click)="editCanonicalUrl()">edit</mat-icon>
                                    </div>
                                </div>
                                <mat-error *ngIf="form.get('canonicalUrl').errors && (form.get('canonicalUrl').dirty || form.get('canonicalUrl').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('canonicalUrl').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                        <div *ngIf="form.get('canonicalUrl').errors.isPatternInvalid">{{ 'form.control.error.pattern.value' | translate }}</div>
                                    </div>
                                </mat-error>
                                <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ 'channel.canonicalUrl.info.value' | translate }}
                                    <br/>
                                    {{ 'channel.canonicalUrl.update.value' | translate }}
                        </span>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="channel.enableApi">

                    <div class="row marges ligne_form">

                        <div class="col_6">

                            <!-- Adresses IPs -->

                            <div class="form-group">
                                <mat-select formControlName="allowedIps" multiple [compareWith]="compareSocietyIp">
                                    <mat-option *ngFor="let societyIp of societyIps;" [value]="societyIp">{{ societyIp.address | translate }}</mat-option>
                                </mat-select>
                                <mat-label class="required">{{ 'ipAddress.plural.allowed.value' | translate }}</mat-label>
                                <mat-error *ngIf="form.get('allowedIps').invalid">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('allowedIps').errors.isRequired">{{ 'ipAddress.selection.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </div>

                    </div>

                </ng-container>

                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && isOneOfTheseIntegrationModes(['subDomain', 'JS'])">

                    <div class="row marges ligne_form">
                        <div class="col_12">

                            <!-- Référencer les offres du canal -->

                            <div class="form-group">
                                <label>{{'channel.referenced.question.value' | translate}}</label>
                                <div class="radio">
                                    <input type="radio" [value]="true" formControlName="referenced" class="form-control"
                                           id="referencedYes">
                                    <label for="referencedYes">{{'form.user.fields.yes.value' | translate}}</label>
                                </div>
                                <div class="radio">
                                    <input type="radio" [value]="false" formControlName="referenced" class="form-control" id="referencedNo">
                                    <label for="referencedNo">{{'form.user.fields.no.value' | translate}}</label>
                                </div>
                                <span class="message-help">
                            <mat-icon class="tooltip_icon">info</mat-icon>
                                    {{ ('channel.referenced.description.' + (form.get('referenced').value ? 'checked' : 'unchecked') + '.value') | translate }}
                        </span>
                                <mat-error *ngIf="form.get('referenced').errors && (form.get('referenced').dirty || form.get('referenced').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="form.get('referenced').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Activer le canal -->

                        <div class="form-group">
                            <label>{{'channel.enable.question.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="enable" class="form-control"
                                       id="enableYes">
                                <label for="enableYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="enable" class="form-control" id="enableNo">
                                <label for="enableNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <span class="message-help error" *ngIf="!isActivationAllowed">
                        <mat-icon class="tooltip_icon">info</mat-icon>
                                {{ 'channel.enable.help.disable.value' | translate }}
                    </span>
                            <mat-error *ngIf="form.get('enable').errors && (form.get('enable').dirty || form.get('enable').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('enable').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12 col_md_6">

                        <!-- Commentaire -->

                        <div class="form-group">
                        <textarea placeholder=" " class="form-control" formControlName="comment"
                                  id="comment"></textarea>
                            <label for="comment">{{'comment.internal.value' | translate}}</label>
                        </div>

                    </div>
                </div>

            </div>

            <div class="form_part">

                <p class="title_part">{{ 'channel.form.generalData.title.display.value' | translate }}</p>

                <div class="ligne_form row marges">

                    <div class="col_12">

                        <ng-container formArrayName="translations">

                            <ng-container [formGroupName]="translationBuilder.indexAsString(0)">

                                <div class="row marges ligne_form">

                                    <div class="col_6">

                                        <!-- Référence -->

                                        <div class="form-group">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="name" id="name" required="required">
                                            <label for="name">{{ 'channel.name.value' | translate }}</label>
                                            <mat-error *ngIf="getTranslation(0).get('name').errors && (getTranslation(0).get('name').dirty || getTranslation(0).get('name').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="getTranslation(0).get('name').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>

                                    <div class="col_6">

                                        <!-- Titre -->

                                        <div class="form-group">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="title" id="title">
                                            <label for="name">{{ 'channel.title.value' | translate }}</label>
                                            <div class="form-group col_content y_items_center">
                                                <span class="message-help">
                                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                                    {{ 'channel.title.info.value' | translate }}
                                                </span>
                                            </div>
                                            <mat-error *ngIf="getTranslation(0).get('title').errors && (getTranslation(0).get('title').dirty || getTranslation(0).get('title').touched)">
                                                <div class="invalid-feedback">
                                                    <div *ngIf="getTranslation(0).get('title').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                                </div>
                                            </mat-error>
                                        </div>

                                    </div>

                                </div>

                                <div class="row marges ligne_form">
                                    <div class="col_12">

                                        <!-- Description -->
                                        <div class="form-group">
                                            <input type="text" value="" placeholder=" " class="form-control" formControlName="description" id="description">
                                            <label for="description">{{'description.value' | translate}}</label>
                                            <div class="form-group col_content y_items_center">
                                                <span class="message-help">
                                                    <mat-icon class="tooltip_icon">info</mat-icon>
                                                    {{ 'description.info.value' | translate }}
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </ng-container>

                        </ng-container>

                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_4">
                        <div class="form-group add_picture big">
                            <div class="row">
                                <div class="form-group">
                                    <label>{{'channel.logo.value' | translate}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col_content">
                                    <form-field-image [config]="logoConfig" [form]="form.get('logo').get('image')"></form-field-image>
                                    <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'channel.logo.info.value' | translate }}
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col_4">
                        <div class="form-group add_picture big">
                            <div class="row">
                                <div class="form-group">
                                    <label>{{'channel.banner.value' | translate}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col_content">
                                    <form-field-image [config]="imageConfig" [form]="form.get('picture').get('image')"></form-field-image>
                                    <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                        {{ 'channel.banner.info.value' | translate }}
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="form.get('integrationMode').value === 'subDomain'">
                        <div class="col_4">
                            <div class="form-group add_picture big">
                                <div class="row">
                                    <div class="form-group">
                                        <label>{{'channel.favicon.value' | translate}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col_content">
                                        <form-field-file [config]="faviconConfig" [form]="form" [file]="form.get('favicon')"></form-field-file>
                                        <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                            {{ 'channel.favicon.info.value' | translate }}
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_6">
                        <div class="form-group" *ngIf="hasImage(form.get('logo'))">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="logoUrl" id="logoUrl">
                            <label for="logoUrl">{{ 'channel.logo.url.value' | translate }}</label>
                            <mat-error *ngIf="form.get('logoUrl').errors && (form.get('logoUrl').dirty || form.get('logoUrl').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('logoUrl').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="col_6">
                        <div class="form-group" *ngIf="hasImage(form.get('picture'))">
                            <input type="text" value="" placeholder=" " class="form-control" formControlName="pictureUrl" id="pictureUrl">
                            <label for="pictureUrl">{{ 'channel.banner.url.value' | translate }}</label>
                            <mat-error *ngIf="form.get('pictureUrl').errors && (form.get('pictureUrl').dirty || form.get('pictureUrl').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('pictureUrl').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Affichage du moteur de recherche -->
                        <div class="form-group">
                            <label>{{'channel.searchEngine.display.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="searchEngine" class="form-control" id="searchEngineYes">
                                <label for="searchEngineYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="searchEngine" class="form-control" id="searchEngineNo">
                                <label for="searchEngineNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="form.get('searchEngine').errors && (form.get('searchEngine').dirty || form.get('searchEngine').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('searchEngine').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Affichage de la carte -->

                        <div class="form-group">
                            <label>{{'channel.map.display.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="allowMap" class="form-control" id="allowMapYes">
                                <label for="allowMapYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="allowMap" class="form-control" id="allowMapNo">
                                <label for="allowMapNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="form.get('allowMap').errors && (form.get('allowMap').dirty || form.get('allowMap').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('allowMap').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">
                    <div class="col_12">

                        <!-- Affichage du créateur d'offre -->
                        <div class="form-group">
                            <label>{{ 'channel.displayCo.value' | translate}}</label>
                            <div class="radio">
                                <input type="radio" [value]="true" formControlName="displayCo" class="form-control" id="displayCoYes">
                                <label for="displayCoYes">{{'form.user.fields.yes.value' | translate}}</label>
                            </div>
                            <div class="radio">
                                <input type="radio" [value]="false" formControlName="displayCo" class="form-control" id="displayCoNo">
                                <label for="displayCoNo">{{'form.user.fields.no.value' | translate}}</label>
                            </div>
                            <mat-error *ngIf="form.get('displayCo').errors && (form.get('displayCo').dirty || form.get('displayCo').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('displayCo').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <ng-container formArrayName="style" *ngIf="isOneOfTheseIntegrationModes(['subDomain', 'JS'])">

                    <!-- Prévisualisations -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'channel.style.title.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_6">
                            <app-select-search [config]="fontFieldConfig" [value]="form.controls['style'].get('font').value" [form]="form" (valueChange)="changeFont($event)"></app-select-search>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_6">
                            <!-- colorTitle -->
                            <div class="form-group color">
                                <input type="text" (colorPickerChange)="updateColorField($event,'colorTitle')" autocomplete="off" [value]="colorTitle" [(colorPicker)]="colorTitle" placeholder=" " class="form-control" formControlName="colorTitle" id="colorTitle">
                                <label for="colorTitle">{{'channel.fields.colorTitle.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorTitle"></span>
                            </div>
                        </div>
                        <div class="col_6">
                            <!-- colorHighlighting -->
                            <div class="form-group color">
                                <input type="text" autocomplete="off" [value]="colorHighlighting" (colorPickerChange)="updateColorField($event,'colorHighlighting')"  [(colorPicker)]="colorHighlighting" placeholder=" " class="form-control" formControlName="colorHighlighting" id="colorHighlighting">
                                <label for="colorHighlighting">{{'channel.fields.colorHighlighting.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorHighlighting"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_6">
                            <!-- colorSubTitle -->
                            <div class="form-group color">
                                <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorSubTitle')" [value]="colorSubTitle" [(colorPicker)]="colorSubTitle" placeholder=" " class="form-control" formControlName="colorSubTitle" id="colorSubTitle">
                                <label for="colorSubTitle">{{'channel.fields.colorSubTitle.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorSubTitle"></span>
                            </div>
                        </div>
                        <div class="col_6">
                            <!-- colorTextButton -->
                            <div class="form-group color">
                                <input type="text" autocomplete="off" [value]="colorTextButton" (colorPickerChange)="updateColorField($event,'colorTextButton')"  [(colorPicker)]="colorTextButton" placeholder=" " class="form-control" formControlName="colorTextButton" id="colorTextButton">
                                <label for="colorTextButton">{{'channel.fields.colorTextButton.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorTextButton"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">
                        <div class="col_6">
                            <!-- colorDescription -->
                            <div class="form-group color">
                                <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorDescription')" [value]="colorDescription" [(colorPicker)]="colorDescription" placeholder=" " class="form-control" formControlName="colorDescription" id="colorDescription">
                                <label for="colorDescription">{{'channel.fields.colorDescription.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorDescription"></span>
                            </div>
                        </div>
                        <div class="col_6">
                            <!-- colorBackgroundButton -->
                            <div class="form-group color">
                                <input type="text" autocomplete="off" (colorPickerChange)="updateColorField($event,'colorBackgroundButton')" [value]="colorBackgroundButton" [(colorPicker)]="colorBackgroundButton" placeholder=" " class="form-control" formControlName="colorBackgroundButton" id="colorBackgroundButton">
                                <label for="colorBackgroundButton">{{'channel.fields.colorBackgroundButton.value' | translate}}</label>
                                <span class="show_color" [style.background]="colorBackgroundButton"></span>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <ng-container formArrayName="sort">

                    <!-- Tri des offres -->

                    <div class="row marges ligne_form">
                        <div class="col_12">
                            <div class="form-group">
                                <label>{{ 'channel.sort.title.value' | translate}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row marges ligne_form">

                        <div class="col_6">
                            <div class="form-group">
                                <select class="form-control" id="orderBy" formControlName="orderBy" required="required">
                                    <option value=""></option>
                                    <option *ngFor="let orderBy of sortsOrderBy$ | async" [value]="orderBy">{{ orderByLabel(orderBy) }}</option>
                                </select>
                                <label class="required" for="orderBy">{{ 'channel.fields.sort.orderBy.value' | translate }}</label>
                                <mat-error *ngIf="sortForm.get('orderBy').errors && (sortForm.get('orderBy').dirty || sortForm.get('orderBy').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="sortForm.get('orderBy').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>

                        <div class="col_6">
                            <div class="form-group">
                                <select class="form-control" id="operator" formControlName="operator" required="required">
                                    <option value=""></option>
                                    <option *ngFor="let operator of sortsOperators$ | async" [value]="operator">{{ operatorLabel(operator) }}</option>
                                </select>
                                <label class="required" for="operator">{{ 'channel.fields.sort.operator.value' | translate }}</label>
                                <mat-error *ngIf="sortForm.get('operator').errors && (sortForm.get('operator').dirty || sortForm.get('operator').touched)">
                                    <div class="invalid-feedback">
                                        <div *ngIf="sortForm.get('operator').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <label>{{ 'channel.locale.currency.title.value' | translate}}</label>
                        </div>
                    </div>
                </div>

                <div class="row marges ligne_form">

                    <ng-container *ngIf="isClassicMode">

                        <div class="col_6">

                            <!-- Langue -->

                            <div class="ligne_form row marges">
                                <div class="col_12">
                                    <div class="form-group">
                                        <select class="form-control" id="locale" formControlName="locale" required="required" (change)="changeLocale()">
                                            <option value=""></option>
                                            <option *ngFor="let locale of locales$ | async" [value]="locale.id">{{ locale.label | translate }}</option>
                                        </select>
                                        <label class="required" for="locale">{{ 'locale.value' | translate }}</label>
                                        <mat-error *ngIf="form.get('locale').errors && (form.get('locale').dirty || form.get('locale').touched)">
                                            <div class="invalid-feedback">
                                                <div *ngIf="form.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                            </div>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <div class="col_6">

                        <!-- Sélection de la devise -->
                        <div class="form-group">
                            <mat-select class="form-control" id="currency" formControlName="currency" required="required">
                                <mat-option value=""></mat-option>
                                <mat-option *ngFor="let currency of currencies$ | async" [value]="currency.id">
                                    {{ translationService.getFallbackTranslation(currency.translations).label }} ({{ currency.code }})
                                </mat-option>
                            </mat-select>
                            <mat-label class="required" for="currency">{{ 'currency.value' | translate }}</mat-label>
                            <mat-error *ngIf="form.get('currency').errors && (form.get('currency').dirty || form.get('currency').touched)">
                                <div class="invalid-feedback">
                                    <div *ngIf="form.get('currency').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                                </div>
                            </mat-error>
                        </div>

                    </div>

                </div>

            </div>

            <div class="form_part">

                <p class="title_part">{{'channel.form.generalData.title.marketing.value' | translate}}</p>

                <!-- Options de commercialisation -->

                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                <span>{{ 'channel.marketing.configuration.startFromPrice.warning.value' | translate }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form">
                    <div class="col_12">
                        <div class="form-group">
                            <div class="checkbox min-size">
                                <input type="checkbox" id="allowOnlineSale" formControlName="allowOnlineSale">
                                <label for="allowOnlineSale">{{ 'offer.onlineSale.value' | translate }}</label>
                            </div>
                            <div class="checkbox min-size">
                                <input type="checkbox" id="allowBooking" formControlName="allowBooking">
                                <label for="allowBooking">{{ 'booking.type.booking.value' | translate }}</label>
                            </div>
                            <div class="checkbox min-size">
                                <input type="checkbox" id="allowRequest" formControlName="allowRequest">
                                <label for="allowRequest">{{ 'booking.type.request.value' | translate }}</label>
                            </div>
                            <div class="checkbox min-size">
                                <input type="checkbox" id="allowGiftVoucher" formControlName="allowGiftVoucher">
                                <label for="allowGiftVoucher">{{ (hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) ? 'giftVoucher.value' : 'giftVoucher.eligibleOffer.value') | translate }}</label>
                            </div>
                            <ng-container *ngIf="isClassicMode && user.society.hasAccessCreateChannelShowcase">
                                <div class="checkbox min-size">
                                    <input type="checkbox" id="allowShowcase" formControlName="allowShowcase">
                                    <label for="allowShowcase">{{ 'channel.showcase.value' | translate }}</label>
                                </div>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="form.errors && form.errors.isMarketingConfigurationRequired">

                            <div class="form-group">
                                <mat-error>
                                    <div class="invalid-feedback">
                                        <div>{{ 'channel.marketing.configuration.required' | translate }}</div>
                                    </div>
                                </mat-error>
                            </div>

                        </ng-container>
                        <div class="form-group">
                            <span class="message-help" *ngIf="!isAllowGiftVoucher && hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR'])">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                <span>{{ 'channel.marketing.configuration.serviceRequired.description_1.value' | translate }} <a [routerLink]="['/account/service/list']">{{ 'user.account.services.value' | translate }}</a> {{ 'channel.marketing.configuration.serviceRequired.description_2.value' | translate }} <a [routerLink]="['/account/service/list']" [queryParams]="{ targetTab: 'document' }">{{ 'document.plural.yours.value' | translate }}</a> {{ 'channel.marketing.configuration.serviceRequired.description_3.value' | translate }}</span>
                            </span>
                        </div>
                        <div class="form-group" *ngIf="this.form.get('allowOnlineSale').value">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                <span>{{ 'channel.marketing.configuration.onlineSaleOnly.help.value' | translate }}</span>
                            </span>
                        </div>
                        <div class="form-group" *ngIf="form.get('allowShowcase').value">
                            <span class="message-help">
                                <mat-icon class="tooltip_icon">info</mat-icon>
                                <span>{{ 'channel.showcase.description.value' | translate }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row marges ligne_form" *ngIf="form.get('allowShowcase').value">
                    <div #showcaseReference class="col">

                    <!-- Afficher le tarif “à partir de” dans la miniature de l’offre -->

                    <div class="form-group indent_left">
                        <label>{{ 'channel.showcase.displayStartFromPrice.action.value' | translate}}</label>
                        <div class="radio">
                            <input type="radio" [value]="true" formControlName="displayStartFromPrice" class="form-control" id="displayStartFromPriceYes">
                            <label for="displayStartFromPriceYes">{{'form.user.fields.yes.value' | translate}}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" [value]="false" formControlName="displayStartFromPrice" class="form-control" id="displayStartFromPriceNo">
                            <label for="displayStartFromPriceNo">{{'form.user.fields.no.value' | translate}}</label>
                        </div>
                        <mat-error *ngIf="form.get('displayStartFromPrice').errors && (form.get('displayStartFromPrice').dirty || form.get('displayStartFromPrice').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('displayStartFromPrice').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>

                </div>
                </div>

            </div>

        </ng-container>

    </ng-container>
</ng-template>

<!-- Sélection des offres -->

<ng-template #offersSelection>

    <div [formGroup]="form" class="tab_content_overflow">
        <div class="row marges">
            <div class="col y_items_center">

                <div class="selected_offers" *ngIf="offerSearchService.selectedOffers.value.length">

                    <span>{{ 'offer.list.selected.value' | translate }} :</span>
                    <ul>
                        <li *ngFor="let offer of offerSearchService.selectedOffers.value">{{ translationService.getFallbackTranslation(offer.translations).name }}</li>
                    </ul>
                </div>
            </div>
            <div class="col_content y_items_center" *ngIf="offerSearchService.selectedOffers.value.length">
                <button type="button" class="btn_cta btn_little" (click)="deselectOffer() ">{{ 'channel.button.noSelectOffer.value' | translate}}</button>
            </div>

        </div>
        <app-core-offer-search
                [user]="user"
                [society]="society"
                [mode]="'channel'"
                [locale]="form.get('locale')"
                [referenced]="form.get('referenced')"
                [allowBooking]="form.get('allowBooking')"
                [allowGiftVoucher]="form.get('allowGiftVoucher')"
                [allowRequest]="form.get('allowRequest')"
                [allowOnlineSale]="form.get('allowOnlineSale')"
        ></app-core-offer-search>

    </div>

</ng-template>

<ng-template #JSIntegration>

    <div class="ck-content">

        <h3 style="font-weight: bold">{{ 'channel.help.jsIntegration.title.value' | translate }}</h3>

        <p>- {{ 'channel.help.jsIntegration.description.start.value' | translate }} <span class="modify" [innerText]="'<body>'"></span>{{ 'channel.help.jsIntegration.description.end.value' | translate }}</p>

        <div class="copy-content" (click)="copyJSIntegrationWCContent()" title="{{ 'channel.help.jsIntegration.copy.value' | translate }}">

            <pre><<span>tywin-channel</span><span class="no_modify">&nbsp;token="{{ channel.tokenJs }}</span>" <span class="modify">base-url="{{ canonicalUrl }}</span>"><<span>/tywin-channel</span>></pre>

        </div>

        <div class="form-group">

            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>
                <div [innerHTML]="('channel.help.jsIntegration.help.token.value' | translate)"></div>
            </span>

            <span class="message-help">
                <mat-icon class="tooltip_icon">info</mat-icon>
                <div [innerHTML]="(('channel.help.jsIntegration.help.baseUrl.value' | translate: {marketplace : marketplace}) | safeHtml)"></div>
            </span>

        </div>

        <p>- {{ 'channel.help.jsIntegration.script.value' | translate }} <span class="modify" [innerText]="'<body>'"></span> :</p>

        <div class="copy-content" (click)="copyJSIntegrationSourceContent()" title="{{'channel.help.jsIntegration.copy.value' | translate }}">

            <pre><span class="modify" [innerText]="JSIntegrationSourceTag"></span></pre>

        </div>

        <ng-container *ngIf="channel.referenced">

            <br>

            <h3 style="font-weight: bold">{{ 'channel.help.jsIntegration.referenced.title.value' | translate }}</h3>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.baseUrl.value' | translate: {channelId : channel.id})"></p>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.wildcard.value' | translate)"></p>

            <p [innerHTML]="('channel.help.jsIntegration.referenced.description.warning.value' | translate)"></p>

            <p [innerHTML]="(('channel.help.jsIntegration.referenced.description.warning.example.value' | translate: {marketplace: marketplace}) | safeHtml)"></p>

            <p [innerHTML]="(('channel.help.jsIntegration.referenced.description.sitemap.value' | translate: {sitemap: sitemapUrl}) | safeHtml)"></p>

        </ng-container>
    </div>

</ng-template>

<ng-template #SubdomainIntegration>

    <span style="padding-top: 10px;">{{ 'channel.help.subDomain.description.value' | translate }} <a href="{{ subDomainIntegrationLink }}" target="_blank"><span style="padding-top: 10px; color: orange; font-weight: bold" [innerText]="subDomainIntegrationLink"></span></a></span>

</ng-template>

<ng-template #APIIntegration>

    <div class="ck-content">

        <h3 style="font-weight: bold">{{ 'channel.help.apiIntegration.title.value' | translate }}</h3>

        <p>- {{ 'channel.id.value' | translate }} : <span class="modify">{{ channel.id }}</span></p>

        <div class="row">
            <div class="col_content">
                <p>- {{ 'ipAddress.plural.selected.value' | translate }} :&nbsp;</p>
            </div>
            <div class="col">
                <p *ngFor="let allowedIp of allowedIps" style="margin-bottom: 4px;">
                    <span class="modify">{{ allowedIp }}</span>
                </p>
            </div>
        </div>

        <div class="subscription_information">
            <p [innerHTML]="'channel.help.apIntegration.description.value' | translate"></p>
        </div>
    </div>

</ng-template>

<ng-template #offerCardPreview let-parentTab="parentTab">

    <ng-container *ngIf="parentTab.isActive">

        <app-core-channel-offer-card-preview [styleForm]="styleForm"></app-core-channel-offer-card-preview>

    </ng-container>

</ng-template>

<ng-template #offerViewPreview let-parentTab="parentTab">

    <ng-container *ngIf="parentTab.isActive">

        <app-core-channel-offer-view-preview [styleForm]="styleForm"></app-core-channel-offer-view-preview>

    </ng-container>

</ng-template>
