<div class="col_12" id="composition-item">

    <div class="row marges content-col-date">

        <!-- Voyageurs -->

        <div class="col_12 col_lg_6 semi-col-date" id="composition-step-traveller">

            <div class="item_date composition-data-wrapper" [ngClass]="getConfigurationStepDataElementClasses(configurationSteps.traveller)">

                <div class="composition-step-label" [ngClass]="getConfigurationStepLabelClasses(configurationSteps.traveller)">
                    {{ 'offer.dateEngine.traveller.plural.value' | translate }}
                </div>

                <div class="composition-step-content">

                    <div class="row">

                        <!-- Adulte -->

                        <ng-container *ngIf="offer.publics.includes('adult')">

                            <div class="choice_item choice_people y_items_center" [ngClass]="{'col_6' : offer.publics.includes('child'), 'col_12': !offer.publics.includes('child')}">
                                <div class="content_item">

                                    <select [(ngModel)]="countAdult" (change)="countAdultUpdatedSubject.next()">
                                        <option *ngFor="let count of countAdultValues" [ngValue]="count">{{ count }}</option>
                                    </select>

                                    <span class="label">{{ 'offer.dateEngine.adult.count.value' | translate }}</span>

                                </div>

                            </div>

                        </ng-container>

                        <!-- Enfant -->

                        <ng-container *ngIf="offer.publics.includes('child')">

                            <div class="choice_item choice_people y_items_center" [ngClass]="{'col_6' : offer.publics.includes('adult'), 'col_12': !offer.publics.includes('adult')}">
                                <div class="content_item">

                                    <select [(ngModel)]="countChild" (change)="countChildUpdatedSubject.next()">
                                        <option *ngFor="let count of countChildValues" [ngValue]="count">{{ count }}</option>
                                    </select>

                                    <span class="label">{{ 'offer.dateEngine.child.count.value' | translate }}</span>

                                </div>
                            </div>

                        </ng-container>

                    </div>

                </div>

                <mat-error *ngIf="!isValidNbPerson">
                    <div class="invalid-feedback">
                        <div>{{ 'form.control.error.nbPerson.max_exceed.value' | translate: {max: (offer.presential.max)} }}</div>
                    </div>
                </mat-error>

                <p *ngIf="offer.publics.includes('child')" class="info_step icon_info">
                    {{ 'offer.booking.composition.child.age.interval.value'|translate:{minChildrenAge: (offer.minChildrenAge), maxChildrenAge: (offer.maxChildrenAge|formatAge)} }}
                </p>

            </div>

        </div>

        <!-- Dates -->

        <div class="col_12 col_lg_6 semi-col-date" id="composition-step-date">

            <div class="item_date composition-data-wrapper" [ngClass]="getConfigurationStepDataElementClasses(configurationSteps.date)">

                <div class="col_12 composition-step-label" [ngClass]="getConfigurationStepLabelClasses(configurationSteps.date)">
                    {{ 'offer.dateEngine.date.plural.value' | translate }}
                </div>

                <div class="composition-step-content">

                    <div class="row">

                        <div class="col content_items">

                            <div class="row">

                                <!-- Départ -->

                                <div (click)="toggleCalendar()" class="col_6 choice_item choice_date y_items_center is_clickable" [ngClass]="{'open' : isCalendarOpened}">
                                    <div class="content_item">
                                        <span class="label">{{ 'arrival.value' | translate }}</span>

                                        <ng-container *ngIf="!displayedDates.start">

                                            <span class="value">{{ 'date.add.action.value' | translate }}</span>

                                        </ng-container>

                                        <ng-container *ngIf="!!displayedDates.start">

                                            <span class="value">{{ displayedDates.start.format('DD/MM/YYYY') }}</span>

                                        </ng-container>
                                    </div>
                                </div>

                                <!-- Retour -->

                                <div class="col_6 choice_item choice_date y_items_center disabled">
                                    <div class="content_item">
                                        <span class="label">{{ 'departure.value' | translate }}</span>

                                        <ng-container *ngIf="!!displayedDates.end">

                                            <span class="value">{{ displayedDates.end.format('DD/MM/YYYY') }}</span>

                                        </ng-container>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <p class="info_step error hidden" *ngIf="configurationSteps.date.enabled && !isValidationDateSelectionValid">{{ 'offer.dateEngine.choicedate.error.value' | translate }}</p>

                <!-- Affichage du calendrier -->

                <div [hidden]="!isCalendarOpened" class="offer_calendar">

                    <div>

                        <div class="navigation">

                            <!-- Semaine précédante -->

                            <div class="btn_cta btn_small btn_lines prev" [ngClass]="{ disabled: !isNavigationToPreviousMonthAllowed }" (click)="navigateToPreviousMonth()">
                                <mat-icon>chevron_left</mat-icon>
                            </div>

                            <!-- Semaine suivante -->

                            <div class="btn_cta btn_small btn_lines next" (click)="navigateToNextMonth()">
                                <mat-icon>chevron_right</mat-icon>
                            </div>

                        </div>

                        <!-- Affichage des mois -->

                        <div class="row x_between">
                            <div *ngFor="let month of months" class="month">

                                <div class="month_label">
                                    <span>{{ month.label }}</span>
                                </div>

                                <!-- Affichage des jours de la semaine (Lundi, Mardi...) -->

                                <div class="row">
                                    <div *ngFor="let dayName of dayNames" class="day day_name">
                                        {{ dayName }}
                                    </div>
                                </div>

                                <div class="row">

                                    <!-- Affichage des jours du mois précédant -->

                                    <div *ngFor="let day of month.previousMonthDays" class="day">

                                        <!-- Le jour appartenant au mois précédant, celui-ci n'est pas affiché -->

                                    </div>

                                    <!-- Affichage des jours du mois courant -->

                                    <div *ngFor="let day of month.days" [ngClass]="getDayClasses(day, availabilities[day.format('YYYY-MM-DD')])" (click)="updateSelection(day, availabilities[day.format('YYYY-MM-DD')])" class="day">

                                        <div class="legend-icon" [ngClass]="getDayPastilleClasses(availabilities[day.format('YYYY-MM-DD')])"></div>

                                        <span class="day_number">
                                            {{ day.format('DD') }}
                                        </span>

                                        <ng-container *ngIf="configuration.displayDayDetails">

                                            <ng-container *ngIf="availabilities[day.format('YYYY-MM-DD')].isAvailable">

                                                <ng-container *ngIf="availabilities[day.format('YYYY-MM-DD')].promotionPrice > 0">

                                                    <span class="day_info"><span [ngClass]="availabilities[day.format('YYYY-MM-DD')].promotionPrice < availabilities[day.format('YYYY-MM-DD')].price ? 'promotion' : ''">{{ availabilities[day.format('YYYY-MM-DD')].promotionPrice | parsePrice }} {{ availabilities[day.format('YYYY-MM-DD')].currency.symbol }} {{ availabilities[day.format('YYYY-MM-DD')].priceTypeLabel | translate }}</span></span>

                                                </ng-container>

                                            </ng-container>

                                            <ng-container *ngIf="availabilities[day.format('YYYY-MM-DD')].isOnRequest">

                                                <span class="day_info">{{ 'offer.availability.onRequest.value' | translate }}</span>

                                            </ng-container>

                                        </ng-container>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- Prix Net / Public -->

                        <div class="row marges" *ngIf="configuration.enablePublicPrice">

                            <div class="col_12 choice_price">
                                <div class="form-group">
                                    <mat-radio-group [(ngModel)]="publicPrice" (ngModelChange)="pricePublicUpdatedSubject.next()">
                                        <mat-radio-button [value]="0">{{ 'offer.dateEngine.publicPrice.net.value' | translate }}</mat-radio-button>
                                        <mat-radio-button [value]="1">{{ 'offer.dateEngine.publicPrice.public.value' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>

                        <div class="row x_between y_items_center">

                            <!-- Légende du calendrier -->

                            <div class="row marges legends">

                                <div class="col_content">
                                    <div class="legend">
                                        <span class="legend-icon legend-available"></span>
                                        <span>{{ 'offer.dateEngine.calendar.availability.available.value' | translate }}</span>
                                    </div>
                                </div>

                                <div class="col_content">
                                    <div class="legend">
                                        <span class="legend-icon legend-onbooking"></span>
                                        <span>{{ 'offer.dateEngine.calendar.availability.onBooking.value' | translate }}</span>
                                    </div>
                                </div>

                                <div class="col_content">
                                    <div class="legend">
                                        <span class="legend-icon legend-onrequest"></span>
                                        <span>{{ 'offer.dateEngine.calendar.availability.onRequest.value' | translate }}</span>
                                    </div>
                                </div>

                            </div>

                            <!-- Actions -->

                            <div class="row marges x_end">

                                <div class="col_content y_items_center buttons">

                                    <!-- Fermeture du calendrier -->

                                    <button type="button" class="btn_cta btn_little btn_lines" (click)="closeCalendar()">{{ 'notification.close.action.value' | translate }}</button>

                                    <!-- Validation de la sélection -->

                                    <button type="button" class="btn_cta btn_little" [disabled]="!isValidationDateSelectionValid" (click)="validateSelection()">{{ 'offer.dateEngine.selection.validate.action.value' | translate }}</button>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="row marges">

        <ng-container *ngTemplateOutlet="childInformations"></ng-container>

    </div>

    <ng-container *ngIf="configurationSteps.traveller.enabled">

        <!-- Valider -->
        <div class="row marges">

            <div class="col_12 col_lg_6 semi-col-date x_end">
                <button type="button" class="btn_cta btn_lines btn_small" (click)="validateTravellerStep()">
                    {{ 'offer.dateEngine.step.validate.value' | translate }}
                </button>
            </div>

        </div>

    </ng-container>

    <!-- Modifier -->

    <ng-container *ngIf="!configurationSteps.traveller.enabled">

        <div class="row marges">
            <div class="col_12 col_lg_6 semi-col-date x_end">
                <button type="button" class="btn_cta btn_lines btn_small" (click)="updateTravellerStep()">
                    {{ 'offer.dateEngine.step.update.value' | translate }}
                </button>
            </div>
        </div>

    </ng-container>

</div>

<ng-template #childInformations>

    <div class="col_12 col_lg_6 semi-col-date" id="child-informations-container">

        <ng-container *ngIf="(offer.customerTypology.type === 'I') && (countChild > 0)">

            <div class="item_date composition-data-wrapper" [ngClass]="getConfigurationStepDataElementClasses(configurationSteps.traveller)">

                <div class="composition-step-content">

                    <form [formGroup]="form" class="row">

                        <div class="choice_item choice_people choice_children col_12 col_lg_6 y_items_start" *ngFor="let _ of participantsControls.controls; index as i" formArrayName="participants">

                            <ng-container [formGroupName]="indexAsString(i)">

                                <div class="content_item form-group">

                                    <input type="text" class="datetimepicker" matInput readonly [matDatepicker]="birthDatePicker" formControlName="birthDay" (click)="birthDatePicker.open()" id="birthDay-{{i}}" placeholder="Ajouter une date">

                                    <span class="label">{{ 'offer.form.fields.birthdate.value' | translate }} {{ 'offer.form.fields.child.value' | translate: {count: i + 1} }}
                                        :</span>

                                    <mat-datepicker #birthDatePicker></mat-datepicker>

                                    <mat-error *ngIf="displayChildInformationsValidators && participantsControl(i).get('birthDay').errors && (participantsControl(i).get('birthDay').dirty|| participantsControl(i).get('birthDay').touched)">

                                        <div class="invalid-feedback">
                                            <div *ngIf="participantsControl(i).get('birthDay').errors.required">{{ 'form.control.error.birthdate.required.value' | translate }}</div>
                                            <div *ngIf="participantsControl(i).get('birthDay').errors.birthdateInvalid">{{ 'form.control.error.birthdate.invalid.value' | translate }}</div>
                                        </div>

                                    </mat-error>

                                </div>

                            </ng-container>

                        </div>

                    </form>

                </div>

            </div>

        </ng-container>

    </div>

</ng-template>
