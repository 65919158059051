import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {Pagination} from "@core/shared/models/pagination";
import {Society} from '@core/shared/models/society';
import {map} from "rxjs/operators";
import {TermsAndCondition} from "@core/shared/models/terms-and-condition";

@Injectable({
    providedIn: 'root'
})
export class SocietyService {

    public currentUserSociety: BehaviorSubject<Society> = new BehaviorSubject(null);

    constructor(
        private _apiService: ApiService,
        private _httpClient: HttpClient,
    ) {}

    public getItemsAPI(params?: string[]): Observable<Society[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Society[]>(url, {
            headers: headers
        });

    }

    public getOfferCreatorsItemsAPI(params?: string[]): Observable<Society[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/offerCreators`;
        params = params || [];
        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Society[]>(url, {
            headers: headers
        });
    }

    public getPaginationOfferCreatorsItemsAPI(params?: string[]): Observable<Pagination<Society>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/offerCreators`;

        params = params || [];

        if (params.length > 0) {

            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Pagination<Society>>(url, {
            headers: headers
        });
    }

    public getOfferDistributorsItemsAPI(params?: string[]): Observable<Society[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/offerDistributors`;
        params = params || [];
        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Society[]>(url, {
            headers: headers
        });
    }

    public getProvidersItemsAPI(params?: string[]): Observable<Society[]> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/providers`;
        params = params || [];
        params.push('disablePagination=1');

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }

        return this._httpClient.get<Society[]>(url, {
            headers: headers
        });
    }

    public getPaginationItemsAPI(params?: string[]): Observable<Pagination<Society>> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies`;
        params = params || [];
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Pagination<Society>>(url, {
            headers: headers
        });

    }

    public getItemAPI(id: number, params?: string[]): Observable<Society> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        let url: string = `${this._apiService.getApiUrl(false, true)}/societies/${id}`;
        params = params || [];
        params.push('disablePagination=1');
        if (params.length > 0) {
            url = `${url}?${params.join('&')}`;
        }
        return this._httpClient.get<Society>(url, {
            headers: headers
        });

    }

    public getItemSelfAPI(): Observable<Society> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<Pagination<Society>>(`${this._apiService.getApiUrl(false, true)}/users/self/societies`, {
            headers: headers
        }).pipe(
            map((result: Pagination<Society>): Society => {

                return result.items[0];
            })
        );
    }

    public createItemAPI(data: object): Observable<Society> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Society>(`${this._apiService.getApiUrl(false, true)}/societies`, data, {
            headers: headers
        });
    }

    public createReferenceShareAPI(data: object): Observable<any> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<Society>(`${this._apiService.getApiUrl(false, true)}/societies/reference/share`, data, {
            headers: headers
        });
    }

    public deleteItemAPI(id: number): Observable<void> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.delete<void>(`${this._apiService.getApiUrl(false, true)}/societies/${id}`,{
            headers: headers
        });
    }

    public updateItemAPI(id: number, data: object): Observable<Society> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.patch<Society>(`${this._apiService.getApiUrl(false, true)}/societies/${id}`, data, {
            headers: headers
        });
    }

    public getTermAndConditionsFromOffer(id: number, locale: string): Observable<number> {

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');
        return this._httpClient.get<number>(`${this._apiService.getApiUrl(false, true)}/societies/offer/${id}/${locale}/terms-and-conditions`, {
            headers: headers
        });
    }

    public exportItemsAPI(): Observable<Blob> {

        return this._httpClient.get(`${this._apiService.getApiUrl(false, true)}/societies/export`, {
            responseType: 'blob'
        });

    }

    public cancelDeleteItemAPI(id: number): Observable<void>{

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.post<void>(`${this._apiService.getApiUrl(false, true)}/societies/${id}/delete/cancel`,{
            headers: headers
        });
    }
}
