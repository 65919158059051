<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
    <div class="ck-content">
        <p [innerHTML]="data.content"></p>
    </div>
</mat-dialog-content>
<mat-dialog-actions>

    <div class="row x_center marges">
        <div class="col_content">
            <button class="btn_cta btn_little btn_red" (click)="cancel.emit()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
        </div>
        <div class="col_content">
            <button class="btn_cta btn_little" (click)="confirm.emit()">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
        </div>
    </div>

</mat-dialog-actions>

