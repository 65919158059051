<div class="card_offer" [ngClass]="(isHighlighted) ? 'offer_highlighted' : ''">
    <div class="row h_full direction_y">
        <div class="col_content card_top">
            <div [ngClass]="{'visuel' : isView('visual'), 'map_wrapper' : isView('map')}">
                <ng-container  *ngIf="layoutView == 'list' && isView('map')">

                    <div class="map" leaflet [leafletOptions]="mapOptions" (leafletMapReady)="handleMapInitialization()" [leafletMarkerCluster]="markers"></div>

                </ng-container>
                <ng-container *ngIf="isView('visual')">
                    <img *ngIf="offerTranslation.pictures.length" [src]="getVisuelOffer(item.id, offerTranslation.pictures[0].id)" alt="{{offerTranslation.name}}"/>
                    <img *ngIf="!offerTranslation.pictures.length" src="assets/images/offer/preview/1.jpg" alt="image">
                </ng-container>
                <div class="infos">
                    <div class="extras">
                        <ng-container *ngIf="layoutView === 'list'">

                            <!-- Passage en vue carte -->

                            <ng-container *ngIf="isView('visual') && markers.length">
                                <span class="map-switch ty-map" (click)="setView('map')"></span>
                            </ng-container>

                            <!-- Passage en vue liste -->

                            <ng-container *ngIf="isView('map') && offerTranslation.pictures.length">
                                <span class="map-switch ty-eye" (click)="setView('visual')"></span>
                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="layoutView === 'map'">
                            <span class="map-close ty-close" (click)="closeItem.emit()"></span>
                        </ng-container>

                        <ng-container *ngIf="displayOfferPublishedStatus">
                            <span class="status green" *ngIf="item.published === 'published'" matTooltip="{{'offer.list.item.status.published.value' | translate}}" matTooltipPosition="above">P</span>
                            <span class="status red" *ngIf="item.published === 'restricted'" matTooltip="{{'offer.list.item.status.restricted.value' | translate}}" matTooltipPosition="above">R</span>
                            <span class="status blue" *ngIf="item.published === 'draft'" matTooltip="{{'offer.list.item.status.draft.value' | translate}}" matTooltipPosition="above">B</span>
                        </ng-container>

                        <span class="status vigilance" *ngIf="isOnAlert()" matTooltip="{{'offer.list.item.isOnAlert.value' | translate}}"></span>

                    </div>

                    <div class="upper_left" *ngIf="isView('visual')">
                        <span class="new" *ngIf="isNewOffer()"><span>{{'offer.list.item.new.value' | translate}}</span></span>
                        <span class="translation-process" *ngIf="hasTranslationOnProcess"><span>{{ 'offer.hasTranslationRequestToProcess.title.value' | translate }}</span></span>
                    </div>

                    <div class="tags">
                        <ng-container *ngIf="displayOfferOnlineSale">
                            <span class="tag online-sale">
                                <ng-container *ngIf="displayOnlineSaleStatus('disable')">
                                    <img src="assets/images/online-sale-grey.svg" alt="{{ 'offer.onlineSale.value' | translate }}" [matTooltip]="onlineSaleContent('disable')" />
                                </ng-container>
                                <ng-container *ngIf="displayOnlineSaleStatus('warning')">
                                    <img src="assets/images/online-sale-red.svg" alt="{{ 'offer.onlineSale.value' | translate }}" [matTooltip]="onlineSaleContent('warning')" />
                                </ng-container>
                                <ng-container *ngIf="displayOnlineSaleStatus('available')">
                                    <img src="assets/images/online-sale-green.svg" alt="{{ 'offer.onlineSale.value' | translate }}" [matTooltip]="onlineSaleContent('available')" />
                                </ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="displayOfferGiftVoucher">
                            <span class="tag gift-voucher">
                                <ng-container *ngIf="displayGiftVoucherStatus('available')">
                                    <img src="assets/images/giftbox-green.svg" alt="{{ 'offer.giftVoucher.value' | translate }}" [matTooltip]="giftVoucherContent('available')" matTooltipPosition="above"/>
                                </ng-container>
                                <ng-container *ngIf="displayGiftVoucherStatus('disable')">
                                    <img src="assets/images/giftbox-grey.svg" [matTooltip]="giftVoucherContent('disable')" matTooltipPosition="above" alt="{{ 'offer.giftVoucher.value' | translate }}"/>
                                </ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="displayPermanentOptions">
                            <span class="tag online-sale">
                                <img src="assets/images/permanent-option.png" alt="{{ 'offer.permanentOptions.value' | translate }}" [matTooltip]="permanentOptionsContent"/>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!displayPermanentOptions && hasOneOfThisMode(['personnal-offers', 'offer-permanent-option-personnal-offers', 'catalog', 'channel', 'offer-permanent-option-catalog'])">
                            <span class="tag online-sale">
                                <img src="assets/images/permanent-option-unavailable.png" alt="{{ 'offer.permanentOptions.unavailable.value' | translate }}" [matTooltip]="'offer.permanentOptions.unavailable.value' | translate"/>
                            </span>
                        </ng-container>
                        <span class="tag promo" *ngIf="hasOfferPromotion && timerPromotion">
                            <span><img src="assets/images/icons/icon_promo.svg"/>&nbsp;{{timerPromotion}}</span>
                        </span>
                        <span class="tag highlighted" *ngIf="isHighlighted">
                            <span>{{'offer.list.item.highlighted.value' | translate}}</span>
                        </span>
                        <span class="tag referenced" *ngIf="offerSearchService.displayReferencedOffer && isReferenced">
                            <span>{{ 'offer.referenced.value' | translate }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col card_bottom">
            <div class="infos_offer">
                <div class="row h_full direction_y">
                    <div class="col infos_wrapper">
                        <div class="infos" [ngClass]="{'closed' : cardClosed && layoutView == 'list'}">
                            <div class="row marges no_wrap">
                                <div class="col y_items_start title">
                                    <div class="row">
                                        <div class="col_12">
                                            <p>{{ offerTranslation.name }} <ng-container *ngIf="displayLocation">({{ displayLocation.city }})</ng-container></p>
                                        </div>
                                        <div class="col_content y_items_center">
                                            <div class="flags">
                                                <span *ngFor="let flag of locales" class="flag flag-{{flag}}"></span>
                                                <span *ngFor="let flag of item.localesPendingTranslationRequest" class="flag flag-{{flag}} flag-pending" matTooltip="{{ 'offer.locale.hasTranslationRequestToProcess.description.value' | translate }}" matTooltipPosition="above"></span>
                                            </div>
                                        </div>
                                        <div class="col y_items_center">
                                            <p class="duration">{{getDurationTranslation()?.label}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col_content y_items_start" *ngIf="layoutView == 'list'">
                                    <span class="show_more ty-chevron" (click)="cardClosed ? cardClosed = false : cardClosed = true"></span>
                                </div>
                            </div>

                            <div class="price" *ngIf="item.startFromPriceHT || item.startFromPriceTTC">
                                <span>{{ ((publicPrice && offerCatalog) ? ((item.isDisplayPriceHT ? offerCatalog.startFromPriceHT : offerCatalog.startFromPriceTTC) / 100) : ((item.isDisplayPriceHT ? item.startFromPriceHT : item.startFromPriceTTC) / 100)) | parsePrice }} {{ item.currency.symbol }} {{ (item.isDisplayPriceHT ? 'offer.price.type.ht.value' : 'offer.price.type.ttc.value') | translate }}</span>
                                <div class="price_info">
                                    <span class="from">{{'offer.list.item.from.value' | translate}}</span>
                                    <span>{{'offer.list.item.perPerson.value' | translate}}</span>
                                </div>
                            </div>

                            <div class="description">
                                <p [innerHTML]="offerTranslation.teaser|safeHtml"></p>
                            </div>

                            <div class="infos_sup">

                                <!-- Addresse  -->
                                <ng-container *ngIf="showAddress && displayLocation">

                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.list.item.address.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ displayLocation.address }}</p>
                                        </div>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_PROVIDER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION'])">
                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.list.item.producer.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{item.society.name}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row">
                                    <div class="col_5">
                                        <p>{{'offer.list.item.type.value' | translate}}</p>
                                    </div>
                                    <div class="col_7">
                                        <p>{{getCustomerTypologyTranslation()?.name}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col_5">
                                        <p>{{'offer.list.item.interest.value' | translate}}</p>
                                    </div>
                                    <div class="col_7">
                                        <ul class="interests">
                                            <li *ngFor="let interest of item.interests">{{ translationService.getFallbackTranslation(interest.translations).label}}</li>
                                        </ul>
                                    </div>
                                </div>

                                <!-- Date de mise à jour -->
                                <ng-container *ngIf="hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION', 'ROLE_OFFER_CREATOR', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_PROVIDER']) || mode === 'catalog'">

                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.list.item.updatedAt.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ item.updatedAt | amDateFormat:'DD MMMM YYYY'}}</p>
                                        </div>
                                    </div>

                                </ng-container>

                                <!-- Créateur & Distributeur -->

                                <ng-container *ngIf="mode !== 'reservoir' && hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']) && item.markup">
                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.form.fields.markup.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ parseMarkup(item.markup * 100) }}</p>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Créateur -->

                                <ng-container *ngIf="mode !== 'reservoir' && hasRole('ROLE_OFFER_CREATOR') && !hasRole('ROLE_OFFER_DISTRIBUTOR') && item.markup">
                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.form.fields.markup.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ parseMarkup(item.markup * 100) }}</p>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- Distributeur -->

                                <ng-container *ngIf="mode !== 'reservoir' && offerCatalog && !hasRole('ROLE_OFFER_CREATOR') && (hasRole('ROLE_OFFER_DISTRIBUTOR'))">
                                    <div class="row">
                                        <div class="col_5">
                                            <p>{{'offer.form.fields.markup.value' | translate}}</p>
                                        </div>
                                        <div class="col_7">
                                            <p>{{ parseMarkup(offerCatalog.markup * 100) }}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col_content">
                        <div class="links_offer">

                            <!-- Gestion du catalogue -->

                            <ng-container *ngIf="(hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_PROVIDER', 'ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']))" class="">

                                <ng-container *ngIf="isMode('personnal-offers') || isMode('reservoir')">

                                    <ng-container *ngIf="isAcceptedOfferCatalog">

                                        <ng-container *ngIf="offerSearchService.deleteOfferFromCatalogAllowed">

                                            <!-- Supression du catalogue -->

                                            <div class="" *ngIf="hasAccessCatalogDelete()">
                                                <a (click)="openDeleteCatalogueDialog()">
                                                    <span>{{'offer.list.item.action.catalog.delete.value' | translate}}</span>
                                                </a>
                                            </div>

                                        </ng-container>

                                        <ng-container *ngIf="!offerSearchService.deleteOfferFromCatalogAllowed">

                                            <div class="">
                                                <p>
                                                    <span>{{'offer.inCatalog.value' | translate}}</span>
                                                </p>
                                            </div>

                                        </ng-container>

                                    </ng-container>

                                    <ng-container *ngIf="offerSearchService.addOfferToCatalogAllowed">

                                        <ng-container *ngIf="((hasRole('ROLE_OFFER_CREATOR') && item.society.id === user.society.id) || hasRole('ROLE_PROVIDER')) && !isInCatalog && hasAccessCatalogList() && hasAccessCatalogCreate()">

                                            <!-- Ajout au catalogue -->

                                            <div>
                                                <a (click)="openAddCatalogueDialog()">
                                                    <span>{{'offer.list.item.action.catalog.add.value' | translate}}</span>
                                                </a>
                                            </div>

                                        </ng-container>

                                    </ng-container>

                                </ng-container>

                            </ng-container>

                            <!-- Demande d'acces -->

                            <ng-container *ngIf="offerSearchService.accessRequestOfferAllowed">

                                <ng-container *ngIf="isMode('reservoir') && hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && (isPendingOfferCatalog || !isInCatalog)" class="">

                                    <ng-container *ngIf="isPendingOfferCatalog">

                                        <div class="btn_white">
                                            <a>
                                                <span>{{ 'offer.accessRequest.requested.value' | translate }}</span>
                                            </a>
                                        </div>

                                    </ng-container>

                                    <ng-container *ngIf="isRefusedOfferCatalog">

                                        <div class="">
                                            <a>
                                                <span>{{ 'offer.accessRequest.refused.value' | translate }}</span>
                                            </a>
                                        </div>

                                    </ng-container>

                                    <ng-container *ngIf="!isInCatalog">

                                        <ng-container *ngIf="offerSearchService.multipleAccessRequestSelectionActivated.getValue(); then handleOfferToAccessRequestsSelectionTemplate else openOfferAccessDialogTemplate"></ng-container>

                                        <ng-template #handleOfferToAccessRequestsSelectionTemplate>

                                            <div *ngIf="!offerSearchService.isSelectedOfferToAccessRequest(item)" class="btn_degrade">
                                                <a (click)="offerSearchService.selectOfferToAccessRequest(item)" class="selectable">
                                                    <span>{{'offer.list.item.action.access.request.value' | translate}}</span>
                                                </a>
                                            </div>


                                            <div *ngIf="offerSearchService.isSelectedOfferToAccessRequest(item)" class="btn_degrade">
                                                <a (click)="offerSearchService.unselectOfferToAccessRequest(item)" class="selectable selected">
                                                    <span>{{'offer.list.item.action.access.request.value' | translate}}</span>
                                                </a>
                                            </div>

                                        </ng-template>

                                        <ng-template #openOfferAccessDialogTemplate>


                                            <div class="btn_degrade">
                                                <a (click)="openOfferAccessDialog(item)">
                                                    <span>{{'offer.list.item.action.access.request.value' | translate}}</span>
                                                </a>
                                            </div>

                                        </ng-template>

                                    </ng-container>
                                </ng-container>

                            </ng-container>

                            <!-- Edition d'une offre -->

                            <ng-container *ngIf="offerSearchService.updateOfferAllowed && isMode('personnal-offers')">

                                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && hasAccessEdit()">

                                    <div class="">
                                        <a (click)="updateItem(item.id)" [ngStyle]="{ opacity: hasTranslationOnProcess ? 0.5 : 1, cursor: hasTranslationOnProcess ? 'not-allowed' : 'pointer' }" matTooltip="{{ 'offer.hasTranslationRequestToProcess.description.value' | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!hasTranslationOnProcess">
                                            <span>{{'offer.list.item.action.update.value' | translate}}</span>
                                        </a>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="hasRole('ROLE_OFFER_CREATOR') && hasAccessCreate()">

                                    <div class="">
                                        <a (click)="openDuplicationDialog()" [ngStyle]="{ opacity: hasTranslationOnProcess ? 0.5 : 1, cursor: hasTranslationOnProcess ? 'not-allowed' : 'pointer' }" matTooltip="{{ 'offer.hasTranslationRequestToProcess.description.value' | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!hasTranslationOnProcess">
                                            <span>{{'offer.duplication.action.value' | translate}}</span>
                                        </a>
                                    </div>

                                </ng-container>

                            </ng-container>

                            <!-- Visualisation d'une offre -->

                            <ng-container *ngIf="offerSearchService.viewOfferAllowed">

                                <ng-container *ngIf="hasAllOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_OFFER_DISTRIBUTOR']); then viewOfferAsCreatorDistributor; else viewOfferAsOther"></ng-container>

                                <ng-template #viewOfferAsCreatorDistributor>
                                    <ng-container *ngIf="isMode('personnal-offers') || hasOneOfThisMode(['channel', 'catalog'])">
                                        <ng-container *ngTemplateOutlet="viewOfferContent" ></ng-container>
                                    </ng-container>
                                </ng-template>

                                <ng-template #viewOfferAsOther>
                                    <ng-container *ngIf="(hasOneOfThisRoles(['ROLE_OFFER_DISTRIBUTOR', 'ROLE_INSTITUTIONAL', 'ROLE_FEDERATION']) && isMode('catalog')) || hasOneOfThisRoles(['ROLE_OFFER_CREATOR', 'ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_PROVIDER'])" class="">
                                        <ng-container *ngTemplateOutlet="viewOfferContent" ></ng-container>
                                    </ng-container>
                                </ng-template>

                                <ng-template #viewOfferContent>
                                    <div>
                                        <a (click)="goToOffer(item)" [ngStyle]="{ opacity: hasTranslationOnProcess ? 0.5 : 1, cursor: hasTranslationOnProcess ? 'not-allowed' : 'pointer' }" matTooltip="{{ 'offer.hasTranslationRequestToProcess.description.value' | translate }}" matTooltipPosition="above" [matTooltipDisabled]="!hasTranslationOnProcess">
                                            <span>{{'offer.list.item.action.see.value' | translate}}</span>
                                        </a>
                                    </div>
                                </ng-template>

                            </ng-container>

                            <!-- Sélection -->

                            <ng-container *ngIf="offerSearchService.selectOfferAllowed">

                                <ng-container *ngIf="offerSearchService.isSelectedOffer(item)">

                                    <div>
                                        <a (click)="offerSearchService.unselectOffer(item)">
                                            <span>{{'offer.unselect.action.value' | translate}}</span>
                                        </a>
                                    </div>

                                </ng-container>

                                <ng-container *ngIf="!offerSearchService.isSelectedOffer(item)">

                                    <div>
                                        <a (click)="offerSearchService.selectOffer(item)">
                                            <span>{{'offer.select.action.value' | translate}}</span>
                                        </a>
                                    </div>

                                </ng-container>

                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
