import {Injectable} from '@angular/core';
import {TranslationItem} from "@core/shared/models/translation";
import {UserService} from "@core/shared/services/user.service";
import {User} from "@core/shared/models/user";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    public onLangChange: Subject<string> = new Subject<string>();

    private _fallbacks: { [p: string]: string[] } = {
        'fr': ['fr', 'en', 'es', 'it', 'de', 'nl', 'pt'],
        'en': ['en', 'fr', 'es', 'it', 'de', 'nl', 'pt'],
        'es': ['es', 'en', 'fr', 'it', 'pt', 'de', 'nl'],
        'it': ['it', 'en', 'fr', 'es', 'de', 'nl', 'pt'],
        'de': ['de', 'nl', 'en', 'fr', 'es', 'it', 'pt'],
        'pt': ['pt', 'en', 'es', 'fr', 'it', 'de', 'nl'],
        'nl': ['nl', 'de', 'en', 'fr', 'es', 'it', 'pt']
    }

    constructor(private _userService: UserService) {}

    public getFallbackTranslation<T extends TranslationItem>(translations: Array<T>, user?: User): T|null {

        if(!translations.length){

            return null;
        }

        const checkedUser: User = user || this._userService.currentUser.getValue();

        if(!checkedUser){

            return translations[0];
        }

        const fallbackLocales: string[] = this._fallbacks[checkedUser.locale];

        const existingLocales = translations.map((translation: T): string => {

            return translation.locale;
        });

        const locale = fallbackLocales.reduce((previousValue, currentValue): string => {

            return (!previousValue && existingLocales.includes(currentValue)) ? currentValue : previousValue;

        }, null);

        return translations.find((translation: T): boolean => {

            return translation.locale === locale;

        }) || translations[0];
    }

    public getUserLocale(): string {

        return this._userService.currentUser.getValue()?.locale;
    }
}
