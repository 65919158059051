<div>
    <ng-container *ngIf="backEnabled">
        <div class="page_title">
            <div class="row marges">
                <div class="col_content y_items_center">
                    <a (click)="backClicked()" class="btn_cta btn_little btn_back">< {{ 'action.back' | translate}}</a>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="page_title">
        <div class="row marges">
            <div class="col">
                <h1 class="title">{{_translationService.getFallbackTranslation(page.translations).titre}}</h1>
                <div class="ck-content" [innerHTML]="sanitize(_translationService.getFallbackTranslation(page.translations).description)"></div>
            </div>
            <div class="col_content y_items_end">
                <span>{{ 'page.updatedAt.value' | translate }} : {{page.updatedAt | date : 'dd/MM/yyyy' : null : localeId}}</span>
            </div>
        </div>
    </div>


    <div class="content_cms">
        <div class="box_full">
            <div class="row">
                <div class="col_12">
                    <ng-container *ngFor="let row of _translationService.getFallbackTranslation(page.data.content.translations).rows">
                        <div class="row cms_row">
                            <ng-container *ngFor="let column of row.columns">
                                <div class="col_12 col_md_{{ column.colWidth }} cms_column ">
                                    <div class="row h_full y_content_center" [ngClass]="(column.backgroundColor) ? column.backgroundColor.tag : 'without_bg'">
                                        <ng-container *ngFor="let element of column.elements">

                                            <div *ngIf="native_types.indexOf(element.type) >= 0" class="col_12 element_{{ element.type }}">

                                                <h2 *ngIf="element.title" class="titre_element">{{ element.title }}</h2>
                                                <span *ngIf="element.subtitle" class="sous_titre_element">{{ element.subtitle }}</span>

                                                <div *ngIf="element.type == 'button'">
                                                    <app-cms-button [button]="element"></app-cms-button>
                                                </div>

                                                <div *ngIf="element.type == 'text'">
                                                    <app-cms-text [text]="element"></app-cms-text>
                                                </div>
                                                <div *ngIf="element.type == 'file'">
                                                    <app-cms-file [file]="element"></app-cms-file>
                                                </div>
                                                <div *ngIf="element.type == 'image'">
                                                    <app-cms-image [image]="element"></app-cms-image>
                                                </div>
                                                <div *ngIf="element.type == 'slideshow'">
                                                    <app-cms-slideshow [slideshow]="element"></app-cms-slideshow>
                                                </div>
                                                <div *ngIf="element.type == 'iframe'">
                                                    <app-cms-iframe [iframe]="element"></app-cms-iframe>
                                                </div>
                                                <div *ngIf="element.type == 'video'">
                                                    <app-cms-video [video]="element"></app-cms-video>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

</div>

